@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}



@-webkit-keyframes flicker-out-1 {
    0% {
        opacity: 1;
    }

    13.9% {
        opacity: 1;
    }

    14% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    14.9% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    15% {
        opacity: 1;
    }

    22.9% {
        opacity: 1;
    }

    23% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    24.9% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    25% {
        opacity: 1;
    }

    34.9% {
        opacity: 1;
    }

    35% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    39.9% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    40% {
        opacity: 1;
    }

    42.9% {
        opacity: 1;
    }

    43% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    44.9% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    45% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    54.9% {
        opacity: 1;
    }

    55% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    69.4% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    69.5% {
        opacity: 1;
    }

    69.9% {
        opacity: 1;
    }

    70% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    79.4% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    79.9% {
        opacity: 1;
    }

    80% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    89.8% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    89.9% {
        opacity: 1;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    90% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    100% {
        opacity: 0;
    }
}

@keyframes flicker-out-1 {
    0% {
        opacity: 1;
    }

    13.9% {
        opacity: 1;
    }

    14% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    14.9% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    15% {
        opacity: 1;
    }

    22.9% {
        opacity: 1;
    }

    23% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    24.9% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    25% {
        opacity: 1;
    }

    34.9% {
        opacity: 1;
    }

    35% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    39.9% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    40% {
        opacity: 1;
    }

    42.9% {
        opacity: 1;
    }

    43% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    44.9% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    45% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    54.9% {
        opacity: 1;
    }

    55% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    69.4% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    69.5% {
        opacity: 1;
    }

    69.9% {
        opacity: 1;
    }

    70% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    79.4% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    79.9% {
        opacity: 1;
    }

    80% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    89.8% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    89.9% {
        opacity: 1;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    90% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes scale-in-ver-bottom {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }
}

@keyframes scale-in-ver-bottom {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }
}

@-webkit-keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}


.leadercreature {
    width: 28px;
    -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: var(--random-delay);
}

.leadercreature2 {
    width: 18px;
    -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: var(--random-delay);
}

.groups-container {
    max-width: 812px;
    margin: 0 auto;
    padding: 0px;
    color: black;
    height: 95vh;
    overflow-y: scroll;
}



.groups-heading {
    font-size: 24px;
    margin-bottom: 10px;
}

.groups-list {
    list-style-type: none;
    padding: 0;
}

.groupiconcontainer {
    font-size: 1.4rem;
    text-align: center;
    padding-top: 2px;
    border-radius: 50%;
    background-color: #f1f1f1;
    height: 35px;
    width: 35px;
    margin-left: 5px;
}

.grouptotalscontainer {
    display: flex;
    flex-direction: row;
}

.grouptableaddedby {
    text-align: right;
    font-size: 0.7rem;
    font-family: "Quicksand", sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grouptableamount {
    text-align: right;
    font-size: 0.9rem;
    font-family: "Baloo 2", sans-serif;
}

.groupstilesheader {
    margin-top: 25px;
    padding: 15px;
    font-size: 1.4rem;
    font-weight: 700;
    color: #474747;
    font-family: "Quicksand", sans-serif;
}

.editinggroupnameinput {
    border-radius: 5px;
    background-color: white;
    border: 1px solid rgb(204, 204, 204);
    font-family: "Quicksand", sans-serif;
    text-align: left;
    padding-left: 5px;
    height: 35px;
    width: 100%;
}

.colordot {
    width: 12px;
    height: 12px;
    border-radius: 50%;


    margin-top: 0px;
    margin-right: 0px;
    box-sizing: border-box;
}

.colordot2 {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
    margin-top: 0px;
    margin-right: 0px;
    box-sizing: border-box;
}

.saveeditedgroup {
    background-color: transparent;
    background-color: rgb(10, 100, 90);
    border-radius: 5px;
    border: none;
    font-family: "Quicksand", sans-serif;
    font-size: 0.7em;
    color: #f5f5f5;
    font-weight: 600;
    outline: none;
    padding: 2px 5px 2px 5px;
}

.closebuttoncontain {
    width: 100%;
    padding-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-button {

    background-color: rgb(29, 56, 53);
    padding: 10px;
    color: #fff;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
    margin-bottom: 140px;
}

.backtogroupsbuttoncontain {
    padding: 0px;
    line-height: 0.9rem;
    /* position: fixed;
    top: 10px; */
}

.backtogroupsbutton {
    background-color: transparent;
    color: rgb(71, 71, 71);
    border: none;
    outline: none;
    font-weight: 600;
    margin-top: 15px;
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
}

.editsavedgroup {
    position: absolute;
    left: 0px;
    top: 0px;

    float: right;
    background-color: transparent;
    font-size: 0.7rem;
    color: rgb(10, 62, 110);
    text-decoration: underline;
    font-family: "Quicksand", sans-serif;
    outline: none;
    border: none;
    padding: 0px;
}

.grouptablefilters {
    width: 100%;
    display: flex;
    height: 40px;
    line-height: 1.4rem;
    background-color: #e9e9e9;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 4px;
    box-sizing: border-box;
}

.grouppurchasehistory {
    font-weight: 600;
    color: rgb(204, 204, 204);
    text-align: center;
    font-size: 0.8rem;
    background-color: rgb(29, 56, 53);
    border-radius: 10px 10px 0px 0px;
}

.filter-input1 {
    width: 100%;
    padding-left: 5px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
    outline: none;
    font-size: 0.7rem;
    font-family: "Quicksand", sans-serif;
}

.filter-input2 {
    width: 20%;
    border: 1px solid rgb(204, 204, 204);
    padding-left: 5px;
    border-radius: 5px;
    font-size: 0.7rem;
    font-family: "Quicksand", sans-serif;
    text-align: center;
    outline: none;
}

.groupspurchasedatecontainer {
    padding: 0px;
}

.groupspurchasedate {
    width: 100%;
    text-align: center;
    background-color: #e9e9e9;
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

.citybackcontainer {
    display: flex;
    justify-content: center;
    width: 280px;
    height: 280px;
    position: relative;
    background-color: #c2c2c2;
    border-radius: 50%;
    overflow: hidden;
}

.cityback2 {
    width: 100%;
}

.emptystatecontainer {
    height: 280px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.tilestatement {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}


.lightmast {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 13%;
    left: 49.285%;
    -webkit-animation: ping 1.5s ease-in-out 1s infinite both;
    animation: ping 1.5s ease-in-out 1s infinite both;
}

.deletegroupcontainer {
    padding: 0px;
    margin-top: 100px;
    margin-bottom: 200px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
}

.deletegroupbutton {
    font-family: "Quicksand", sans-serif;
    height: auto;
    font-weight: 700;
    margin-top: 20px;
    outline: none;
    border: none;
    color: #852626;
    background-color: transparent;
}

.addmembergroupheader {
    /* background-color: #e2e2e2e0;
    border-radius: 5px;
    padding: 10px; */
    width: 100%;
    color: rgb(46, 46, 46);
    font-size: 0.8rem;
    margin-top: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.addmembergroupheader2 {
    /* background-color: #e2e2e2e0;
    border-radius: 5px;
    padding: 10px; */
    width: 100%;
    color: rgb(192, 192, 192);
    font-size: 0.8rem;
    margin-top: 10px;
    font-weight: 500;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.confirmation-delete-group {
    color: rgb(255, 255, 255);
    background-color: #852626;

    outline: none;
    border: none;
    border-radius: 5px;
    font-family: "Quicksand", sans-serif;
    height: auto;
    font-weight: 700;
    margin-top: 20px;
    padding: 15px 20px 15px 20px;
}

.lilwindow {
    width: 13px;
    height: 13px;
    position: absolute;
    top: 32.5%;
    left: 60.5%;
    -webkit-animation: fade-out 0.1s ease-out 5s both;
    animation: fade-out 0.1s ease-out 5s both;
}

.lilman {
    width: 5px;
    height: 5px;
    position: absolute;
    top: 68.7%;
    left: 70.5%;
    -webkit-animation: scale-in-ver-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 15s both;
    animation: scale-in-ver-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 15s both;
}



.bigwindow {
    width: 28px;
    height: 20px;
    position: absolute;
    top: 55.5%;
    left: 2.4%;
    -webkit-animation: fade-out 0.1s ease-out 9s both;
    animation: fade-out 0.1s ease-out 9s both;
}

.nopurchasefoundtext {
    padding-top: 20px;
    font-size: 0.9rem;
    font-weight: 700;
}

.nopurchasefoundtext2 {
    padding-top: 4px;
    font-size: 0.7rem;
    font-weight: 500;
    color: rgb(71, 71, 71);
}

.lightpole {
    width: 33px;
    height: 33px;
    position: absolute;
    bottom: 10%;
    left: 49%;
    opacity: 80%;
    -webkit-animation: flicker-out-1 2s linear 12s both;
    animation: flicker-out-1 2s linear 12s both;
}

.no-purchases-group {
    background-color: rgb(230, 230, 230);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    margin: 10px;
}

.no-purchases-statement {
    width: 100%;
    text-align: left;
    font-size: 0.7rem;
    color: rgb(39, 39, 39);
}

.nopurchaseempty {
    width: 100%;
    font-size: 0.8rem;
    text-align: center;
    padding-bottom: 40px;
    -webkit-animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
    animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
}

.addsomeonecontainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.toggleinvitebuttonhide {

    outline: none;
    font-family: "Quicksand", sans-serif;
    border: none;
    color: #2c2c2c;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    background-color: transparent;
    font-weight: 600;
    margin-top: 30px;
}

.toggleinvitebuttonshow {
    border: none;
    outline: none;
    margin-top: 20px;
    font-size: 0.8rem;
    color: rgb(10, 62, 110);
    text-decoration: underline;
    font-family: "Quicksand", sans-serif;
    background-color: transparent;
}

.group-members-count-empty {
    width: 100%;
    font-size: 0.8rem;
    text-align: center;
}


.grouptotalsflexbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

}




.group-totals-group.ahead {
    background-color: rgb(6, 59, 53);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    margin: 10px;
    -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}



.group-totals-group.behind {
    background-color: rgb(245, 245, 245);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    margin: 10px;
    -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.group-totals-group-name.ahead {
    padding: 0px;
    margin: 0px;
    color: #ffbd2b;
}

.group-totals-group-name.behind {
    padding: 0px;
    margin: 0px;
    color: rgb(6, 59, 53);
}

.group-totals-group-name.no-purchases {
    padding: 0px;
    margin: 0px;
    color: rgb(39, 39, 39);
}

.group-totals-statement.ahead {
    font-size: 0.9rem;
    font-weight: 500;
    color: white;
}

.group-totals-statement.behind {
    font-size: 0.9rem;
    font-weight: 600;
    color: rgb(34, 34, 34);
}


.group-totals-member.ahead {
    color: rgb(204, 204, 204);
    font-size: 0.8rem;
}

.group-totals-member.behind {
    color: rgb(58, 58, 58);
    font-size: 0.8rem;
}

.group-totals-member-id.ahead {
    font-size: 0.8rem;
    font-weight: 500;
    color: rgb(204, 204, 204);
}

.group-totals-member-id.behind {
    font-size: 0.8rem;
    font-weight: 500;
    color: rgb(58, 58, 58);
}

.group-totals-member-total.ahead {
    font-size: 0.8rem;
    font-weight: 500;
    color: rgb(204, 204, 204);
    font-family: "Baloo 2", sans-serif;
}

.group-totals-member-total.behind {
    font-size: 0.8rem;
    font-weight: 500;
    color: rgb(116, 116, 116);
    font-family: "Baloo 2", sans-serif;
}


.grouptablenote {

    max-width: 220px;
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    font-weight: 500;
}

.selectpurchase {
    color: black;
}

.group-item {
    margin-bottom: 5px;
}

.invite-section {
    width: 80%;
    box-sizing: border-box;
    margin: 10%;
    padding: 15px;
    background-color: #41414138;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.group-input,
.invite-input {
    /* display: block; */
    /* margin-bottom: 20px; */
    box-sizing: border-box;
    padding: 5px;
    margin: 20px 0px 10px 0px;
    width: 100%;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    outline: none;
    border-radius: 5px;
    border: none;
    text-align: center;
    height: 40px;
}

.goaltargetinputcontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 95%;
    font-size: 2rem;
    padding-left: 2.5%;
    padding-right: 2.5%;
    justify-content: space-evenly;
    height: 60px;
    align-items: center;
}

.goaltargetlabel {
    width: 60%;
    font-size: 1.6rem;
}



.goaltargetamount {


    width: 40%;

    text-align: center;
    /* height: 40px; */
}

.group-input2 {
    /* display: block; */
    /* margin-bottom: 20px; */
    box-sizing: border-box;
    /* padding: 5px;*/
    margin: 5px 0px 0px 0px;
    width: 100%;
    font-family: "Baloo 2", sans-serif;
    font-weight: 700;
    outline: none;
    color: rgb(226, 226, 226);
    font-size: 2rem;
    border: none;
    text-align: center;
    /* height: 40px; */
    background-color: transparent;
}

.group-button,
.invite-button {
    width: 200px;
    height: 35px;
    border-radius: 5px;
    border: none;
    outline: none;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    background-color: rgb(6, 59, 53);
    color: rgb(219, 219, 219);
}

.group-button:hover,
.invite-button:hover {
    background-color: rgb(9, 90, 81);
}

.group-select {
    display: block;
    margin-bottom: 10px;
    padding: 5px;
    font-size: 1.3rem;
    font-family: "Quicksand", sans-serif;
    text-align: left;
    border-radius: 0px;
    outline: none;
    font-weight: 700;
    width: 100%;
    color: rgb(6, 59, 53);
    background-color: transparent;

}

.groups-container {
    color: black;
}

.groups-heading {
    color: black;
}

.groups-list {
    color: black;
}

.group-item {
    /* Styles for each group item */
    cursor: pointer;
    color: black;
}

.group-name {
    padding: 0px 10px 0px 0px;
    box-sizing: border-box;
    font-size: 1.3rem;
    color: rgb(6, 59, 53);
    box-sizing: border-box;
    position: relative;
    margin: 10px;
}

.selectagroup {
    font-size: 1rem;
}

.groupselection {
    color: #707070;
    margin-top: -40px;
    margin-bottom: 20px;
    width: 100%;
    height: 34px;
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
    text-align: left;
    border-radius: 0px;
    outline: none;
    font-weight: 700;
    background-color: #f1f1f1d3;
}


.nogroupsemptycontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.8rem;
}

.nogroupstext1 {
    font-weight: 700;
    font-size: 1rem;
    margin-top: 80px;
    color: rgb(15, 15, 15);
    margin-bottom: 10%;
}

.nogroupstext2 {
    font-weight: 600;
    font-size: 0.8rem;
    margin-top: 10%;
    color: rgb(34, 34, 34);
    margin-bottom: 10%;
}

.emptygroupcebits {
    width: 50%;
}

.bottomanchor {
    margin-top: 200px;
}

.createnewgroupbutton {
    background-color: transparent;
    border: none;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    font-size: .8rem;
    color: rgb(10, 62, 110);
    height: 45px;
    font-weight: 400;
    text-decoration: underline;
    width: 100%;
    outline: none;
    text-align: center;
    font-family: "Quicksand", sans-serif;
}

.newgroupbuttoncontainer {
    width: 100%;
    padding: 2.5%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.createnewgroupformheader {
    padding-top: 20px;
    box-sizing: border-box;
    width: 100%;
    color: rgb(6, 59, 53);
}

.createnewgroupformheader1 {
    padding-top: 20px;
    box-sizing: border-box;
    font-weight: 500;
    width: 100%;
    color: rgb(253, 253, 253);
}

.groupselection2 {
    color: rgb(6, 59, 53);
    margin-top: -40px;
    margin-bottom: 20px;
    width: 100%;
    height: 34px;
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
    text-align: left;
    border-radius: 0px;
    background-color: rgb(218, 218, 218);
    outline: none;
    font-weight: 700;
}


.groupselectioncontainer {
    width: 100%;
    margin-top: -20px;
    margin-bottom: 20px;
}

.groupdetailoptions {
    float: right;

}

.section-heading {
    font-size: 18px;
    margin-top: 16px;
    margin-bottom: 8px;
}

.group-members-count {
    list-style: none;
    padding: 0px;
    margin: 0px;
    text-align: right;
    font-size: 0.7rem;
}

.table-container {
    overflow-x: auto;
}

.purchase-table {
    width: 100%;
    border-collapse: collapse;
}

.tablenumbergroup2 {
    font-size: 0.9em;
    font-family: "Baloo 2", cursive;
    color: #4e4e4e;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px;
    position: relative;
}

.tablenumbergroup3 {
    max-width: 58px;
    font-size: 0.9em;
    font-family: "Baloo 2", cursive;
    color: #4e4e4e;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px;
    position: relative;
}

.worthiticon2 {
    width: 4px;
    position: absolute;
    bottom: 2px;
    right: 8px;
    font-size: 0.6rem;
    opacity: 80%;
}

.unmodifiedpurchasedot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgb(24, 119, 95);
}

.fullscreencontainer {
    position: absolute;
    bottom: 50px;
    right: 0;
    width: 100vw;
    z-index: 8000;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 92%;
    /* filter: drop-shadow(0 0 0.75rem rgb(43, 43, 43)); */
}

.grouppurchasedetailcontainer {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grouppurchasedetailcardcontainer {
    width: 100%;
    max-width: 680px;
    height: 80vh;
    overflow: hidden;
    display: flex;
    padding: 0px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0 0 0.75rem rgb(43, 43, 43));
}

.purchasedetailscontainer {
    text-align: left;
    padding: 5px;
    box-sizing: border-box;
    width: 98%;
    overflow: scroll;
    height: 100%;
    background-color: rgb(247, 246, 245);
    border-radius: 10px;
    -webkit-animation: slide-in-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.deletemygrouppurchasebutton {
    border: none;
    outline: none;
    background-color: transparent;
    text-decoration: underline;
    color: rgb(10, 62, 110);
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    float: right;
    margin-right: 10px;
    font-size: 0.7rem;
}

.deleteconfirmgrouppurchasebuttoncontainer {
    display: flex;
    height: 20%;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40%;
}

.confirmdeletegrouppurchaseicon {
    font-size: 3rem;
    color: rgb(112, 27, 27);
}

.confirmdeletepurchaseconfirmed {
    color: rgb(255, 255, 255);
    background: rgb(6, 59, 53);
    outline: none;
    border: none;
    border-radius: 5px;
    font-family: "Quicksand", sans-serif;
    height: auto;
    font-weight: 700;
    margin-top: 20px;
    padding: 15px 20px 15px 20px;
}

.modifiedpurchaseindicator {

    box-sizing: border-box;
    padding: 0px;
    height: 6px;
    /* margin-top: 9px; */
    background-color: transparent;
    color: rgb(155, 153, 153);
    /* float: left; */
    font-size: 0.5rem;
    text-align: right;
    line-height: 0.4rem;
    font-weight: 400;
    /* margin-left: 5px;
    margin-right: 4px; */
    border-radius: 5px 5px 5px 5px;
    position: absolute;
    bottom: 3px;
    left: 10px;

}

.modifiedpurchaseindicatordot {
    width: 10px;
    box-sizing: border-box;
    padding: 0px;
    height: 10px;
    margin-top: 7px;
    background-color: rgb(175, 175, 175);
    color: white;
    float: left;
    font-size: 0.5rem;
    text-align: center;
    line-height: 0.7rem;
    font-weight: 400;

    border-radius: 5px 5px 5px 5px;

}


.confirmdeletepurchasenotconfirmed {
    color: rgb(39, 39, 39);
    background: transparent;
    outline: none;
    border: none;
    border-radius: 5px;
    font-family: "Quicksand", sans-serif;
    height: auto;
    font-weight: 700;
    margin-top: 20px;
    padding: 15px 20px 15px 20px;
}

.confirmdeletegrouppurchasecontainer {
    width: 100%;
    height: 100%;
    padding: 30% 5% 30% 5%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
}

.purchasedetailscontainer2 {
    text-align: left;
    padding: 5px;
    box-sizing: border-box;
    width: 98%;
    overflow: scroll;
    height: 100%;
    background-color: rgb(247, 246, 245);
    border-radius: 10px;
}

.grouppurchasedescriptioncontainer {
    padding: 5px 0px 00px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.grouppurchasevotingcontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 40px 10px 10px 10px;
    justify-content: space-evenly;
    align-items: center;

}

.dateandratingcontainer {
    width: 86%;
    height: 21px;
    font-size: 0.7rem;
    color: rgb(34, 34, 34);
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 5px;
}

.purchaserating {
    line-height: 1rem;
    color: #cc8f0c;
}

.grouppurchasecommentscontainer {
    /* background-color: #852626; */
    margin-top: 20px;


}

.ratethispurchasecontainer {
    font-size: 1.2rem;
    color: #cc8f0c;
    padding-top: 20px;
}

.purchaseaddcommentcontainer {
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.newcommenttextarea {
    align-self: center;
    width: 95%;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgb(223, 223, 223);
    min-height: 80px;
    padding: 5px;
    margin: 5px;
    background-color: #fafafa;
    font-family: "Quicksand", sans-serif;
    color: rgb(15, 15, 15);
    font-weight: 600;
    outline: none;
    resize: vertical;
    overflow: scroll;
}

.addcommentbuttoncontainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.newcommentbutton {
    width: 30%;
    min-height: 25px;
    background-color: rgb(29, 56, 53);
    color: rgb(241, 241, 241);
    outline: none;
    border: none;
    border-radius: 5px;
    float: right;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
    margin-right: 10px;
    font-size: 0.8rem;

}


.postingaswho {
    width: 50%;
    font-size: 0.8rem;
    padding-left: 12px;
    padding-top: 3px;
    font-weight: 600;
    color: rgb(46, 46, 46);
}

.profilename {
    padding-left: 5px;
    color: rgb(15, 15, 15);
    font-size: 0.8rem;
}

.profileiconnameanddate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.profileiconandname {
    display: flex;
    flex-direction: row;
    font-weight: 700;



}

.editgrouppurchasecommentbutton {
    color: rgb(10, 62, 110);
    font-size: 0.7rem;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    background-color: transparent;
    text-decoration: underline;
    border: none;
    padding-bottom: 5px;

    text-align: left;
    padding-left: 0px;
}

.editgrouppurchasecommentbutton2 {
    color: #852626;
    font-size: 0.7rem;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    background-color: transparent;
    text-decoration: underline;
    border: none;
    padding-bottom: 5px;

    text-align: left;
    padding-left: 0px;
}

.editgrouppurchasecommentbutton3 {
    color: #383838;
    font-size: 0.7rem;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    background-color: transparent;
    text-decoration: underline;
    border: none;
    padding-bottom: 5px;

    text-align: left;
    padding-left: 0px;
}


.editgrouppurchasecommentbuttoncontainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.closegrouppurchasedetailcontainer {
    width: 100%;
    justify-content: right;
    display: flex;
}

.editcommentinput {
    margin: 10px;
    width: 95%;
    min-height: 55px;
    border: none;
    outline: none;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 0.8rem;
    font-weight: 500;
    font-family: "Quicksand", sans-serif;
    resize: vertical;
    overflow: scroll;
}

.closegrouppurchasedetailbutton {
    color: rgb(19, 19, 19);
    background: transparent;
    outline: none;
    border: none;
    font-family: "Quicksand", sans-serif;
    height: auto;
    font-weight: 700;
    margin-top: 10px;

}

.areyousuredeletecomment {
    font-size: 0.8rem;
    font-weight: 500;
    color: #161616;
}

.grouppurchasecommenttext {
    font-size: 0.8rem;
    font-weight: 500;
    color: #161616;
}

.commenttimestamp {
    align-self: right;
    font-size: 0.7rem;
    color: rgb(44, 44, 44);
    font-weight: 700;
}

.purchasecommentthread {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
}

.purchasecommentcontainer {
    /* background-color: #4e4e4e; */
    box-sizing: border-box;
    border-bottom: 1px solid #d3d3d3;
}


.grouppurchasedescriptionflexbox {
    display: flex;
    flex-direction: row;
    width: 95%;
    box-sizing: border-box;
    padding: 10px;
    justify-content: space-evenly;
    border: 1px solid rgb(29, 56, 53);
    border-radius: 10px;

}


.groupworthitbuttoncontainer {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.groupvoteworthit {
    background-color: transparent;
    outline: none;
    width: 60px;
    height: 60px;
    font-size: 1.7rem;
    border: none;
    display: flex;
    background-color: #e9e9e9;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.worthitcountbutton {
    font-size: 0.7rem;
    font-weight: 700;
    color: rgb(247, 247, 247);
    font-family: "Baloo 2", cursive;
}

.anonymousvotecontainer {
    color: rgb(15, 15, 15);
    font-size: 0.8rem;
    width: 40%;
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    line-height: 0.8rem;
}

.votescontainer {
    padding-top: 0px;
    color: rgb(15, 15, 15);
    display: flex;
    width: 100%;
    min-height: 26px;
    box-sizing: border-box;
    justify-content: left;
    align-items: left;
    padding: 0px 15px 0px 15px;
}

.no-comments-empty-state {
    width: 100%;

}

.worthitvotelabel {
    font-size: 0.7rem;
    color: rgb(245, 245, 245);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #4e4e4e;
    border-radius: 25px;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 1rem;
    margin: 5px;
}

.groupdetailsiconcontainer {
    font-size: 1.4rem;
    text-align: center;
    padding-top: 2px;
    border-radius: 50%;
    background-color: #f1f1f1;
    height: 35px;
    width: 35px;
    margin-left: 0px;
}











.invitation-message {
    height: 20px;
}

.success-message-invite {
    font-weight: 700;
    font-size: 0.8rem;
    color: rgb(6, 59, 53)
}

.error-message-invite {
    font-weight: 700;
    font-size: 0.8rem;
    color: #fc8f8f;
}

.error-message-invite-dark {
    font-weight: 500;
    font-size: 0.8rem;
    color: #c0c0c0;
}

.leaderstatus {
    font-weight: 600;
    padding-left: 14px;
}

.leaderusername {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    padding-left: 10px;
}

.barchartcontainer {
    width: 40%;
    height: 100%;

}

.barchartcanvas {
    max-height: 80px;
}

.tableheaderdd {

    width: 100%;

    /* max-width: 200px; */
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
    color: #161616;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 2px 2px 2px 8px;

}

.tableheaderee {

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* max-width: 200px; */
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
    color: #161616;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 2px 2px 2px 8px;

}

.table-header {
    font-weight: bold;
    background-color: #f1f1f1;
    padding: 8px;
    text-align: left;
}

.table-data {
    padding: 8px;
    border-bottom: 1px solid #ccc;
}

.group-manual-add-purchase {
    width: 100%;
    max-height: 320px;
    overflow: scroll;
}

.group-manual-add-purchase-form {
    box-sizing: border-box;
    padding: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

}

.group-manual-add-purchase-icon {
    background-color: #9e9e9e;
    border: none;
    width: 40px;
    margin-left: 45px;
    margin-right: 25px;
    font-size: 1.4rem;
    text-align: center;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.group-manual-add-purchase-currency {
    width: 20px;
    font-size: 1.4rem;
    font-weight: 500;
    color: rgb(116, 116, 116);
}

.group-manual-add-purcahse-currenyandamount-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 110px;
}


.group-manual-add-purchase-amount {
    background-color: transparent;
    color: #ebebeb;
    border: 1px dashed #ffbd2b;
    width: 80px;
    font-size: 1.5rem;
    text-align: center;
    outline: none;
    height: 40px;
    border-radius: 5px;
    font-family: "Baloo 2", cursive;
    overflow: none;

}

.group-manual-add-purchase-header {
    color: white;
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 500;
}

.group-manual-add-purchase-category {
    background-color: #f0f0f0;
    border: none;
    width: 50%;
    font-size: 0.8rem;
    font-family: "Quicksand", sans-sarif;
    color: rgb(51, 51, 51);
    font-weight: 600;
    text-align: left;
    padding-left: 10px;
    height: 40px;
    border-radius: 5px;
    outline: none;

}

.group-manual-add-purchase-note {
    background-color: #f0f0f0;
    border: none;
    width: 50%;
    font-size: 0.8rem;
    font-family: "Quicksand", sans-sarif;
    color: rgb(51, 51, 51);
    font-weight: 600;
    text-align: left;
    padding-left: 10px;
    height: 40px;
    border-radius: 5px;
    outline: none;
}


.group-manual-add-purchase-iconandnote-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5px;
}

.group-manual-add-purchase-amountandcategory-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 5px;
}

.recurring-purchase-edit-button {
    background-color: transparent;
    border: none;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    text-decoration: underline;
    color: rgb(10, 62, 110);
    font-size: 0.7rem;
    outline: none;
    float: right;
}

.recurring-purchase-date-header {
    color: rgb(15, 15, 15);
    font-size: 0.8rem;
    font-weight: 600;
}

.recurring-purchase-edit-amount {
    border: 1px solid rgb(10, 62, 110);
    max-width: 60px;
    outline: none;
    border-radius: 5px;
    background-color: #ffffff;
    font-family: "Baloo 2", cursive;
    text-align: right;
    font-size: 0.9rem;
    font-weight: 500;
    color: #4e4e4e;
    height: 20px;
}


.recurring-purchase-edit-note {
    max-width: 220px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    border: 1px solid rgb(10, 62, 110);
    outline: none;
    border-radius: 5px;
    background-color: #ffffff;
    font-family: "Quicksand", sans-serif;
    color: #4e4e4e;
    height: 20px;

}

.recurring-purchase-edit-day {
    max-width: 220px;
    width: 30px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    border: 1px solid rgb(10, 62, 110);
    outline: none;
    border-radius: 5px;
    background-color: #ffffff;
    font-family: "Quicksand", sans-serif;

}


.recurring-purchase-date-text {
    text-align: left;
    height: 15px;
    width: 70%;
    font-size: 0.7rem;
    padding: 0px;
    padding-bottom: 10px;
    padding-left: 20px;

    border-bottom: 0px solid gainsboro;
}

/* .recurring-purchase-edit-buttoncontainer {
    justify-content: right;
    display: flex;
    flex-direction: row;
    width: 30%;
} */

.recurring-purchase-row {
    margin: 10px;
    padding: 5px;

}

.recurring-purchase-recurrance {
    margin-top: 10px;
    font-weight: 500;
    color: white;

}

.recurring-purchases-info {
    font-size: 0.8rem;
    font-weight: 600px;
    color: rgb(15, 15, 15);
    padding: 5px;
}

.delete-recurring-group-purchase {
    background-color: transparent;
    color: #ff7171;
    text-decoration: underline;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
    outline: none;
    border: none;
    padding-top: 20px;
}

.recurring-purchase-recurrance-input {
    border-radius: 5px;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    color: rgb(15, 15, 15);
    outline: none;
    border: none;
    margin-left: 4px;
    margin-right: 4px;
}

.delete-recurring-purchase-confirmation {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 70px;
}

.recurring-checkbox-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 152px;
    height: 15px;
    text-align: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15%;
    padding-right: 15%;
    box-sizing: border-box;
    justify-content: space-around;
}

.recurring-purchases-div-container {
    border-radius: 10px;
    background-color: #ffffff;
}

.recurring-purchases-div-container2 {
    border-radius: 10px;
    background-color: #ffffff;
    font-size: 0.8rem;
}

.add-new-recurring-expense-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-bottom: 5px;
}


.add-new-recurring-expense-button {
    background-color: rgb(9, 71, 64);
    font-size: 0.8rem;
    font-weight: 500;
    font-family: "Quicksand", sans-serif;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    color: white;
    box-sizing: border-box;

}

.add-new-recurring-expense-button2 {
    background-color: rgb(83, 83, 83);
    font-size: 0.8rem;
    font-weight: 500;
    font-family: "Quicksand", sans-serif;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    color: white;
    box-sizing: border-box;

}

.recurring-purchases-div-header {
    height: 15px;
    font-size: 0.8rem;
    line-height: 0.8rem;
    padding-top: 2px;
    padding-bottom: 1px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    background-color: #383838;
    color: white;
    border-radius: 10px 10px 0px 0px;
}

.recurring-purchase-bottomborder {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    width: 80%;
    height: 1px;
    border-bottom: 1px solid rgb(236, 236, 236);
}


@media (max-width: 768px) {
    .group-name {}

    .section-heading {
        font-size: 16px;
    }

    .table-data,
    .table-header {
        padding: 6px;
    }
}



@media (max-height: 700px) {
    .citybackcontainer {
        width: 200px;
        height: 200px;
    }

    .lightmast {
        display: none;
    }

    .lilwindow {
        display: none;
    }

    .lilman {
        display: none;
    }



    .bigwindow {
        display: none;
    }

    .empty-state-review-header {
        margin: 5px;
    }

    .datereviewdonuticon {
        font-size: 6rem;
    }

    .confirmdeletegrouppurchasecontainer {
        padding-top: 5%;
        padding-bottom: 5%;
    }

}

@media (max-height: 580px) {
    .citybackcontainer {
        width: 180px;
        height: 180px;
    }

    .lightmast {
        display: none;
    }

    .lilwindow {
        display: none;
    }

    .lilman {
        display: none;
    }

    .datereviewdonuticon {
        font-size: 5rem;
    }

    .bigwindow {
        display: none;
    }

}

@media (max-height: 520px) {
    .citybackcontainer {
        width: 160px;
        height: 160px;
    }

    .empty-state-review-message {
        font-size: 0.8rem;
    }

    .lightmast {
        display: none;
    }

    .lilwindow {
        display: none;
    }

    .lilman {
        display: none;
    }

    .datereviewdonuticon {
        font-size: 4rem;
    }

    .bigwindow {
        display: none;
    }

}


@media (max-height: 320px) {
    .confirmdeletegrouppurchasecontainer {
        padding-top: 5%;
        padding-bottom: 5%;
    }

}