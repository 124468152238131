.chartcanvas2 {
    background-color: rgb(29, 56, 53);
    border-radius: 10px 10px 10px 10px;
    height: 80px;
    padding: 10px 10px 10px 10px;
    margin: 0px 0px 0px 0px;
    position: relative;
}

.dontdisplay {
    display: none;
}

.budgetsignature {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 20%;
}