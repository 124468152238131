.custom-number-pad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0px;
}

.custom-number-pad button {
    width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    color: #2c2c2c;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 0px;
    background-color: #fff;
    cursor: pointer;
    font-family: 'Baloo 2';
    font-weight: 700;
}

.custom-number-pad button:hover {
    background-color: #f5f5f5;
}

.custom-number-pad button:focus {
    outline: none;
    box-shadow: 0 0 3px 1px #aaa;
}


.custom-number-pad .number-row:last-child button {
    width: 100%;
    grid-column: span 1;
}

.custom-number-pad .number-row:last-child button:nth-child(2) {
    grid-column: 2;
}

.custom-number-pad .number-row:last-child button:nth-child(3) {
    grid-column: 3;
}