@-webkit-keyframes color-change-4x {
    0% {
        background: #0a0a0a;
    }

    25% {
        background: #180e4e;
    }

    50% {
        background: #5d696b;
    }

    75% {
        background: #e41414;
    }

    100% {
        background: #000000;
    }
}

@keyframes color-change-4x {
    0% {
        background: #0a0a0a;
    }

    25% {
        background: #180e4e;
    }

    50% {
        background: #5d696b;
    }

    75% {
        background: #e41414;
    }

    100% {
        background: #000000;
    }
}

@-webkit-keyframes bg-pan-left {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes bg-pan-left {
    0% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.achievement-table {
    display: relative;
    z-index: 50;
    transition: top 0.3s ease-out;
    border-radius: 10px;
    height: 100vh;
    overflow: auto;
    padding-bottom: 240px;
}

.achievementwobble {
    -webkit-animation: heartbeat 1.5s ease-in-out both;
    animation: heartbeat 1.5s ease-in-out both;
}

.achievementscontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f100;
    border-radius: 10px;
    /* margin-bottom: 20px; */
    padding-bottom: 20px;

}


.achievedgoalscontainer {

    margin-bottom: 200px;
}

.goldstar {
    display: table-cell;
    vertical-align: middle;
    font-size: 1.5rem;
    width: 40px;
    text-align: center;
}

.goldstartodo {
    display: table-cell;
    vertical-align: middle;
    font-size: 1.5rem;
    width: 40px;
    text-align: center;
    opacity: 50%;
}

.achievementtextcontainer {
    display: table;
    height: 40px;
}

.achievementtext {
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;
}

.achievementspecialtext {
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;
    color: rgba(0, 0, 0, 0.836);
}

.achievementsubtext {
    font-size: 0.7rem;
    font-weight: 600;
    color: rgb(61, 61, 61);
    padding: 5px 5px 0px 0px;
    margin: 0;
}

.achievementspecialsubtext {
    font-size: 0.7rem;
    font-weight: 600;
    color: rgb(151, 151, 151);
    padding: 5px 5px 0px 0px;
    margin: 0;
}

.achievementgood {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 0.8rem;
    box-sizing: border-box;
    width: 98%;
    height: auto;
    border-radius: 10px;
    border: 1px solid rgb(0, 100, 100);
    background: linear-gradient(to bottom left, rgba(223, 210, 210, 0.61), rgba(238, 237, 209, 0.541), rgba(12, 136, 64, 0.404));
    padding: 5px;
    margin: 5px;
    color: #2c2c2c;
    font-weight: 700;
}

.achievementtodo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 0.8rem;
    box-sizing: border-box;
    width: 98%;
    height: auto;
    border-radius: 10px;
    background-color: rgb(245, 245, 245);
    border: 1px solid rgb(129, 129, 129);
    padding: 5px;
    margin: 5px;
    color: #838383;
    font-weight: 600;
}

.achievementgoal {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 0.8rem;
    box-sizing: border-box;
    width: 98%;
    height: auto;
    border-radius: 10px;
    border: 1px solid rgb(129, 129, 129);
    background: linear-gradient(to bottom right, #f1ede3, #eed383, #fff6cb);
    padding: 5px;
    margin: 10px 5px 5px 5px;
    color: #2c2c2c;
    font-weight: 700;
    opacity: 100%;
}

.achievementscontainerheader {
    width: 100%;
    padding-left: 0px;
    padding-top: 20px;
    text-align: center;
    color: #2c2c2c;
    font-weight: 700;
    font-size: 1.4rem;
    padding-bottom: 0px;
    margin-bottom: 10px;
}

.achievementbad {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 0.8rem;
    box-sizing: border-box;
    width: 98%;
    height: auto;
    border-radius: 10px;
    background: linear-gradient(to bottom left, rgba(255, 255, 255, 0.61), rgba(243, 230, 154, 0.541), rgba(160, 105, 135, 0.5));
    border: 1px solid #4b4b4b;
    padding: 5px;
    margin: 5px;
    color: #181818;
    font-weight: 700;
}

.achievementneutral {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 0.8rem;
    box-sizing: border-box;
    width: 98%;
    height: auto;
    border-radius: 10px;
    background: linear-gradient(to bottom left, rgba(245, 238, 238, 0.61), rgba(215, 225, 231, 0.541), rgba(248, 248, 248, 0.822));
    border: 1px solid #4b4b4b;
    padding: 5px;
    margin: 5px;
    color: #181818;
    font-weight: 700;
}

.achievementspecial {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-size: 0.9rem;
    box-sizing: border-box;
    width: 98%;
    height: auto;
    border-radius: 10px;
    -webkit-animation: color-change-4x 12s linear infinite alternate both;
    animation: color-change-4x 12s linear infinite alternate both;
    border: none;
    padding: 0px;
    margin: 5px;
    color: #000000;
    font-weight: 700;
}

.achievementspecialcontainer {
    width: 100%;
    margin: 1px;
    padding: 10px;
    height: auto;
    border-radius: 8px;
    background: linear-gradient(to bottom left, rgba(58, 58, 58, 0.836), rgba(112, 77, 77, 0.925), rgba(14, 15, 15, 0.774));
    box-sizing: border-box;
}

.achievements-goal-display {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.achievements-goal-delete-goal-button {
    outline: none;
    border: none;
    background-color: transparent;
    font-family: "Quicksand", sans-sarif;
    font-weight: 600;
    text-decoration: underline;
    text-align: center;
    font-size: 0.8rem;
    color: rgb(10, 62, 110);
    margin-bottom: 25px;
}