.expense-reminders {
    width: 100%;
    height: 100%;
    background-color: rgb(247, 246, 245);
    margin: 0;
    box-sizing: border-box;
    padding: 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.expense-reminders-header1 {
    color: rgb(56, 56, 56);
    font-size: 1.1rem;

}

.expense-reminders-header2 {
    color: rgb(100, 100, 100);
    font-size: 0.7rem;
    font-weight: 700;

}

.expense-reminders-header3 {
    width: 100%;
    box-sizing: border-box;
    color: rgb(48, 48, 48);
    font-size: 0.9rem;
    padding: 20px 10px 0px 25px;
    text-align: left;
    margin: 0;
}


.expense-reminders__list-item {
    width: 94%;
    /* max-width: 480px; */
    box-sizing: border-box;
    margin: 10px 10px 0px 10px;
    /* padding: 15px; */
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
}

.expense-reminders-expense-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.expense-reminders-name {
    font-weight: 700;
}

.expense-reminders-liflex {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.8rem;
}

.expense-reminders-liflex1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    font-size: 0.8rem;
}

.expense-reminders-liflex2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    font-size: 0.8rem;
}

.expense-reminders__list-item--due-soon {
    border: 1px solid rgb(6, 59, 53);
    background-color: rgb(255, 255, 255);
    color: rgb(48, 48, 48);
    padding: 5px;
}

.expense-reminders__list-item--due-later {
    border: 1px solid rgb(158, 158, 158);
    /* border: 2px solid green; */
    background-color: rgb(255, 255, 255);
    color: rgb(48, 48, 48);
    padding: 5px;
}

.expense-reminders__list-item--due-far-away {
    /* border: 2px solid rgb(100, 99, 99); */
    background-color: rgb(228, 228, 228);
    color: rgb(88, 88, 88);
    padding: 5px;
}

.expense-reminders__list-item--overdue {
    border: 1px solid #852626;
    background-color: rgb(253, 241, 241);
    color: rgb(85, 31, 31);
    padding: 5px;
    font-weight: 700;
}

.expense-reminders-autopay-indicator {
    font-size: 0.7rem;

}



.expense-reminders-paid-button {
    background-color: transparent;
    border: 1px solid rgb(6, 59, 53);
    border-radius: 5px;
    font-size: 0.7rem;
    outline: none;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
    color: rgb(48, 48, 48);
}



.expense-reminders-undo-paid-button {
    background-color: transparent;
    border: none;
    border-radius: 5px;
    font-size: 0.7rem;
    text-decoration: underline;
    outline: none;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
    color: rgb(6, 59, 53);
}

.e-r-a-paid {
    border-radius: 10px;
    color: rgb(6, 59, 53);
    font-weight: 700;
    padding-left: 5px;
    padding-right: 5px;
}

.e-r-a-manual {
    border-radius: 10px;
    background-color: transparent;
    color: grey;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
}

.e-r-a-auto {
    border-radius: 10px;
    background-color: #dbdbdb;
    color: rgb(6, 59, 53);
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
}

.expense-reminders-overdue {
    width: 100%;
    padding: 0;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(48, 48, 48);
}

.expense-reminders-upcoming {
    width: 100%;
    padding: 0;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(48, 48, 48);
}

.expense-reminders-faraway {
    width: 100%;
    display: flex;
    padding: 0;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    color: rgb(48, 48, 48);
}

.expense-reminders-noduedate {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(48, 48, 48);

    justify-content: space-between;
}

.expense-reminders-edit-reminders-button {
    background-color: transparent;
    border: none;
    border-radius: 5px;
    font-size: 0.7rem;
    text-decoration: underline;
    outline: none;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
    color: rgb(10, 62, 110);
    margin-top: 20px;

}

.expense-reminders-topanchor {
    position: absolute;
    top: 0;
}

.expense-reminders-close-button {
    background-color: transparent;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    outline: none;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
    color: rgb(48, 48, 48);
    margin-top: 60px;
    margin-bottom: 120px;
}

.expense-reminders-billimage {
    width: 90px;
    animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.expense-reminders-bill-image-container {
    min-height: 82px;
    width: 100%;

    text-align: center;
}

.expense-reminders-present-container {
    width: 100%;
}