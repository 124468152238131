.incomebudgetformcomponentcontainer {
    width: 100%;
    box-sizing: border-box;
    /* margin: 5px; */
    background-color: #f7f7f7fd;
    height: 100%;
    padding: 5px 5px 10px 8px;
    border-left: 3px solid #ffbd2b;
    border-radius: 10px;
    height: 95vh;
    overflow: scroll;

}

.cebits {
    width: 15%;
    position: absolute;
    right: 10%;
    top: 4%;
    opacity: 80%;
}

.calculateyourbudget {
    margin: 0px;
    color: rgb(31, 31, 31);
    font-size: 0.9rem;
    margin-top: 10%;
    text-decoration: underline;
}

.incomebudgetformcontainer {

    margin-top: -20px;
    -webkit-animation: scale-up-ver-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-ver-top 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.incomebudgetformcontainer.show {
    opacity: 1;
}

.incomeheader {
    font-size: 0.8rem;
    font-weight: 500;
    color: #1b1b1b;
    padding-top: 20px;
    /* padding-left: 10px; */
    padding-bottom: 3px;
}

.incomeheaderb {
    font-size: 0.8rem;
    font-weight: 500;
    color: #1b1b1b;
    padding-top: 20px;
    /* padding-left: 10px; */
    padding-bottom: 10px;
}

.incomeheader2 {
    font-size: 0.6rem;
    font-weight: 500;
    color: #9c9c9c;
    /* padding-left: 10px; */
    padding-bottom: 5px;
}

.currencysymbol1 {
    padding-left: 15px;
    font-size: 1.2rem;
    color: #1b1b1b;
    font-weight: 600;
}

.currencysymbol2 {
    padding: 0px;
    margin: 0px;
    font-size: 0.8rem;
    color: #585858;
    font-weight: 600;
    font-family: 'Baloo 2', cursive;
}

.currencysymbol3 {
    padding-left: 5px;
    width: 10px;
    font-size: 0.8rem;
    color: #1b1b1b;
    font-weight: 600;
}

.incomeinput {
    width: 20%;
    height: 35px;
    border-radius: 5px;
    border: none;
    font-size: 1rem;
    font-family: 'Baloo 2', cursive;
    margin-left: 5px;
    text-align: center;
}

.expenserow {
    height: 30px;
}

.expense1 {
    width: 55%;
    text-align: left;
    border-radius: 0px;
    padding-top: 0px;
    /* padding-left: 20px; */
    height: 30px;
    background-color: #f7f7f7fd;

}

.expense2 {
    width: 25%;
    border-radius: 0px;
    padding-top: 0px;
    height: 25px;
    background-color: #f7f7f7fd;
    color: rgb(70, 70, 70);
    text-align: left;
}

.expense3 {
    width: 25%;
    margin: 0px;
    border-radius: 0px;
    padding-top: 0px;
    padding-right: 25px;
    line-height: 50px;
    box-sizing: border-box;
    height: 30px;
    background-color: #f7f7f7fd;
    color: rgb(70, 70, 70);
}

.expensename {
    text-align: left;
    font-size: 0.7rem;
    padding: 10px 10px 0px 10px;
    color: rgb(110, 110, 110);
    /* padding-left: 20px; */
    background-color: #f7f7f7fd;
}

.expenseamount {
    /* width: 100%; */
    text-align: center;
    font-size: 0.7rem;
    padding: 10px 10px 0px 10px;
    color: rgb(110, 110, 110);
    /* padding: 10px;
    padding-left: 20px; */
    background-color: #f7f7f7fd;
}

.expensebuttoncontainer {
    width: 20%;
}

.deletebutton {
    padding: 0px 10px 10px 10px;
    background-color: #f7f7f7fd;
    line-height: 1.4rem;
}

.incomesavingstip {
    width: 100%;
    padding-left: 0px;
    font-size: 0.6rem;
    color: #9c9c9c;
}

.deleteexpensebutton {
    border-radius: 5px;

    border: none;
    color: #852626;
    font-weight: 500;
    border: 1px solid #852626;
    outline: none;
    font-size: 0.7rem;
    font-family: "Quicksand", sans-serif;
    ;
    width: 25px;
    margin-right: 25px;

}

.updatemessage {
    height: 35px;
    margin-left: 5px;
    color: rgb(22, 22, 22);
    font-weight: 600;
    font-size: 0.7rem;
    margin-top: 10px;
}

.incomebudgetform-buttoncontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.expensebutton {
    border-radius: 5px;
    height: 25px;
    border: none;
    color: #696969;
    font-weight: 500;
    border: 1px solid #696969;
    outline: none;
    margin-right: 15px;
    font-size: 0.7rem;
    font-family: "Quicksand", sans-serif;
    ;
    margin-top: 0px;
    width: 90%;
    box-sizing: border-box;

}

.expensebutton2 {
    border-radius: 5px;
    width: 60%;
    max-width: 440px;
    border: none;
    color: #161616;
    background-color: #ffbd2b;
    font-weight: 600;
    border: none;
    outline: none;
    height: 35px;
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
    ;
    /* margin-right: 10px; */
    margin-bottom: 10px;
    /* margin-left: 20px; */


}

.expensebutton3 {
    border-radius: 5px;
    border: none;
    color: #242424;
    background-color: transparent;
    font-weight: 500;
    border: none;
    outline: none;
    height: 35px;
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
    width: 60%;
    max-width: 440px;

    margin-bottom: 10px;
    /* margin-left: 20px; */
    text-decoration: underline;

}


.expensebutton4 {
    border-radius: 5px;
    border: none;
    color: rgb(6, 59, 53);
    background-color: transparent;
    font-weight: 500;
    border: 1px solid rgb(6, 59, 53);
    outline: none;
    height: 35px;
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
    width: 60%;
    max-width: 440px;
    margin-bottom: 10px;

    /* margin-left: 20px; */


}

.expensebutton6 {
    border-radius: 5px;
    border: none;
    color: #3b3b3b;
    background-color: #afafaf;
    font-weight: 500;
    border: none;
    outline: none;
    height: 35px;
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
    width: 60%;
    max-width: 440px;
    margin-bottom: 10px;

    /* margin-left: 20px; */


}


.bottomborder {
    border-bottom: 1px solid rgb(184, 184, 184);
    width: 100%;
    margin-top: 3%;
}


.modified {
    background-color: yellow;
}

.expenseinput1 {
    width: 90%;
    height: 25px;
    border-radius: 5px;
    border: none;
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
    ;
    box-sizing: border-box;
    padding-left: 5px;

}

.expenseinput2 {
    margin-left: 5px;
    text-align: center;
    width: 70%;
    height: 25px;
    border-radius: 5px;
    border: none;
    font-size: 0.8rem;
    font-family: 'Baloo 2', cursive;
    padding: 0px;
    box-sizing: border-box;
}

.discretionaryincomeheader {
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
    ;
    color: #242424;
    /* padding-left: 5%; */
    padding-top: 0%;
    padding-left: 5px;
    margin-top: 0%;
}


.expensebutton2:disabled {
    background-color: #696969;
    color: rgb(184, 184, 184);
}

.expensebutton4:disabled {
    background-color: #696969;
    color: rgb(184, 184, 184);
    border: none;
}

.ibfa-income-budget-form-advanced {
    padding: 10px;
    -webkit-animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.ibfa-expense-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.ibfa-header {
    width: 100%;
    margin-top: 30px;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    color: rgb(31, 31, 31);
}

.ibfa-expense-row {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    font-size: 0.8rem;
    align-items: center;
    /* margin-bottom: 5px; */
    font-size: 0.8rem;
    font-weight: 700;
    color: rgb(31, 31, 31);
}

.ibfa-expense-row1 {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    font-size: 0.8rem;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 5px; */
    font-size: 0.8rem;
    font-weight: 700;
    color: rgb(31, 31, 31)
}

.ibfa-expense-row2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 0.8rem;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 5px; */
    font-size: 0.8rem;
    font-weight: 500;
    color: rgb(31, 31, 31)
}

.ibfa-expense-name {
    font-weight: bold;
    color: rgb(31, 31, 31);
    margin-right: 10px;
}

.ibfa-expense-amount {
    margin-right: 10px;
    color: rgb(31, 31, 31);
    font-weight: 700;
}

.ibfa-due-date {
    margin-right: 10px;
    color: rgb(9, 75, 71);
    font-weight: 600;
}

.ibfa-due-date-input {
    width: auto;
    padding-right: 2px;
    margin: 0px;
    font-size: 0.8rem;
    margin-right: 2px;
    margin-left: 2px;
    color: rgb(31, 31, 31);
    outline: none;
    border: none;
    background-color: white;
    border-radius: 5px;
    font-family: "Quicksand", sans-serif;
    text-align: right;
    font-size: 1rem;
    font-weight: 700;
    border: 1px rgb(206, 206, 206) solid;
}

.ibfa-due-date-dropdown {
    height: 25px;
    padding: 2px;
    width: auto;
    font-size: 0.8rem;
    color: rgb(31, 31, 31);
    outline: none;
    border: 1px solid rgb(235, 235, 235);
    background-color: rgb(255, 255, 255);
    font-family: "Quicksand", sans-serif;
}

.ibfa-ordinal-suffix {
    width: 20px;
    font-size: 0.8rem;
    margin-right: 10px;
    color: rgb(31, 31, 31);
}

.ibfa-due-date-submit {
    font-size: 0.8rem;
    margin-right: 10px;
    border: none;
    outline: none;
    background-color: rgb(9, 75, 71);
    font-family: "Quicksand", sans-serif;
    color: #ffbd2b;
    font-weight: 700;
    border-radius: 5px;
}

.ibfa-due-date-submit:disabled {
    background-color: #696969;
    color: rgb(43, 43, 43);

}


.ibfa-due-date-cancel {
    font-size: 0.8rem;
    margin-right: 10px;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: "Quicksand", sans-serif;
    color: grey;
}

.ibfa-autopay-checkbox-label {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    width: 70px;
    justify-content: space-between;
    margin-top: 5px;
    color: rgb(31, 31, 31);
}

.ibfa-close {
    justify-self: center;
    width: 80%;
    max-width: 300px;
    color: rgb(255, 255, 255);
    background: rgb(6, 59, 53);
    outline: none;
    border: none;
    border-radius: 5px;
    font-family: "Quicksand", sans-serif;
    height: auto;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 60px;
    padding: 15px 20px 15px 20px;
}

.ibfa-button-container {
    /* position: fixed;
    bottom: -20px; */
    left: 0;
    margin: 0px;
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.ibfa-topanchor {
    position: absolute;
    top: 0;
}