@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@400;600;900&display=swap');

.aboutus-container {
    /* margin-top: 20%; */
    width: 100%;
    height: 100vh;
    padding-top: 20px;
    margin: 0;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    overflow: auto;

}

.aboutus-header {
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: left;
    color: #424242;
    font-family: 'Inter Tight', sans-serif;
}

.aboutus-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 88%;
    /* height: 100vh; */
    /* margin-bottom: 200px; */
    max-width: 812px;

}

.aboutus-item {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    margin: 5px;
    background-color: #444444;
    border-radius: 10px 10px 10px 10px;
    color: rgb(240, 240, 240);
    font-size: 0.9rem;
    font-weight: 600;
    text-align: left;
}

.aboutus-item.active {
    background-color: rgb(29, 56, 53);
    border-radius: 10px 10px 0px 0px;
    font-weight: 700;
    margin: 5px 5px 0px 5px;
    color: #fafafa;
    font-size: 0.9rem;
    text-align: left;

}

.aboutus-textheader {
    font-weight: 700;
    font-size: 1rem;
    color: #ffbd2b;
    padding: 5px 0px 5px 0px;
}

.aboutus-textcontent {
    font-weight: 500;
    font-size: 0.8rem;
    color: rgb(235, 235, 235);
}

.aboutus-textheader2 {
    font-weight: 700;
    font-size: 1rem;
    color: #ffbd2b;
    padding: 5px 0px 5px 0px;
}

.aboutus-textheader3 {
    font-weight: 700;
    font-size: 0.8rem;
    color: #dad5cb;
    padding: 5px 0px 5px 0px;
}

.aboutus-textcontent2 {
    font-weight: 400;
    font-size: 0.8rem;
    color: rgb(235, 235, 235);
}

.aboutus-textheader4 {
    font-weight: 700;
    font-size: 0.8rem;
    color: #ffbd2b;
    padding: 15px 0px 2px 0px;
}

.aboutus-textcontent3 {
    font-weight: 500;
    font-size: 0.8rem;
    color: rgb(245, 247, 222);
}




.aboutus-content {
    width: 100%;
    height: fit-content;
    overflow: scroll;
    margin-bottom: 5px;
    padding: 10px;
    background-color: rgba(29, 56, 53, 0.295);
    color: #dfdfdf;
    border-radius: 0px 0px 10px 10px;
    font-size: 0.8rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-clip: border-box;
    background-origin: border-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.aboutus-contentmission {
    width: 100%;
    height: fit-content;
    max-height: 600px;
    overflow: scroll;
    margin-bottom: 5px;
    padding: 10px;
    background-color: rgba(29, 56, 53, 0.295);
    color: #dfdfdf;
    border-radius: 0px 0px 10px 10px;
    font-size: 0.8rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-clip: border-box;
    background-origin: border-box;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}

.aboutus-contentfaqs {
    width: 100%;
    height: fit-content;
    /* overflow: scroll; */
    margin-bottom: 5px;
    padding: 10px;
    background-color: rgba(29, 56, 53, 0.295);
    color: #dfdfdf;
    border-radius: 0px 0px 10px 10px;
    font-size: 0.8rem;
    position: relative;
    display: flex;
    flex-direction: column;

    align-items: center;
    background-clip: border-box;
    background-origin: border-box;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: fit;
}


.aboutus-map {

    opacity: 100%;
    /* height: 100%; */
    width: 100%;
    top: 0;
    /* left: 50%;
    transform: -50%; */
    position: absolute;
    z-index: -1;
}

.aboutus-map {

    opacity: 100%;
    /* height: 100%; */
    width: 100%;
    top: 0;
    /* left: 50%;
    transform: -50%; */
    position: absolute;
    z-index: -1;
}

.whowearecontainer {
    width: 100%;
    height: auto;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.whowearewindow {
    width: 100%;
    height: 100%;
    background-color: #00000077;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5%;
}

.whowearewindowmission {
    width: 100%;
    overflow: auto;
    height: auto;
    background-color: #000000d7;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5%;
}

.whowearecontainerfaqs {
    width: 100%;
    height: 80%;
    max-height: 600px;
    overflow: scroll;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    border-radius: 5px;
    padding: 0% 0% 0% 0%;
}

.whowearewindowfaqs {
    width: 100%;
    height: auto;
    background-color: #000000d7;
    color: rgb(235, 235, 235);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 1% 5% 5% 5%;
    justify-self: top;
}

.closeaboutus {
    width: 80px;
}