.budgetdetails-overlay {
    position: relative;
}

.budgetdetails-container {
    /* overflow-y: hidden; */
    color: black;
    font-size: 0.7rem;
    transition: height 0.3s ease-in-out;
}

.budget-details-td {
    padding: 0px;
    margin: 0px;
}

/* .budgetdetails-container button {
    margin-bottom: 10px;
}

.budgetdetails-container table {
    margin-top: 10px;
} */

.categorypurchasedescriptionflexbox {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    justify-content: space-evenly;
    border: none;
    border-bottom: 1px solid rgb(236, 236, 236);
    background-color: rgb(250, 250, 250);


}

.categorydetailstablenumbergroup {
    font-size: 0.9em;
    font-family: "Baloo 2", cursive;
    color: #4e4e4e;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0px;
    position: relative;
    width: 30%;
}

.categorydetailstableicongroup {
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Baloo 2", cursive;
    color: #4e4e4e;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0px;
    position: relative;
    width: 30%;
}

.categorydetailstablenote {
    width: 100%;
    display: flex;
    /* max-width: 200px; */
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
    color: #161616;
    font-weight: 600;
    overflow: hidden;
    text-align: left;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 2px 2px 2px 8px;

}

.categorydetailstableaddedby {
    text-align: right;
    font-size: 0.7rem;
    font-family: "Quicksand", sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 15px;
    color: rgb(58, 58, 58);
    font-weight: 400;
}

.categorydetailstableamount {
    text-align: right;
    font-size: 0.8rem;
    font-family: "Baloo 2", sans-serif;
    font-weight: 700;
    color: rgb(58, 58, 58);
}

.categorydetailstablenegativeamount {
    text-align: right;
    font-size: 0.8rem;
    font-family: "Baloo 2", sans-serif;
    font-weight: 700;
    color: rgb(17, 80, 20);
}

.categorypurchasetabledata {
    padding: 0px;
    margin: 0px;
}

.categorydetailsnote {
    font-size: 0.7rem;
    max-width: 220px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
}

.categorydetailstabledate {
    color: rgb(58, 58, 58);
    max-width: 220px;
    height: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 0.7rem;
    font-family: "Baloo 2", sans-serif;
}

.categorydetailsiconcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    opacity: 85%;
    text-align: center;
    padding-top: 2px;
    border-radius: 50%;
    background-color: transparent;
    height: 35px;
    width: 35px;
    margin-left: 0px;
}

.nocategorypurchasesemptystate {
    width: 100%;
    color: rgb(58, 58, 58);
    text-align: center;
}

.unmodifiedpurchasedot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgb(24, 119, 95);
}

.purchaseindicatordotcontainer {
    width: 4px;
    position: absolute;
    bottom: 6px;
    right: 6px;
    font-size: 0.6rem;
    opacity: 80%;
}