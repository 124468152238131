.custom-date-picker {
    color:rgba(0, 0, 0, 0.25);
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    /* border-radius: 8px; */
    padding: 16px;
    font-size: 16px;
  }
  
  .custom-date-picker-header {
    color:rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .custom-date-picker-header button {
    
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 24px;
    cursor: pointer;
  }
  
  .custom-date-picker-header h1 {
    color:rgba(0, 0, 0, 0.76);
    font-size: 24px;
    margin: 0;
  }
  
  .custom-date-picker-days-of-week {
    color:rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .custom-date-picker-days-of-week div {
    color:rgba(0, 0, 0, 0.753);
    flex: 1;
    text-align: center;
  }
  
  .custom-date-picker-days {
    color:rgba(0, 0, 0, 0.589);
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 8px;
  }
  
  .custom-date-picker-day {
    display: flex;
    color:rgba(0, 0, 0, 0.74);
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .custom-date-picker-day.selected {
    background-color: #333;
    color: #ffffff;
  }
  
  .custom-date-picker-day.blank {
    visibility: hidden;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }