@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@100;700&family=Raleway:wght@300;400;500;700;900&display=swap');


button:active {
  outline: none;
}

button:hover {
  outline: none;
}

button:focus {
  outline: none;
}


@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.iostext1 {
  position: absolute;
  bottom: 30%;
  width: 100%;
  text-align: center;
}

.iosarrow1 {
  width: 25px;
  position: absolute;
  bottom: 15px;
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.iostext2 {
  position: absolute;
  bottom: 20%;
  width: 100%;
  text-align: center;
}


.onboarding {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: top 0.3s ease-in-out;
}

.onboard-nav-spacer {
  width: 100%;
  height: 100px;
}

.slide1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
  background-color: #3a3a3a;
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: top 0.3s ease-in-out;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.slide2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
  background-color: #274b5a;
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: top 0.3s ease-in-out;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.slide3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
  background-color: #3e523d;
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: top 0.3s ease-in-out;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: top 0.3s ease-in-out;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}



.arrow1 {
  width: 25px;
  position: absolute;
  top: 15px;
  right: 16px;
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.bracket {
  width: 25px;
  position: absolute;
  top: 15%;
  left: 30%;
  -webkit-animation: heartbeat 1.5s ease-in-out 3s infinite both;
  animation: heartbeat 1.5s ease-in-out 3s infinite both;
}

.slide2text1 {
  position: absolute;
  top: 15px;
  right: 55px;
  font-size: 1.0rem;
  font-weight: 700;
  color: #eeeeee;
}

.slide2text2 {
  position: absolute;
  width: 25%;
  text-align: right;
  top: 28%;
  left: 1%;
  font-size: 1.0rem;
  font-weight: 700;
  color: #eeeeee;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.slide3text1 {
  text-align: center;
  font-size: 1.0rem;
}


.arrow2 {
  width: 25px;
  position: absolute;
  left: 20%;
  top: 60%;
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.slidebutton {
  padding: 0.5em 1em;
  background-color: #3a3a3a00;
  color: #fff;
  border: none;
  border-radius: 0.25em;
  font-size: 1.0em;
  cursor: pointer;
  border: 1px solid #f7f7f7;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  width: 60%;
  position: absolute;
  bottom: 60%;
}


.slideescapebutton {
  padding: 0.5em 1em;
  background-color: #788ea700;
  color: #aaaaaa;
  border: none;
  border-radius: 0.25em;
  font-size: 1.0em;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  width: 150px;
  position: absolute;
  bottom: 5%;
}

.slidebutton2 {
  padding: 0.5em 1em;
  background-color: #274b5a00;
  color: #eeeeee;
  border: none;
  border-radius: 0.25em;
  font-size: 1.0em;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  width: 150px;
  position: absolute;
  bottom: 120px;
}

.slidebuttonios {
  padding: 0.5em 1em;
  background-color: #274b5a00;
  color: #eeeeee;
  border: none;
  border-radius: 0.25em;
  font-size: 1.0em;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  border: 1px solid #f7f7f7;
  width: 60%;
  position: absolute;
  bottom: 40%;
}

.slideescapebutton2 {
  padding: 0.5em 1em;
  background-color: #788ea700;
  color: #a3a3a3;
  border: none;
  border-radius: 0.25em;
  font-size: 1.0em;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  width: 150px;
  position: absolute;
  bottom: 60px;
}

.slideescapebutton3 {
  padding: 0.5em 1em;
  background-color: #788ea700;
  color: #aaaaaa;
  border: none;
  border-radius: 0.25em;
  font-size: 1.0em;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  width: 150px;
  position: absolute;
  bottom: 10%;
}

.slide1container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20%;
}

.slide1text {
  display: flex;
  text-align: leftr;
  font-size: 1.2;
  font-weight: 400;
}

.googleplaycontainer {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 120px;
}

.googleplayicon {
  width: 60px;
}

.googleplaytext {
  text-align: center;
  font-size: 0.8rem;
  margin-top: 20px;
  font-weight: 500;
  color: rgb(238, 238, 238);

}

.googleplaylink {
  text-decoration: none;
}

.closevaluepropcont {
  background-color: rgb(6, 59, 53);
  border-radius: 5px;
  color: rgb(245, 245, 245);
  height: 45px;
  box-sizing: border-box;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
  border: none;
  outline: none;
  width: 80%;
  max-width: 300px;
}

.valueclosediv {
  width: 100%;
}

.valuepropcontainer {
  background-color: #ececec;
  padding-top: 0%;
  padding-bottom: 120px;
  box-sizing: border-box;
  width: 100vw;
  max-width: 480px;
  height: 100%;
  position: relative;
  overflow: scroll;
}

.valueclosebuttoncontainer {
  width: 100%;
  height: 50px;
  margin-bottom: 0px;
  /* position: fixed; */
  /* display: flex;
  justify-content: center;  */
  /* bottom: 0px; */

}

.closevaluepropanytimecont {
  position: absolute;
  top: 5px;
  right: 3px;
  color: #3a3a3a;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 1.5rem;
}


.valuepropheader1 {
  width: 100%;
  padding: 5%;
  text-align: center;
  box-sizing: border-box;
  font-size: 1.3rem;
  color: rgb(27, 27, 27);
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  ;
}

.valuepropheader2 {
  box-sizing: border-box;
  width: 100%;
  padding: 2% 5% 2% 5%;
  font-size: 1rem;
  color: rgb(27, 27, 27);
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
  ;
}

.valuepropheader3 {
  box-sizing: border-box;
  width: 100%;
  padding: 2% 2% 2% 2%;
  margin-top: 20px;
  font-size: 0.8rem;
  color: rgb(27, 27, 27);
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
  ;
}

.valuepropheader4 {
  box-sizing: border-box;
  width: 100%;
  padding: 2% 5% 2% 5%;
  font-size: 1rem;
  color: rgb(27, 27, 27);
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
  ;
}

.tutorialimage {
  width: 100%;
  height: fit-content;
  border-radius: 5px;
}

.valuepropheader5 {
  width: 100%;
  padding: 5% 2% 2% 8%;
  text-align: left;
  box-sizing: border-box;
  font-size: 1.3rem;
  color: rgb(27, 27, 27);
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  ;
}

.tutorialtext1 {
  box-sizing: border-box;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0%;
  font-size: 0.8rem;
  color: rgb(27, 27, 27);
  font-weight: 600;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  ;
}

.tutorialtext2 {
  box-sizing: border-box;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0%;
  font-size: 0.8rem;
  color: rgb(27, 27, 27);
  font-weight: 600;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  ;
}

.tutorialheader {
  width: 100%;
  margin: 0px;
  padding: 0%;
  text-align: center;
  box-sizing: border-box;
  font-size: 1.2rem;
  color: rgb(27, 27, 27);
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  ;
}

.creditcards {
  margin-right: 5%;
  width: 220px;
}

.creditcardscontainer {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.expensescontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;

}

.labelcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.discretionarylabelcontainer {
  text-align: center;
  width: 180px;
  padding-bottom: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  font-size: 0.8rem;
  color: rgb(27, 27, 27);
  font-weight: 900;
  font-family: "Quicksand", sans-serif;
  ;
}

.fixedlabelcontainer {
  text-align: center;
  width: 180px;
  margin-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  font-size: 0.8rem;
  color: rgb(70, 70, 70);
  font-weight: 900;
  font-family: "Quicksand", sans-serif;
  ;
}

.discretionaryexpensescontainer {
  border-radius: 5px;
  background-color: rgba(216, 216, 216, 0);
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 255px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.fixedexpensescontainer {
  border-radius: 5px;
  background-color: rgba(216, 216, 216, 0);
  width: 100%;
  height: 255px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

}

.discretionary {
  width: 80%;

}

.fixedexpenses {
  width: 80%;

}

.expensetypes {

  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.scroll-container {
  height: 100vh;
  width: 100%;
  overflow: auto;
  scroll-snap-type: y mandatory;
  position: relative;
  background: linear-gradient(to right, rgb(68, 124, 72), rgb(6, 59, 53));
  display: flex;
  justify-content: center;
}

.value-card-container {
  position: absolute;
}

.scroll-section {
  height: 100vh;
  width: 100%;
  max-width: 500px;
  /* position: relative; */
  scroll-snap-align: start;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2em;
  color: white;
  text-align: center;
  padding: 50px;
  box-sizing: border-box;
  border: none;
}

.section4 {
  background: linear-gradient(to right, #e3f1e9, #eff4e9);
  color: rgb(241, 241, 241);
}

.section2 {
  background: linear-gradient(to right, #e6cccc, #ffffff);
}

.section3 {
  background: linear-gradient(to right, #d5d3ec, #f0cdcd);
}

.section5 {
  background: linear-gradient(to right, #f6dfeb, #e6f7fd);
  color: rgb(27, 27, 27);
}

.section6 {
  background: linear-gradient(to right, #f8e2b7, #f8f1df);
}

.section1 {
  background: transparent;
}

.fadeindivs {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  animation: fade-in 0.5s linear forwards;
  background-color: #FFFFFF;
  padding: 5%;
  border-radius: 20px;
  border: none;
  filter: drop-shadow(0 0 0.75rem rgb(19, 19, 19));
  position: relative;
}

.valuepropheader6 {
  color: #274b5a;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}

.trackspanner {
  width: 20px;
  background-color: #ffbd2b;
  height: 100%;
  position: absolute;
  left: 0;
}

.coinstack {
  width: 30px;
}

.trackhandle {
  width: 100px;
  background-color: #ffbd2b;
  height: 20px;
  position: absolute;
  left: 0;
}

.valuepropemoji {
  font-size: 8rem;
  padding: 0px;
  margin: 0px;
}

.valuepropemoji4 {
  font-size: 6rem;
  padding: 0px;
  margin: 0px;
}

.valuepropmore {
  font-size: 2rem;
}

.featurecontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  flex-wrap: nowrap;
  width: auto;
  height: 40px;
  border-radius: 10px;
  background-color: white;
  padding: 0px;
  box-sizing: border-box;
  bottom: 10%;
}

.featurecheckmark {
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.featuretitle {
  font-size: 0.9rem;
  font-weight: 700;
  color: rgb(6, 59, 53);
  padding: 5px 8px 5px 5px;
}

.navigationtutorial {
  width: 100%;
  height: 100px;
  background-color: transparent;
  position: absolute;
  top: 4px;
  left: 0;
}


.component-indicator-container-tutorial {
  display: flex;
  height: 20px;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  border: 1px solid rgb(114, 114, 114);
  border-top: none;
  width: 195px;
  line-height: 15px;
  margin-left: 5px;
}

.component-indicator-container-tutorial-line {
  display: flex;
  height: 20px;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  border: none;
  border-top: none;
  width: 200px;
  margin-left: 40px;
  color: rgb(19, 19, 19);
  border-left: 1px solid rgb(114, 114, 114);
}

.component-indicator3-tutorial {
  width: 10px;
  height: 85px;
  position: absolute;
  right: 21px;
  top: 0px;
  box-sizing: border-box;
  margin-left: 5px;
  border-right: 1px solid rgb(114, 114, 114);
  border-bottom: 1px solid rgb(114, 114, 114);
}

.component-indicator5-tutorial {
  width: 10px;
  height: 65px;
  position: absolute;
  right: 66px;
  top: 0px;
  box-sizing: border-box;
  margin-left: 5px;
  border-right: 1px solid rgb(114, 114, 114);
  border-bottom: 1px solid rgb(114, 114, 114);
}

.component-indicator7-tutorial {
  width: 10px;
  height: 45px;
  position: absolute;
  right: 116px;
  top: 0px;
  box-sizing: border-box;
  margin-left: 5px;
  border-right: 1px solid rgb(114, 114, 114);
  border-bottom: 1px solid rgb(114, 114, 114);
}

.component-indicator-tutorial-text1 {
  position: absolute;
  left: 9px;
  top: 40px;
  font-weight: 600;
  color: rgb(63, 63, 63);
  font-size: 0.7rem;
}

.component-indicator-tutorial-text2 {
  position: absolute;
  right: 130px;
  top: 37px;
  font-weight: 600;
  color: rgb(63, 63, 63);
  font-size: 0.7rem;
}

.component-indicator-tutorial-text3 {
  position: absolute;
  right: 79px;
  top: 56px;
  font-weight: 500;
  color: rgb(63, 63, 63);
  font-size: 0.7rem;
}

.component-indicator-tutorial-text4 {
  position: absolute;
  right: 34px;
  top: 77px;
  font-weight: 500;
  color: rgb(63, 63, 63);
  font-size: 0.7rem;
}

.component-indicator-tutorial-dismiss {
  position: absolute;
  right: 15px;
  top: 95px;
  font-weight: 500;
  color: #274b5a;
  font-size: 0.7rem;
  border: none;
  outline: none;
  background-color: transparent;
  text-decoration: underline;
  font-family: "Quicksand", sans-serif;
}


@media screen and (max-height: 800px) {

  .valuepropemoji {
    font-size: 6rem;

  }

  .valuepropheader4 {
    padding: 0px;
    font-size: 0.9rem;

  }

  .valuepropheader6 {
    padding: 0px;
    font-size: 0.9rem;
    margin: 5px;
  }
}

@media screen and (max-height: 680px) {

  .valuepropemoji {
    font-size: 3rem;

  }

  .valuepropemoji4 {
    font-size: 3rem;

  }

  .valuepropheader3 {
    padding: 0px;
    font-size: 0.9rem;

  }
}

@media screen and (max-height: 600px) {

  .valuepropemoji4 {
    font-size: 2.5rem;

  }

  .fadeindivs {
    width: 98%;
    height: 98%;
  }
}

@media screen and (max-height: 560px) {

  .valuepropemoji {
    font-size: 5rem;

  }

  .scroll-section {
    padding: 20px;
  }

  .fadeindivs {
    width: 99%;
    height: 90%;
  }
}

@media screen and (max-height: 500px) {

  .valuepropemoji {
    font-size: 3rem;

  }

  .valuepropheader3 {
    padding: 0px;
    font-size: 0.9rem;
    margin: 0px;
  }
}

@media screen and (max-width: 360px) {


  .component-indicator5-tutorial {

    right: 62px;

  }

  .component-indicator7-tutorial {

    right: 106px;

  }


  .component-indicator-tutorial-text2 {

    right: 121px;

  }

  .component-indicator-tutorial-text3 {

    right: 77px;

  }


}

@media screen and (max-width: 300px) {


  .component-indicator-container-tutorial-line {

    height: 40px;
  }



  .component-indicator-tutorial-text1 {

    top: 60px;

  }



}