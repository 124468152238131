.archivefoldercontainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.archivefolderimage {
    width: 100px;
    opacity: 80%;
}

.emptychartstatearchive {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 0.7rem;
    color: rgb(19, 19, 19);
    font-weight: 500;
    animation: fade-in 0.6s linear forwards;
}

.statistics-container {
    width: 100%;
    color: rgb(19, 19, 19);
    height: auto;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    justify-content: space-evenly;
    align-items: left;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(184, 184, 184);
}

.archive-stat-container {
    width: 100%;
    text-align: left;
    padding: 5px;
    font-size: 0.9rem;
    font-weight: 600;
    color: rgb(29, 29, 29);
    background-color: transparent;
    /* margin: 10px; */
    box-sizing: border-box;
    border-radius: 5px;


}

.archive-header {
    width: 100%;
    padding: 0px 0px 0px 10px;
    box-sizing: border-box;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: left;
    color: #424242;
    font-family: 'Inter Tight', sans-serif;
}

.archive-dropdown-header {
    width: 100%;
    padding: 20px 0px 10px 0px;
    box-sizing: border-box;
    font-size: 0.8rem;
    font-weight: 700;
    text-align: center;
    color: rgb(29, 56, 53);

}


.close-archive-nav {
    text-decoration: none;
    border-radius: 5px;
    width: 180px;
    height: 46px;
    font-size: 0.8rem;
    font-weight: 600;
    text-align: center;
    background-color: rgb(29, 56, 53);
    color: white;
    padding: 5px;
    margin: 10px 5px 10px 5px;
    outline: none;
    border: none;
    font-family: "Quicksand", sans-serif;
}

.close-archive-container {
    margin-top: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 40px; */
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 10px;

}

.archive-date-button {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 20px;
    background-color: transparent;
    outline: none;
    border: 1px solid rgb(29, 56, 53);
    color: rgb(29, 56, 53);
    border-radius: 5px;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    height: 25px;
    padding: 0px;
}

.archivebuttoncontainer {
    width: 100%;
    display: flex;
    justify-content: right;
}