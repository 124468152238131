.user-profile {
    background-color: transparent;
    padding: 25px 8px 25px 8px;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 25px;
    width: 95%;
    height: 100vh;
    margin: 2.5%;
    overflow: scroll;
    box-sizing: border-box;
    animation: fade-in 1s linear forwards;

}

.showorhideaccount {
    text-decoration: none;
    color: rgb(29, 56, 53);
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: 500;
    font-family: "Quicksand", sans-serif;
    text-decoration: underline;
    font-size: 0.7rem;
    margin-top: 25px;
    width: 100%;
    text-align: left;
}

.user-profile__title {
    font-size: 1rem;
    color: #2c2c2c;
    margin-bottom: 25px;
    text-align: center;
}

.user-profile__subtitle {
    color: #2c2c2c;
    font-size: 0.9rem;
    padding: 15px 0px 10px 0px;
    margin: 15px 0px 5px 0px;
    text-align: left;
}

.user-profile__section {
    width: 100%;
}

.user-profile__input {
    width: 50%;
    padding-left: 5px;
    border: 1px solid #c2c2c2;
    outline: none;
    border-radius: 5px;
    font-weight: 500;
    height: 25px;
    font-family: "Quicksand", sans-serif;
    ;
    margin: 0px 0px 5px 0px;
}

.user-profile__inputdropdown {
    width: auto;
    font-size: 1.2rem;
    background-color: transparent;
    padding: 0px 2px 0px 2px;
    border: 0px solid #c2c2c2;
    outline: none;
    border-radius: 5px;
    font-weight: 600;
    height: 25px;
    font-family: "Quicksand", sans-serif;
    color: rgb(61, 61, 61);
    margin: 0px 0px 5px 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}


.user-profile__input5 {
    width: 25%;
    padding-left: 5px;
    border: 1px solid #c2c2c2;
    outline: none;
    border-radius: 5px;
    font-weight: 500;
    height: 25px;
    font-family: "Quicksand", sans-serif;
    ;
    margin: 0px 0px 5px 0px;
}

.npprofilepicture {
    width: 200px;
    height: 200px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;

    border-radius: 50%;
}

.profileanimal {
    width: 150px;

}

.user-profile__input6 {
    width: 100%;
    padding: 0px;

    border: 1px solid #c2c2c2;
    outline: none;
    border-radius: 5px;
    font-weight: 500;
    text-align: center;
    height: 25px;
    font-family: "Quicksand", sans-serif;
    ;
    margin: 20px 0px 20px 0px;
}

.namecontainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.profilebigbox {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.user-profile__inputbox {

    width: 10px;
    height: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
    font-weight: 500;
    height: 25px;
    font-family: "Quicksand", sans-serif;
    ;
    margin: 5px 5px 5px 5px;
}

.user-profile__left-aligned-container {
    width: 100%;
    display: flex;
    margin-top: 30px;
    border-top: 1px solid grey;
    flex-direction: column;
}

.user-profile__input-group1 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

}

.user-profile__input-group2 {
    display: flex;
    flex-direction: column;

}

.user-profile__input-group3 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 20px;
}


.user-profile__label {

    font-size: 0.8rem;
    color: #2c2c2c;
    font-weight: 500;

}

.user-profile__label2 {
    font-size: 0.8rem;
    color: #920000;
    font-weight: 500;
    margin-bottom: 5px;
}

.user-profile__label3 {
    padding-left: 5px;
    /* padding-bottom: 10px; */
    font-size: 0.8rem;
    color: #161616;

}


.user-profile__label4 {
    padding-left: 5px;
    padding-bottom: 10px;
    font-size: 0.8rem;
    line-height: 1.6rem;
    color: #161616;

}



.user-profile__button {
    background-color: #ffffff;
    width: 130px;
    color: rgb(29, 56, 53);
    border: 1px solid rgb(29, 56, 53);
    outline: none;
    border-radius: 5px;
    font-weight: 500;
    margin: 5px 0px 0px 0px;
    height: 25px;
    font-family: "Quicksand", sans-serif;
    ;
}

.user-profile__button2 {

    width: 180px;
    color: rgb(29, 56, 53);
    font-size: 0.8rem;
    outline: none;
    border-radius: 5px;
    font-weight: 500;
    margin: 5px 0px 0px 0px;
    height: 25px;
    font-family: "Quicksand", sans-serif;
    ;
}

.user-profile__button20 {

    width: 180px;
    color: rgb(29, 56, 53);
    font-size: 0.8rem;
    outline: none;
    border-radius: 5px;
    font-weight: 500;
    margin: 35px 0px 0px 0px;
    height: 25px;
    font-family: "Quicksand", sans-serif;
    ;
}

.close-profile-nav {
    text-decoration: none;
    color: white;
    font-weight: 600;
    line-height: 1.4rem;

}

.close-profile-nav-container {
    text-decoration: none;
    border-radius: 5px;
    height: 46px;
    background-color: rgb(29, 56, 53);
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 180px;
}

.user-profile__button-container {
    width: calc(100vw-20px);
    text-align: center;
    display: flex;
    justify-content: center;
}


.user-profile__password-status {
    padding-top: 10px;
    height: 25px;
    color: #2c2c2c;
    font-size: 0.8rem;
    font-weight: 600;
}

.user-profile__delete-status {
    padding-top: 10px;
    height: 25px;
    color: #920000;
    font-size: 0.8rem;
    font-weight: 600;
}

@media screen and (min-width: 1200px) {

    .user-profile {
        padding: 25px 8px 25px 8px;
        width: 45%;
        margin: 0% 27.5% 0% 27.5%;
    }

}