.widgetcomponent {
  /* width: 100%; */
  height: 40px;
  box-sizing: border-box;
  position: relative;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  /* justify-content: center; */
  font-family: "Quicksand", sans-serif;
  ;
  align-items: center;
  border-radius: 5px 5px 5px 5px;
  border: none;
  /* margin-top: 5%; */
}


.leftoverwidgetchartdiv {
  height: 10px;
  width: 100%;
  /* background-color: rgb(14, 14, 14); */

  position: relative;
  padding: 0px;
  margin: 0px;


}



.leftoverstatement {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  position: absolute;
  bottom: 2px;
  font-size: 0.9rem;
  line-height: 0.7rem;

}




.negative {
  color: rgb(165, 56, 56);
}

.positive {
  color: rgb(56, 56, 56);
}



.widgetelementcontainer {

  /* width: 100%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
}

.leftoveramountremaining {
  padding: 5px;
  font-size: 2.2rem;
  text-align: left;
  font-weight: 700;
  color: rgb(100, 184, 100);
  font-family: 'Baloo 2', cursive;
}

.leftoveramounttext {
  padding: 5px;
  font-family: "Quicksand", sans-serif;
  ;
  font-weight: 600;
  color: #616161;
  line-height: 1.4rem;
}

.remainingbudgetchart {

  width: 42%;
  box-sizing: border-box;
  padding: 5px;
  position: absolute;
  height: 27px;
  bottom: 0;
  right: 0;
}

.thermocanvas {
  position: absolute;
  top: 0;
  right: 0px;
  z-index: 200;
  /* background-color: #F0F0F0; */
  border: none;
  height: auto;
  border-radius: 25px;
  box-sizing: border-box;
  padding: 0px;
  background-color: rgba(189, 189, 189, 0.74);
  /* transform: rotate(180deg) */
}