@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');

.citybacklogin {
    width: 1200px;
    position: absolute;
    z-index: 10;
}

.loginglasswindow {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(#ccd7df91, #fafafa8e); */
    z-index: 10;
    border-radius: 10px;
}

.roundedlogo {
    width: 40%;
    max-width: 75px;
    position: absolute;
    top: -60px;
    /* -webkit-animation: scale-in-ver-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

.roundedlogosmaller {
    width: 20%;
    max-width: 45px;
    position: absolute;
    top: -40px;
    /* -webkit-animation: scale-in-ver-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

.fadeawaydiv {
    -webkit-animation: fade-out 1s ease-out both;
    animation: fade-out 1s ease-out both;
}

@-webkit-keyframes scale-in-ver-bottom {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }
}

@keyframes scale-in-ver-bottom {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }
}

@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}




#container {
    position: relative;
    height: 100%;
    width: 160vw;
    padding: 0;
    margin: 0;
    left: -20px;
    overflow: hidden;
    /* background-color: rgba(238, 238, 238, 0.589); */
    /* filter: blur(2px); */
    animation: fade-in 1.5s linear forwards;
}

.circle {
    position: absolute;
    border-radius: 50%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

#circle1 {
    top: 10%;
    left: 10%;
    height: 80px;
    width: 80px;
    background-color: #ff3300;
    animation-name: move1;
    animation-duration: 20s;
    animation-delay: 2s;
}

#circle2 {
    top: 30%;
    left: 60%;
    height: 120px;
    width: 120px;
    background-color: #d0ff00;
    animation-name: move2;
    animation-duration: 18s;
    animation-delay: 4s;
}

#circle3 {
    top: 70%;
    left: 30%;
    height: 50px;
    width: 50px;
    background-color: #8c00ff;
    animation-name: move3;
    animation-duration: 22s;
    animation-delay: 6s;
}

#circle4 {
    top: 60%;
    left: 10%;
    height: 100px;
    width: 100px;
    background-color: #a3053a;
    animation-name: move4;
    animation-duration: 24s;
    animation-delay: 8s;
}

#circle5 {
    top: 20%;
    left: 80%;
    height: 90px;
    width: 90px;
    background-color: #004b7e;
    animation-name: move5;
    animation-duration: 21s;
    animation-delay: 10s;
}

.rain {
    width: 2px;
    height: 10px;
    background: #69c3e0a6;
    position: absolute;
    border-radius: 10px 10px 10px 10px;
    bottom: 100%;
    animation: fall linear infinite;
    animation-duration: 0.6s;
}

.rain:before,
.rain:after {
    content: '';
    position: absolute;
    bottom: 100px;
    width: 2px;
    height: 16px;
    background: #add8e68f;
}

.rain:after {
    width: 1px;
    height: 10px;
    border-radius: 50%;
    bottom: 90px;
    box-shadow: 0 0 10px #add8e683;
}



@keyframes fall {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    100% {
        transform: translateY(900px);
        opacity: 0;
    }
}

.ripple {
    position: absolute;
    bottom: 0;
    /* width: 0;
    height: 0; */
    background: rgba(255, 255, 255, 0.349);
    border-radius: 50%;
    animation: ripple 2s ease-out infinite;
    transform-origin: center center;
}


@keyframes ripple {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

/* Use this to generate random rain drops */
@for $i from 1 through 100 {

    .rain:nth-child(#{$i}),
    .ripple:nth-child(#{$i}) {
        left: random(500) * 1px;
        animation-duration: 0.5s + random(2000) * 1ms;
        animation-delay: random(2000) * 1ms;
    }
}

@keyframes move1 {
    0% {
        transform: translate(0, 0);
    }

    25% {
        transform: translate(300px, 200px);
    }

    50% {
        transform: translate(400px, 0);
    }

    75% {
        transform: translate(200px, -200px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes move2 {
    0% {
        transform: translate(0, 0);
    }

    25% {
        transform: translate(-200px, 300px);
    }

    50% {
        transform: translate(-300px, -200px);
    }

    75% {
        transform: translate(-100px, 100px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes move3 {
    0% {
        transform: translate(0, 0);
    }

    25% {
        transform: translate(300px, -200px);
    }

    50% {
        transform: translate(100px, -100px);
    }

    75% {
        transform: translate(-200px, 300px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes move4 {
    0% {
        transform: translate(0, 0);
    }

    25% {
        transform: translate(-250px, -200px);
    }

    50% {
        transform: translate(200px, 200px);
    }

    75% {
        transform: translate(100px, -100px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes move5 {
    0% {
        transform: translate(0, 0);
    }

    25% {
        transform: translate(-300px, 250px);
    }

    50% {
        transform: translate(200px, -200px);
    }

    75% {
        transform: translate(-100px, 300px);
    }

    100% {
        transform: translate(0, 0);
    }
}

.splash {
    position: absolute;
    width: 3px;
    height: 3px;
    background: rgba(255, 255, 255, 0.767);
    border-radius: 50%;
    animation: splash 0.5s ease-out forwards;
}

@keyframes splash {
    0% {
        bottom: 115px;
    }

    50% {
        bottom: 125px;
    }

    100% {
        bottom: 115px;
        opacity: 0;
    }
}

.wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 110px;
    /* adjust this for wave height */
    background: rgba(35, 121, 150, 0.2);
    /* adjust this for wave color */
    animation: wave 7s ease-out infinite;
    clip-path: url(#wavePath);
}

.wave0 {
    background-color: #79766bf8;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    animation: wave1 7s ease-out infinite;
    clip-path: url(#wavePath);
}

.wave1 {
    animation-delay: 0s;
}

.wave2 {
    animation-delay: 1s;
}

.wave3 {
    animation-delay: 2s;
}




@keyframes wave {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(30px);
        /* adjust this for wave amplitude */
    }
}

@keyframes wave1 {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
        /* adjust this for wave amplitude */
    }
}


@keyframes dayNightCycle {
    0% {
        background: #a0c1b8;
    }


    25% {
        background: #6d98ba;
    }


    50% {
        background: #ed6a5a;
    }


    75% {
        background: #011936;
    }

    100% {
        background: #a0c1b8;
    }


}

#sky {
    position: fixed;
    top: 0;
    left: -135%;
    width: 400%;
    height: 100%;
    background: radial-gradient(at center bottom, rgb(216, 202, 79), rgb(83, 160, 204), rgb(141, 164, 165), hsla(264, 13%, 8%, 0.856));
    z-index: -1;
}

.suncontainer {
    width: 100vw;
    height: 100vh;
    left: 20px;
    bottom: 0;
    display: flex;
    position: absolute;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

#sun {
    position: absolute;
    bottom: -0px;
    left: 50%;
    width: 160px;
    height: 160px;
    background: radial-gradient(circle, rgb(231, 162, 59), rgb(223, 211, 47));
    border-radius: 50%;
    transform: translateX(-50%);
    filter: blur(10px);
}



/* #stars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s;
    z-index: 9999;
    background-color: #004b7e;
}

.star {
    position: absolute;
    background: rgb(255, 0, 0);
    border-radius: 50%;
    animation: twinkle 1s infinite alternate;
    opacity: 1 !important;
    z-index: 9999;
} */

@keyframes twinkle {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

.birds-container {
    position: absolute;
    width: 100vw;
    height: 80vh;
    /* Adjust height as needed */
    perspective: 1000px;
    /* Adjust perspective value for depth effect */
    display: flex;
    justify-content: center;
    align-items: center;
}

.bird {
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 50px;
    height: 50px;
    top: 60%;
    left: -50px;
    /* Adjust initial position to be outside the frame */
    animation: move-bird 38s linear infinite;
    /* Adjust duration as needed */
    animation-delay: calc(var(--delay) * 0.5s);
    /* Adjust delay for each bird */
}

.bird2 {
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 50px;
    /* Adjust size as needed */
    height: 50px;
    /* Adjust size as needed */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Adjust initial position to be outside the frame */
    animation: move-bird 42s linear 1s infinite;
    /* Adjust duration as needed */
    animation-delay: calc(var(--delay) * 1s);
    /* Adjust delay for each bird */
}

.bird3 {
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 50px;
    /* Adjust size as needed */
    height: 50px;
    /* Adjust size as needed */
    top: 20%;
    left: 40%;
    transform: translate(-50%, -50%);
    /* Adjust initial position to be outside the frame */
    animation: move-bird 46s linear 4s infinite;
    /* Adjust duration as needed */
    animation-delay: calc(var(--delay) * 0.0s);
    /* Adjust delay for each bird */
}

.birdbody {
    border-radius: 50%;
    width: 8px;
    height: 5px;
    background-color: #333;
    position: absolute;
    left: -4px;
    top: -3px;
    animation: bounce-body 1s infinite linear, color-transition 20s infinite linear;
}

.wing {
    position: absolute;

    width: 30%;
    height: 3px;
    border-radius: 75% 75% 75% 75%;

    /* Adjust size as needed */
    background-color: #5c5c5c;
    /* Adjust color as needed */
    transform-origin: center bottom;
    animation: flap-wings 1s infinite linear, color-transition 20s infinite linear;
    animation-delay: calc(var(--delay) * -1s);
    /* Adjust delay for each bird */
}

.wing2 {
    position: absolute;
    left: -28%;
    width: 30%;
    height: 3px;
    border-radius: 75% 75% 75% 75%;
    /* Adjust size as needed */
    background-color: #5c5c5c;
    /* Adjust color as needed */
    transform-origin: center bottom;
    animation: flap-wings2 1s infinite linear, color-transition 20s infinite linear;
    animation-delay: calc(var(--delay) * -1s);
    /* Adjust delay for each bird */
}

@keyframes bounce-body {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
        /* Adjust bounce distance as needed */
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes flap-wings {
    0% {
        transform: rotateZ(0deg);
    }

    50% {
        transform: rotateZ(-60deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
}

@keyframes flap-wings2 {
    0% {
        transform: rotateZ(0deg);
    }

    50% {
        transform: rotateZ(60deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
}

@keyframes move-bird {
    0% {
        left: -50px;
        transform: scaleY(-1);
        /* Flip the bird horizontally */
    }

    100% {
        left: 300vw;
        /* Move the bird outside the frame on the right side */
    }
}

@keyframes color-transition {

    0%,
    100% {
        background-color: rgb(83, 83, 83);
    }

    50% {
        background-color: rgb(70, 34, 21);
    }
}

.quote-display {
    display: flex;
    position: absolute;
    box-sizing: border-box;
    padding: 40px;
    width: 100vw;
    height: 20vh;
    color: rgba(82, 82, 82, 0.726);
    font-size: 1.2rem;
    justify-content: center;
    align-items: center;
    top: 50%;
    z-index: 6000;
    font-style: italic;
    text-align: center;
    font-family: 'Reenie Beanie', cursive;
    -webkit-animation: text-shadow-drop-center 0.6s both;
    animation: text-shadow-drop-center 0.6s both;
}

.quote-display-enter {
    opacity: 0;
}

.quote-display-enter-active {
    opacity: 1;
    transition: opacity 2000ms;
}

.quote-display-exit {
    opacity: 1;
}

.quote-display-exit-active {
    opacity: 0;
    transition: opacity 2000ms;
}

.sailboat {
    width: 20px;
    position: absolute;
    bottom: 110px;
    animation: sail 20s linear 1;
    animation-delay: 20s;
    animation-iteration-count: infinite;
    animation-duration: 240s;
}

@keyframes sail {
    from {
        left: 200vw;
    }

    to {
        left: -300vw;
    }
}

@-webkit-keyframes text-shadow-drop-center {
    0% {
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        text-shadow: 0 0 18px rgba(0, 0, 0, 0.35);
    }
}

@keyframes text-shadow-drop-center {
    0% {
        text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        text-shadow: 0 0 18px rgba(0, 0, 0, 0.35);
    }
}

@-webkit-keyframes flip-in-hor-top {
    0% {
        -webkit-transform: rotateX(-80deg);
        transform: rotateX(-80deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
}

@keyframes flip-in-hor-top {
    0% {
        -webkit-transform: rotateX(-80deg);
        transform: rotateX(-80deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
}

@media screen and (max-height: 620px) {


    .roundedlogosmaller {
        top: -40px;
    }

}

@media screen and (max-height: 600px) {


    .roundedlogo {
        width: 30%;
        max-width: 55px;
        top: -40px;
    }

}