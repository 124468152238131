/* @-webkit-keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
}

@keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
}

@-webkit-keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
} */



.savingsgoalscontainer {
    height: auto;
    font-size: 0.8rem;
    color: black;
}

.goalslist {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    width: 100%;
}

.goalnameandpercentagecontainer {
    display: flex;
    flex-direction: column;

    justify-content: space-evenly;
    padding: 15px 0px 15px 0px;
    flex-grow: 1;
}

.modifypercentgoalcontainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.chartandstatuscontainer {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5px;
}

.confirmremovegoalheader {
    font-size: 0.8rem;
    font-weight: 600;
    color: rgb(110, 27, 27);
}

.confirmremovegoalname {
    font-size: 0.8rem;
    font-weight: 600;
    color: rgb(6, 59, 53);

}

.confirmremovegoaltextcontainer {
    display: flex;
    align-items: left;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 10px;
}

.confirmremovegoalbuttoncontainer {
    display: flex;
    align-items: left;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 10px;
}

.confirmremovegoal {
    outline: none;
    border: none;
    font-family: "Quicksand", sans-serif;
    background-color: rgb(110, 27, 27);
    color: white;
    border-radius: 10px 0px 0px 10px;
    font-weight: 500;
}

.cancelremovegoal {
    outline: none;
    border: none;
    font-family: "Quicksand", sans-serif;
    background-color: transparent;
    color: rgb(10, 62, 110);
    text-align: right;
    border-radius: 5px;
    font-weight: 500;
    text-decoration: underline;
}

.goaliconcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    padding: 2px 5px 2px 5px;
    min-width: 40px;
    max-width: 40px;
}

.savingsgoalamounts {
    width: 100%;
    text-align: left;
    font-weight: 400;
    font-size: 0.75rem;
    padding-right: 5px;
    box-sizing: border-box;
    color: rgb(20, 20, 20);
    margin: 5px 0px 0px 10px;
}

.amountallocatedcontainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.goalname {
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 700;
    color: rgb(6, 59, 53);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
}

.usingthispercent {
    font-weight: 700;
}

.goalname2 {
    font-size: 0.9rem;
    font-weight: 700;
    color: rgb(6, 59, 53);
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.goalname3 {
    font-size: 0.9rem;
    font-weight: 700;
    color: rgb(31, 31, 31);
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.group-input3 {
    /* display: block; */
    /* margin-bottom: 20px; */
    box-sizing: border-box;
    padding: 5px;
    margin: 10px 0px 10px 0px;
    width: 60%;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    outline: none;
    border-radius: 5px;
    border: none;
    text-align: center;
    height: 40px;
}

.inputandiconcontainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.savingsicon {
    background-color: rgba(245, 245, 245, 0.11);
    width: 60px;
    border-radius: 50%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    margin-bottom: 20px;
    box-sizing: border-box;
}


.over-target {
    background-color: #ffbd2b;
    border-radius: 25px;
    color: rgb(17, 17, 17);
    padding: 2px 5px 2px 2px;
    box-sizing: border-box;
    height: 15px;
    line-height: 0.7rem;
    /* Additional styling for the div when the percentage is over 100% */
}

.goaldetails {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: left;
    width: 100%;
}

.goaldetailsslider {
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.goalpercentage {
    font-size: 0.8rem;
}


.goalchart {
    width: 100%;
    display: flex;
    justify-content: right;

}

/* .goaltargetlabel {
    font-size: 2rem;
    ;
} */

.removegoal {
    border: none;
    outline: none;
    background-color: rgb(110, 27, 27);
    border-radius: 0px 10px 10px 0px;
    color: rgb(238, 238, 238);
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
}

.achievedgoal {
    border: none;
    outline: none;
    background: linear-gradient(to bottom right, #ffbd2b, #ffd862, #fff2b4);
    border-radius: 10px;
    color: rgb(6, 59, 53);
    font-weight: 700;
    font-size: 1.4rem;
    font-family: "Quicksand", sans-serif;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.animateremoval {
    -webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.goalachievedtext {
    align-items: center;
    text-align: center;

}

.achievedgoallineitem {
    font-size: 0.8rem;
    padding: 0px;
    margin: 5px;
    text-align: center;
    font-weight: 600;
    color: #222222;
}

.savingsachievedbackground {
    position: absolute;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

.savingsachievedmodal {
    width: 80%;
    max-width: 480px;
    background: linear-gradient(to top left, #d8c37d, #d3bb37);
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    filter: drop-shadow(0 0 0.75rem rgb(43, 43, 43));
    -webkit-animation: slide-in-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

}

.sliderconfig {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.goalprogress-container {
    min-width: 100px;
    max-width: 120px;
    height: 15px;
}

.goalprogress-canvas {
    width: 120px;
    margin: 0px;
    border-radius: 25px;
    padding: 0px 2px 0px 2px;
    background-color: rgb(165, 165, 165);
    box-sizing: border-box;


}

.forecastedtotal {
    font-size: 0.7rem;
    font-weight: 500;
    color: rgba(105, 105, 105, 0.966);
    ;
}


.sliderandtext {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.actualtotal {
    text-align: right;
    font-weight: 600;
    font-family: "Baloo 2", sans-serif;
}

.editgoalsbutton {
    background: transparent;
    /* position: absolute; */
    margin-left: 0px;
    margin-right: 5px;
    margin-top: 5px;
    box-sizing: border-box;
    color: rgb(10, 62, 110);
    text-decoration: underline;
    font-family: "Quicksand", sans-serif;
    outline: none;
    border: none;
    /* float: left; */
    font-size: 0.8rem;
    width: 100%;
    text-align: right;
}

.doneeditinggoalsbutton {
    background-color: #ffbd2b;
    height: 45px;
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 60px;
    box-sizing: border-box;
    font-weight: 600;
    color: #171717;
    font-family: "Quicksand", sans-serif;
    outline: none;
    border: none;
    /* float: left; */
    font-size: 0.8rem;
    width: 80%;
    text-align: center;
}

.goal-info {
    display: flex;
    min-height: 65px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 76px;
    max-width: 100%;
    border-radius: 10px;
    border: 0px solid rgb(212, 212, 212);
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    background-color: rgb(255, 255, 255);
    margin: 10px 0px 0px 0px;
    -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.percentslidercontainer {
    width: auto;
    padding: 0px 5px 0px 5px;
    flex-grow: 1;

}

.percentageslider {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 25px;
    padding: 0px;
    margin-top: 8px;
    background-color: rgb(6, 59, 53);
}

.savingchartcontainer {
    width: 100%;
    height: 10px;
    padding: 0px 0px 5px 0px;
    margin: 0px;
}

.allocatedcanvas {
    width: 100%;
    padding: 0px 1px 0px 1px;
    margin: 0;
    background-color: #d8d8d8;
    border-radius: 5px;
    border: 1px solid #d8d8d8;

}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    margin-top: 0px;
    border-radius: 25px;
    /* Centers thumb on the track */
    background-color: #ffbd2b;
    height: 1rem;
    width: 1rem;
}

.buttonbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    background-color: rgb(6, 59, 53);
    color: rgb(212, 212, 212);
}

.buttonbutton2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.8rem;
    text-decoration: underline;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    background-color: transparent;
    color: rgb(6, 59, 53);
}

.emptystategoalsbutton {
    width: 100%;
    text-align: center;
}

.restartgoalsbutton {
    width: 100%;

    text-align: center;
}



.currentpercentage {
    position: absolute;
    left: 2px;
    top: 0px;
    font-size: 0.6rem;
    font-family: "Baloo 2", sans-serif;

}

.editgoalsbutton5 {
    background-color: transparent;
    border-radius: 5px;
    outline: none;
    max-width: 250px;
    border: none;
    margin-top: 40px;
    padding: 10px;
    font-size: 0.8rem;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    color: rgb(6, 59, 53);
    text-decoration: underline;
}

@media screen and (max-width: 380px) {
    .goaliconcontainer {
        width: 60px;
        font-size: 1.5rem;
        padding: 0px;
    }

    .goalprogress-container {
        min-width: 100px;
        max-width: 100px;
        height: 15px;
    }
}

@media screen and (max-width: 360px) {
    .goaliconcontainer {
        width: 60px;
        font-size: 1.5rem;
        padding: 0px;
    }

    .goalprogress-container {
        min-width: 100px;
        max-width: 100px;
        height: 15px;
    }
}

@media screen and (max-width: 340px) {
    .goaliconcontainer {
        width: 60px;
        font-size: 1.5rem;
        padding: 0px;
    }

    .goalprogress-container {
        min-width: 60px;
        max-width: 60px;
        height: 15px;
    }
}