@import url("https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@100;400;500;700&family=Baloo+2:wght@400;500;600;700&family=Comfortaa:wght@500&family=Quicksand:wght@300;400;500;600;700&family=Raleway:wght@300;400;500;600;700&display=swap");


@-webkit-keyframes topuser-color-change-2x {
  0% {
    background: #ffbd2b;
  }

  100% {
    background: #f7d200;
  }
}

@keyframes topuser-color-change-2x {
  0% {
    background: #ffbd2b;
  }

  100% {
    background: #f7d200;
  }
}


@-webkit-keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }

  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}

@keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }

  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in-b {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes fade-in-b {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.2;
  }
}

@-webkit-keyframes color-change-2x {
  0% {
    background: #11b95100;
  }

  100% {
    background: #48be12;
  }
}

@keyframes color-change-2x {
  0% {
    background: #0d8640;
  }

  100% {
    background: #1a6cd8;
  }
}

@-webkit-keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

@-webkit-keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
}

@keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    opacity: 1;
  }
}

@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@-webkit-keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}

@keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}

@-webkit-keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}

@keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}


.add-purchase.animate {
  background-color: #307e3c;
  color: #000000;
  font-size: 1.1rem;
  width: 80px;
  animation: fade-in 0.5s linear forwards;
}

/* .add-purchase.animate::before {
  content: '\2713';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 24px;
} */

@-webkit-keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}


body {
  font-family: "Lato", sans-serif;

  color: #fff;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}



input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
  appearance: textfield;
  /* Other browsers */
  /* Add additional styling as needed */
}

/* tbody tr:nth-child(even) {
  background-color: #2b2b2bf3;
  height: 45px;
}

tbody tr:nth-child(odd) {
  background-color: #353535f3;
  height: 45px;
}  */
/* td:last-child(odd) {
  border-radius: 0px 0px 10px 10px;
} */

/* td:first-child {
  border-radius: 0px 0px 0px 10px;
} */

.App {
  height: 100vh;
  /* max-width: 480px; */
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to top right, rgb(251, 249, 245), #f7f5ec);
  /* background-color: rgb(251, 249, 245); */
  z-index: -300;
  /* overflow: hidden; */
}

.show-tutorial-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.opentutorialbutton {
  color: rgb(51, 51, 51);
  background: transparent;
  outline: none;
  border: none;
  font-family: "Quicksand", sans-serif;
  height: auto;
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 15px;
}

.backtopurchases {
  width: 100%;
  top: 10px;
  left: 10px;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  border: none;
  outline: none;
  background-color: transparent;
  line-height: 1rem;
  color: rgb(61, 61, 61);
}

/* h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  margin-top: 0;
  font-family: 'Raleway', sans-serif;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
} */

.select-menu {
  width: 180px;
}

/* input[type='number'] {
  font-size: 1.2rem;
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  margin-right: 10px;
  width: 120px;
  box-sizing: border-box;
} */

select {
  font-size: 16px;
  font-weight: 400;
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  width: 220px;
  /* background-color: transparent; */
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
}

@-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}

@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



.spinner {
  font-size: 4rem;
  color: rgb(66, 66, 66);
  -webkit-animation: rotate-center 0.6s ease-in-out infinite both;
  animation: rotate-center 0.6s ease-in-out infinite both;
}

.full-loader {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: grayscale(1)
}

.viewport-container {
  width: 100vw;
  height: auto;
  overflow: auto;
}

.page-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: none;
  margin: none;
  flex-direction: row;
  position: relative;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}

.budgetpagecontainer {
  position: absolute;
  /* overflow-y: scroll; */
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 0px 8px 5px 8px;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  left: 0px;
  padding-bottom: 100px;
}

.purchasespagecontainer {
  box-sizing: border-box;
  padding: 0px 8px 5px 8px;
  width: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  position: absolute;
  top: 0px;
}

.groupspagecontainer {
  box-sizing: border-box;
  padding: 0px 8px 5px 8px;
  width: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  position: absolute;
  top: 0px;
  -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.groupsmegamommy {
  -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


.achievementspagecontainer {
  box-sizing: border-box;
  padding: 0px 8px 5px 8px;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  position: absolute;
  top: 0px;
}

.component-indicator-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  position: fixed;

  max-width: 200px;
  line-height: 15px;
  top: 10px;
  left: 10px;
}

.component-indicator {
  width: 55px;
  font-size: 1.2rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  padding: 2px;
  box-sizing: border-box;
  margin-left: 5px;
  font-weight: bold;
  color: #919191e3;
  border-radius: 0px;
  transition: background-color 0.3s;
  -webkit-tap-highlight-color: transparent;
  border-bottom: 3px solid transparent;
  border-left: none;
  border-right: none;
  border-top: none;
  outline: none;
  background-color: transparent;
}


.component-indicator-right-side {
  position: absolute;
  width: 55px;
  font-size: 1.2rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  padding: 2px;
  box-sizing: border-box;
  margin-left: 5px;
  font-weight: bold;
  color: #919191e3;
  border-radius: 0px;
  transition: background-color 0.3s;
  -webkit-tap-highlight-color: transparent;
  border-bottom: 3px solid transparent;
  border-left: none;
  border-right: none;
  border-top: none;
  outline: none;
  background-color: transparent;
}



.component-indicator3 {
  width: 55px;
  font-size: 1.4rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  position: absolute;
  right: 40px;
  top: 9px;
  padding: 2px;
  box-sizing: border-box;
  margin-left: 5px;
  font-weight: bold;
  color: #919191e3;
  border-radius: 0px;
  transition: background-color 0.3s;
  -webkit-tap-highlight-color: transparent;
  border-bottom: 3px solid transparent;
  border-left: none;
  border-right: none;
  border-top: none;
  outline: none;
  background-color: transparent;
}


.component-indicator4 {
  width: 160px;
  font-size: 0.8rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  position: absolute;
  right: 150px;
  top: 12px;
  padding: 2px;
  box-sizing: border-box;
  margin-left: 5px;
  font-weight: bold;
  color: #292929e3;
  border-radius: 0px;
  transition: background-color 0.3s;
  -webkit-tap-highlight-color: transparent;
  border-bottom: 3px solid transparent;
  border-left: none;
  border-right: none;
  border-top: none;
  outline: none;

  background-color: transparent;
}

.component-indicator5 {
  width: 55px;
  font-size: 1.4rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  position: absolute;
  right: 90px;
  top: 9px;
  padding: 2px;
  box-sizing: border-box;
  margin-left: 5px;
  font-weight: bold;
  color: #919191e3;
  border-radius: 0px;
  transition: background-color 0.3s;
  -webkit-tap-highlight-color: transparent;
  border-bottom: 3px solid transparent;
  border-left: none;
  border-right: none;
  border-top: none;
  outline: none;
  background-color: transparent;
}

.component-indicator-budget {
  width: 75px;
  font-size: 0.8rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  padding: 2px;
  margin-left: 5px;
  font-weight: bold;
  color: #919191e3;
  border-radius: 5px;
  transition: background-color 0.3s;
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  background-color: transparent;
}


.component-indicator2 {
  float: right;
  position: absolute;
  right: 125px;
  top: 7px;
  width: 25px;
  /* margin-left: 20px; */
  padding: 0px;
  margin: 0px;
  line-height: 1.2rem;
  height: 25px;
  transition: background-color 0.3s;
  -webkit-tap-highlight-color: transparent;
  border: 3px solid transparent;
  outline: none;
  background-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
}


.greyscale {
  filter: grayscale(1);
}


.star {
  width: 15px;
  height: 15px;

}

.component-indicator.active {
  border-bottom: 3px solid rgb(29, 56, 53);
  color: #ffffff;
  -webkit-tap-highlight-color: transparent;
}

.component-indicator2.activestar {
  border: 3px solid rgb(29, 56, 53);
  border-radius: 50%;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}


.budgetpageheader {
  font-size: 1.1rem;
  font-weight: 500;
  color: rgb(66, 66, 66);
  padding: 10px 0px 5px 5px;
  box-sizing: border-box;
  line-height: 1.1rem;
  margin: 0px;
  width: 100%;
  position: relative;
}

.budgetpageheader2 {
  height: 35px;
  border-radius: 0px 0px 10px 10px;
  font-size: 0.75rem;
  padding-left: 5px;
  box-sizing: border-box;
  margin: 0px;
  margin-top: -6px;
  width: 100%;
  background-color: transparent;
  position: relative;
}

.splashpagecontainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9990;
  display: flex;
  justify-content: center;
  background-color: #c2c2c257;
  align-items: center;
  backdrop-filter: blur(100px);
  -webkit-animation: puff-out-center 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 1s both;
  animation: puff-out-center 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 1s both;
}

.splashpagelogobox {
  background-color: transparent;
  width: 74px;
  height: 78px;
  position: relative;
  /* transform: rotate(90deg); */
  /* Rotate the container by 90 degrees */
}

.splashlogo1 {
  width: 20px;
  border-radius: 5px 0px 0px 5px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  -webkit-animation: scale-in-ver-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s both;
  animation: scale-in-ver-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s both;
}

.splashlogo2 {
  width: 20px;
  position: absolute;
  left: 28px;
  bottom: 0px;
  -webkit-animation: scale-in-ver-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
  animation: scale-in-ver-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
}

.splashlogo3 {
  width: 20px;
  border-radius: 0px 5px 5px 0px;
  position: absolute;
  left: 54px;
  bottom: 0px;
  -webkit-animation: scale-in-ver-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s both;
  animation: scale-in-ver-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s both;
}

.splashlogo4 {
  width: 20px;
  position: absolute;
  left: 28px;
  top: 0px;
  -webkit-animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s both;
  animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s both;
}



.purchasefilters {
  background-color: rgba(141, 141, 141, 0.514);
  width: 200%;
  border-radius: 0px 0px 10px 10px;
  height: 20px;
}

.purchaselistfilter {
  width: 100%;
  box-sizing: border-box;
  height: 35px;
  font-size: 0.8rem;
  font-family: "Quicksand", sans-serif;
  text-align: left;
  border: none;
  outline: none;
  border-radius: 5px;
  padding-left: 5px;
}

.moneyiconcontainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.moneyicon {
  width: 35px;
}

.inkwellimg {
  width: 100px;
  -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


.inkwellcontainer {
  margin-top: 50px;
}

.test {
  color: rgb(0, 100, 100), rgb(10, 99, 90), rgb(20, 98, 80), rgb(30, 97, 70),
    rgb(40, 96, 60), rgb(50, 95, 50), rgb(60, 94, 40), rgb(70, 93, 30),
    rgb(80, 92, 20), rgb(90, 91, 10), rgb(100, 90, 0), rgb(110, 90, 0),
    rgb(120, 90, 0), rgb(130, 90, 0), rgb(140, 90, 0), rgb(145, 90, 0),
    rgb(150, 70, 0), rgb(100, 45, 0), rgb(110, 30, 0),
    rgb(120, 0, 0) #363636;
}

.grouppurchasebuttonmodal {
  display: flex;
  flex-direction: column;
  padding: 10%;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  justify-content: space-evenly;
}

/* .add-purchase:hover {
  background-color: #ffdc80;
} */
.grouppurchasebuttonmodalheader {
  text-align: center;
  width: 100%;
  color: rgb(20, 20, 20);
  font-weight: 600;
}



.hideorshowpassword {
  outline: none;
  border: none;
  background: transparent;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  color: rgb(32, 32, 32);
}

.selectgroupbutton {
  border: 1px solid grey;
  border-radius: 5px;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  line-height: 2rem;
  display: flex;
  box-sizing: border-box;
  background-color: rgb(241, 241, 241);
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.logincontspecial {
  display: flex;
  -webkit-box-align: center;
  /* Vendor prefix for Safari */
  align-items: center;
  -webkit-box-pack: center;
  /* Vendor prefix for Safari */
  justify-content: center;
  width: 216px;
}

.topuserdiv {
  font-size: 0.7rem;
  position: absolute;
  top: 12px;
  right: 110px;
  /* background-color: #ffbd2b; */
  color: rgb(17, 17, 17);
  line-height: 0.9rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2px;
  border-radius: 5px;
  -webkit-animation: topuser-color-change-2x 2s linear infinite alternate both;
  animation: topuser-color-change-2x 2s linear infinite alternate both;
  filter: drop-shadow(0 0 0.2rem rgba(192, 192, 192, 0.507));
}

.add-purchase {
  width: 80px;
  height: 50px;
  font-size: 0.8rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  border: none;
  background-color: #ffbd2b;
  color: #000;
  cursor: pointer;
  /* margin-left: 10px; */
  -webkit-tap-highlight-color: transparent;

}

.add-purchase.payment {
  width: 80px;
  height: 50px;
  font-size: 0.8rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  border: none;
  background-color: rgb(29, 56, 53);
  color: #FFF;
  cursor: pointer;
  /* margin-left: 10px; */
  -webkit-tap-highlight-color: transparent;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes oddcolor-change-3x {
  0% {
    background: #3b3b3bf3;
  }

  50% {
    background: #616b5cc2;
  }

  100% {
    background: #3b3b3bf3;
  }
}

@keyframes oddcolor-change-3x {
  0% {
    background: #3b3b3bf3;
  }

  50% {
    background: #616b5cc2;
  }

  100% {
    background: #3b3b3bf3;
  }
}

@-webkit-keyframes evencolor-change-3x {
  0% {
    background: #2b2b2bf3;
  }

  50% {
    background: #616b5cc2;
  }

  100% {
    background: #2b2b2bf3;
  }
}

@keyframes evencolor-change-3x {
  0% {
    background: #2b2b2bf3;
  }

  50% {
    background: #616b5cc2;
  }

  100% {
    background: #2b2b2bf3;
  }
}

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}

@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.dropdown {
  margin-right: 15px;
  width: 50%;
  max-width: 200px;
  height: 50px;
  font-size: 1.1rem;
  font-family: "Quicksand", sans-serif;
  outline: none;
  box-sizing: border-box;
  /* padding-right: 10px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: rgba(255, 255, 255, 0);
  color: rgb(255, 255, 255);
}

.coin {
  width: 50px;
  opacity: 80%;
}

.portfolioicon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
}

.emojimenucontainer {
  width: 80%;
  background-color: white;
  height: 200px;
}

.financemessage {
  font-size: 0.65rem;
  width: 55%;
  text-align: center;
  margin-bottom: 200px;
  justify-content: end;
  font-style: italic;
  color: rgb(94, 94, 94);
  padding: 2%;
  animation: fade-in 1s linear forwards;

  text-align: center;
  /* top: 0;
  right: 0; */
  z-index: 2;
}

.purchaseaddgroup {
  height: 15px;
  font-size: 0.7rem;
  padding: 0px;
  margin: 0px;
  color: #242424;
  font-family: "Quicksand", sans-serif;
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  justify-content: right;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  text-align: right;
  /* white-space: nowrap; */
  /* Prevents text from wrapping */
  overflow: hidden;
  /* Hides the overflowing text */
  text-overflow: ellipsis;
  /* Adds ellipsis (...) to indicate text overflow */
  /* direction: rtl; */
  /* Sets the text direction to right-to-left */
}

.purchaseaddgroup2 {
  height: 15px;
  font-size: 0.7rem;
  padding: 0px;
  box-sizing: border-box;
  color: rgb(10, 62, 110);
  margin: 0px;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
  background-color: transparent;
  border-radius: 25px;
  border: none;
  outline: none;
  text-align: right;
  /* white-space: nowrap; */
  /* Prevents text from wrapping */
  overflow: hidden;
  /* Hides the overflowing text */
  text-overflow: ellipsis;
  /* Adds ellipsis (...) to indicate text overflow */
  direction: rtl;
  /* Sets the text direction to right-to-left */
}


.charttypetoggle {
  width: 100%;
  background-color: #f1f1f1f3;
  display: flex;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding: 5px;
  margin-top: -20px;
  /* border-bottom: 1px solid rgb(29, 56, 53);
  border-left: 1px solid rgb(29, 56, 53);
  border-right: 1px solid rgb(29, 56, 53); */
}

.chartbycategory {
  width: 25%;
  border: none;
  color: #131313;
  background-color: transparent;
  border-radius: 0px;
  outline: none;
  font-size: 0.7rem;
  font-family: "Quicksand", sans-serif;
  box-sizing: border-box;
}

.chartbycategory2 {
  width: 25%;
  border: 1px solid #616161;
  margin: 10px;
  color: #131313;
  background-color: transparent;
  border-radius: 5px;
  outline: none;
  font-size: 0.7rem;
  font-family: "Quicksand", sans-serif;
  box-sizing: border-box;
}

.chartbycategoryedit {
  width: 25%;
  border: 1px solid #616161;
  margin: 5px;
  color: #131313;
  background-color: transparent;
  border-radius: 25px;
  outline: none;
  font-size: 0.7rem;
  font-family: "Quicksand", sans-serif;
  box-sizing: border-box;
}

.chartbycategory.active {
  width: 25%;
  border: 1px solid rgb(29, 56, 53);
  color: rgb(29, 56, 53);
  font-weight: 500;
  border-radius: 5px;
  outline: none;
  font-size: 0.7rem;
  font-family: "Quicksand", sans-serif;
  box-sizing: border-box;
}

.chartbycategory2.active {
  width: 25%;
  border: 0px solid #616161;
  color: #ffffff;
  background-color: #852626;
  border-radius: 5px;
  outline: none;
  font-size: 0.7rem;
  font-family: "Quicksand", sans-serif;
  box-sizing: border-box;
}

option {
  color: rgb(26, 26, 26);
  outline: none;
}

option:active {
  color: rgb(71, 71, 71);
  background-color: #077703;
}

option:focus {
  color: rgb(71, 71, 71);
  background-color: #077703;
}

option:hover {
  color: rgb(71, 71, 71);
  background-color: #077703;
}

option::selection {
  color: rgb(71, 71, 71);
  background-color: #077703;
}

.chartbytotal {
  width: 25%;
  border: 1px solid #616161;
  color: #838383;
  background-color: transparent;
  border-radius: 25px;
  outline: none;
  font-size: 0.7rem;
  font-family: "Quicksand", sans-serif;
}

tbody tr:nth-child(even).highlight {
  background-color: #cacaca54;
}

tbody tr:nth-child(odd).highlight {
  background-color: #cacaca54;
}

.colorline {
  width: 100%;
  height: 2px;
  /* margin-top: 100px; */
  -webkit-animation: color-change-2x 2s linear infinite alternate both;
  animation: color-change-2x 2s linear infinite alternate both;
}

.colorline2 {
  width: 100%;
  height: 2px;
  -webkit-animation: color-change-2x 2s linear infinite alternate both;
  animation: color-change-2x 2s linear infinite alternate both;
}

.whylilbudgetcont {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 9999;
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.incomebudgetformcomponentcontainer {
  border-radius: 10px 10px 0px 0px;
  overflow-y: scroll;
  -webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.incomebudgetformcontainerreminder {
  width: 100%;
  /* height: 100vh; */

  /* background-color: #077703; */
}

.showincomeformbutton {
  color: rgb(10, 62, 110);
  font-size: 0.7rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  background-color: transparent;
  text-decoration: underline;
  border: none;
  padding-top: 5px;
  text-align: left;
  padding-left: 0px;

}

.archivetableheaderlabel {
  width: 100%;
  height: 35px;
  background-color: rgb(5, 44, 40);
  font-size: 0.8rem;
  color: rgb(241, 241, 241);
  text-align: center;
  line-height: 35px;
  font-weight: 600;
}

.whatslilbudget {
  width: 160px;
  height: 45px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  top: 20px;
  color: rgba(46, 100, 88, 0.808);
  font-size: 2.5rem;
  position: absolute;
  background-color: rgba(25, 118, 210, 0);
}

.valuecircle {
  position: absolute;
  top: -8px;
  left: 20px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #a0a0a063;
}

.showvaluepropbutton {
  margin-bottom: 0px;
  margin-top: 30px;
  /* position: absolute;
  top: 18px;
  left: 18px; */
  width: 150px;
  height: 35px;
  background-color: rgba(0, 128, 0, 0);
  color: rgb(12, 85, 75);
  border: none;
  outline: none;
  font-size: 0.9rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  /* background-color: rgb(131, 9, 50); */
  border-radius: 5px;
  animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
}

.animationboxcontainer {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #cccccc;
  /* border-radius: 10px; */
  /* backdrop-filter: blur(10px); */
  overflow: hidden;
  animation: fade-in 0.1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.1s both;
}

.makeitrainbutton {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  color: rgb(185, 185, 180);

}

.makeitrainbutton2 {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  color: rgb(177, 177, 177);

}

.makeitrainbutton3 {
  position: absolute;
  top: 10px;
  right: 60px;
  font-size: 2rem;
  color: rgb(177, 177, 177);

}

.makeitrainbutton4 {
  position: absolute;
  top: 10px;
  right: 60px;
  font-size: 2rem;
  color: rgb(17, 131, 116);

}

.makeitrainbutton5 {
  position: absolute;
  top: 110px;
  right: 10px;
  font-size: 2rem;
  color: rgb(17, 131, 116);

}

.makeitrainbutton6 {
  position: absolute;
  top: 110px;
  right: 10px;
  font-size: 2rem;
  color: rgb(177, 177, 177);

}



.transactionanimation {
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  margin-right: 5px;
  display: flex;
  justify-content: right;
  text-align: end;
  font-size: 1rem;
  width: 95%;
  height: 50px;
  color: rgb(86, 182, 83);
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  background-color: #07770300;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.transactionanimation.show {
  opacity: 0;
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.behindbudget {
  width: 100%;
  font-size: 0.8em;
  color: grey;
  font-weight: 700;
  display: flex;
  justify-content: right;
  margin-top: 0px;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }

  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}

@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }

  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.coffee-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 20%;
  z-index: -50;
  overflow: hidden;
  animation: fade-in-b 1.5s linear forwards;
}

.appcontainer {
  /* max-width: 480px; */
  position: fixed;
  padding: 0px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -50;
  overflow: hidden;
  -webkit-animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
  animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
}

.coffee1 {
  opacity: 50%;
  width: 5000px;
  height: 5000px;
  position: absolute;
  top: -150%;
  right: 10%;
  pointer-events: none;
  overflow: hidden;
  -webkit-animation: rotate-center 1000s linear infinite both;
  animation: rotate-center 1000s linear infinite both;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.coffee2 {
  opacity: 50%;
  width: 5000px;
  height: 5000px;
  position: absolute;
  top: -150%;
  right: 10%;
  pointer-events: none;
  overflow: hidden;
  -webkit-animation: rotate-center 400s linear infinite reverse both;
  animation: rotate-center 400s linear infinite reverse both;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.coffee3 {
  opacity: 50%;
  width: 5000px;
  height: 5000px;
  position: absolute;
  top: -150%;
  right: 10%;
  pointer-events: none;
  overflow: hidden;
  -webkit-animation: rotate-center 650s linear infinite both;
  animation: rotate-center 650s linear infinite both;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.nav-tabs {
  display: flex;
  justify-content: left;
  list-style: none;
  margin: 8px 0px 0px 0px;
  padding: 0;
}

.nav-tabs-alternate {
  font-size: 0.7rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  color: rgb(27, 27, 27);
  display: flex;
  justify-content: left;
  list-style: none;
  margin: 0;
  padding: 10px;
  border-radius: 5px 5px 0px 0px;
  border: none;
  outline: none;
  background-color: #d6d6d6;
}

.deleted {
  background-color: #ffcccc;
}

.deleted .deletepurchase {
  display: none;
}

.deleted .confirmdelete,
.deleted .canceldelete {
  display: inline-block;
  margin-right: 5px;
}

.nav-tabs li {
  background-color: #cacacaf3;
  font-weight: 500;
  margin: 0;
  padding: 0;
  border-radius: 5px 5px 0px 0px;
  -webkit-tap-highlight-color: transparent;
  /* border-bottom: 1px solid red; */
}

.nav-tabs li a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #2b2b2b;
  font-size: 0.7em;
  -webkit-tap-highlight-color: transparent;
  /* border-bottom: 1px solid red; */

  /* background-color: #c2c2c2f3; */
}

/* .tabcontainer {
  height: 20px;
} */

.nav-tabs li.active {
  background-color: #f1f1f1f3;
  font-weight: 700;
  border-radius: 5px 5px 0px 0px;
  -webkit-tap-highlight-color: transparent;
}

.nav-tabs-alternate.active {
  background-color: #f1f1f1f3;
  color: rgb(27, 27, 27);
  font-weight: 700;
  border-radius: 5px 5px 0px 0px;
  -webkit-tap-highlight-color: transparent;
}

/* .tabactive {
  background-color: #246781c9;
  font-weight: 700;
} */

.emptystate {
  height: 50px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: 100%;

  font-size: 0.8rem;
  padding: 15px 0px 15px 0px;
  color: rgb(17, 17, 17);
}

.add-purchase[disabled] {
  background-color: #d8d8d8;
  opacity: 0.5;
  pointer-events: none;
}

.confirmation-okay-dark[disabled] {
  background-color: #d8d8d8;
  opacity: 0.5;
  pointer-events: none;
}

.widgetarea {
  padding: 2px;
  border-radius: 10px;
  width: 98%;
  /* margin-top: 40px; */
  height: 40px;
  /* filter: drop-shadow(0 0 0.75rem rgba(192, 192, 192, 0.507)); */
}

.dailyspendingcontainer {
  padding: 2;
}

.emptystatebutton {
  width: 250px;
  height: 50px;
  font-size: 0.8rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  background-color: #ffbd2b;
  color: #000;
  cursor: pointer;
  animation: fade-in 0.2s linear forwards;
}

.datepickerwrapper {
  width: 200%;
}

regbutton:disabled {
  opacity: 0.5;
  background-color: grey;
  cursor: not-allowed;
}

.dollarinput {
  padding: 0.2rem;
  border-radius: 4px;
  border: none;
  margin-right: 10px;
  vertical-align: middle;
  text-align: center;
  box-sizing: border-box;
  height: 50px;
  width: 90px;
  max-width: 90px;
  font-size: 2rem;
  font-family: "Baloo 2", cursive;
  outline: none;
  resize: none;
}

.dollarinput::placeholder {
  font-size: 0.6rem;
  color: rgb(155, 155, 155);
  padding-top: 6px;
  /* font-weight: 700; */
}

.categorytipsheader1 {
  color: rgb(19, 19, 19);
  font-size: 1rem;
  padding: 10px;
}

.categorytipsheader2 {
  color: rgb(19, 19, 19);
  font-size: 0.8rem;
}

ul.categorytiplist {
  padding: 0px;
}

.categorytiplistheader {
  font-size: 0.9rem;
  font-weight: 700;
}

li.categorytiplistitem {
  color: rgb(56, 56, 56);
  text-align: left;
  font-size: 0.8rem;
  padding: 5px;
  font-weight: 500;
  list-style-type: none;
}

.showcategorytipsbutton {
  background-color: transparent;
  border: none;
  outline: none;
  color: rgb(10, 62, 110);
  font-size: 0.7rem;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-decoration: underline;
  padding-bottom: 200px;
}

/* progress {
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background-color: #ddd;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
}

progress::-webkit-progress-value {
  background-color: #2196f3;
  border-radius: 10px;
}

progress::-moz-progress-bar {
  background-color: #2196f3;
  border-radius: 10px;
}

.undo-button {
  background-color: #ffffff;
  color: #1976d2;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin-left: 8px;
  cursor: pointer;
} */

.emoji-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  padding: 10px;
}

.emoji-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.emoji-button:hover {
  background-color: #f1f1f1;
}

tr.highlighted {
  background-color: yellow;
  color: rgba(0, 0, 0, 0.918);
}

/* 
tr:last-child td:last-child {
  border-radius: 0px 0px 10px 0px;
}

tr:last-child td:first-child {
  border-radius: 0px 0px 0px 10px;
} */

/* .remainingbudgettext tr:last-child td:first-child {
  background-color: transparent;
  border-radius: 0px 0px 10px 0px;
} */

.over-budget {
  padding-top: 10px;
  color: rgb(114, 8, 8);
  font-weight: 700;
}

.tagline {
  font-size: 0.7rem;
  text-align: center;
  color: rgb(26, 26, 26);
  font-weight: 500;
}

.budgetpage-emptystatebuttoncontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.budgetpage-emptystateheadertext {
  font-weight: 700;
  width: 100%;
  color: rgb(5, 44, 40);
  text-align: center;
  margin-top: 5%;
}

.budgetpage-emptystatebuttoncontainer {
  width: 100%;
  display: flex;
  flex-direction: column;


}

.budget-table {
  display: relative;
  z-index: 50;
  transition: top 0.3s ease-out;
  border-radius: 10px;
  max-height: 100vh;
  overflow: scroll;
}

.budget-table.move {
  top: 50px;
  /* set the new position */
}

.installmsgheader {
  width: 90%;
  margin-top: 20%;
  font-size: 1.3rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
}

.installcta {
  background-color: #383e44;
  /* border: 1px solid #c2c2c2; */
  padding-top: 30px;
  box-sizing: border-box;
  max-width: 480px;
  width: 100%;
  height: 90px;
  border-radius: 0px 0px 25px 25px;
  color: rgb(255, 255, 255);
  position: fixed;
  top: 0;
}

.closeinstall {
  padding: 0.5em 1em;
  background-color: #788ea700;
  color: #c0c0c0;
  border: none;
  border-radius: 0.25em;
  font-size: 1em;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  width: 150px;
  position: absolute;
  bottom: 70px;
}

.installasanapp {
  position: fixed;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 160px;
  font-family: "Quicksand", sans-serif;
  font-size: 0.8rem;
  border: none;
  background-color: transparent;
  color: rgb(255, 255, 255);
  animation: fade-in 0.5s linear forwards;
  z-index: 2000;
}

.budget-container {
  margin-top: 40px;
  box-sizing: border-box;
  /* margin-bottom: 160px; */
  width: 100%;
}

.entirebudgetcontainer {
  overflow-y: scroll;
  box-sizing: border-box;
  padding-top: 30px;
  height: calc(100vh);
  /* height: calc(100vh - 146px); */
  /* padding-bottom: 140px;
  padding-top: 30px; */
}

.datecounter {
  padding: 0;
  margin-top: 10px;
  width: 100%;
  font-size: 0.75rem;
  font-family: "Quicksand", sans-serif;
  text-align: right;
  /* margin-top: 5px; */
  color: rgb(20, 20, 20);
  line-height: 1rem;
  display: flex;
  flex-direction: row;
}

.infocircle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 3px 0px 3px;
  box-sizing: border-box;
  padding-top: 0px;
  color: rgb(73, 73, 73);
}

.classForLessThan2 {
  font-weight: 700;

  color: rgb(114, 8, 8);
}

.classForLessThan4 {
  font-weight: 700;
  color: rgb(20, 20, 20);
}

.resetconfirm {
  width: 80%;
  box-sizing: border-box;
  max-width: 480px;
  background-color: #e2e2e2;
  border-radius: 5px;
  padding: 10px;

  z-index: 9999;
  margin: 5px 25px 5px 25px;
  position: relative;
  color: rgb(0, 100, 100);
  filter: drop-shadow(0 0 0.5rem rgb(31, 31, 31));
}

.resetconfirmbottom {
  max-height: 420px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  max-width: 480px;
  background-color: #0a0a0a;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  margin: 5px 25px 0px 25px;
  position: fixed;
  bottom: 0;
  color: #ffbd2b;
  filter: drop-shadow(0 0 0.5rem rgb(31, 31, 31));
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.resetconfirm2 {
  width: 90%;
  max-width: 480px;
  height: auto;
  max-height: 400px;
  overflow: scroll;
  background-color: #e2e2e2;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 5px 5px 5px;
  /* position: relative; */
  color: rgb(0, 100, 100);
  filter: drop-shadow(0 0 0.5rem rgb(31, 31, 31));
}

.resetconfirm3 {
  width: 100%;
  max-width: 480px;
  height: auto;
  max-height: 250px;
  overflow: scroll;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
  /* position: relative; */
  color: rgb(0, 100, 100);
  filter: drop-shadow(0 0 0.5rem rgb(31, 31, 31));
}

.paydayheader2 {
  /* background-color: #e2e2e2e0; */
  /* border-radius: 5px;
  padding: 10px; */
  color: rgb(71, 71, 71);
  margin: 5px 25px 5px 25px;
  font-size: 0.9rem;
  position: relative;
}

.paydayheader3 {
  /* background-color: #e2e2e2e0;
  border-radius: 5px;
  padding: 10px; */
  color: rgb(71, 71, 71);
  font-size: 0.8rem;
  margin: 5px 25px 5px 25px;
  position: relative;
}

.paydayheader4 {
  /* background-color: #e2e2e2e0;
  border-radius: 5px;
  padding: 10px; */
  color: rgb(46, 46, 46);
  font-size: 1rem;
  margin: 5px 25px 5px 25px;
  position: relative;
}

.divaccent {
  position: absolute;
  width: 100%;
  height: 10px;
  border-radius: 4px 4px 0px 0px;
  top: 0;
  left: 0;
  background-color: rgb(10, 100, 90);
}

.divaccent2 {
  position: absolute;
  width: 100%;
  height: 10px;
  border-radius: 5px 5px 0px 0px;
  top: 0;
  left: 0;
  background-color: rgb(10, 100, 90);
}

.divaccent3 {
  position: absolute;
  width: 100%;
  height: 10px;
  border-radius: 5px 5px 0px 0px;
  top: 0;
  left: 0;
  background-color: #852626;
}

.divaccent4 {
  position: absolute;
  width: 100%;
  height: 10px;
  border-radius: 4px 4px 0px 0px;
  top: 0;
  left: 0;
  background-color: #0C0C0C;
}

.reset-btn {
  cursor: pointer;
  float: right;
}

.reset-btn:hover {
  background-color: #555;
}

.inputwidgetcontainer {
  width: 100vw;
  position: fixed;
  bottom: 0;
  /* margin-left: -10px; */
  /* left: 0; */
  z-index: 9900;
  box-sizing: border-box;
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.inputflexcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-width: 480px; */
  box-sizing: border-box;
}

.input-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  /* margin-top: 40px; */
  /* font-size: .8rem; */
  width: 100vw;
  /* max-width: 480px; */
  background-color: #0a0a0a;
  padding-bottom: 20px;
  padding-top: 2%;
  padding-right: 0%;
  box-sizing: border-box;
  z-index: 9900;
}

.inputcontainerdiv {
  width: 100%;
  height: auto;
}

.inputcontainerform {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 95%;
  /* max-width: 480px; */
}

.transactionsuccess {
  position: absolute;
  background-color: #0d8640;
  border-radius: 5px;
  top: 8vh;
  left: 20px;
  font-size: 0.9em;
  color: #fafafa;
  font-weight: 700;
  z-index: 6000;
  -webkit-animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2.5s both;
  animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2.5s both;
}

.transactionsuccessanimate {
  -webkit-animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2.5s both;
  animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2.5s both;
}

.slide-out {
  -webkit-animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.slide-in {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.nocategoryemptystate {
  text-align: center;
  color: rgb(6, 59, 53);
  font-size: 0.9rem;
  font-weight: 600;
}

.nocategoryemptystate2 {
  text-align: center;
  color: #5c5c5c;
  font-size: 0.8rem;
  font-weight: 600;
}

.no-scroll {
  overflow-x: hidden;
}

.confirm-message {
  position: absolute;
  width: 100%;
  padding: 0px 2px 0px 2px;
  text-align: center;
  height: 35px;
  line-height: 2rem;
  top: 0px;
  background-color: rgb(6, 59, 53);
  border-bottom: 1px solid grey;
  font-size: 0.8rem;
  color: #F1f1f1;
  font-weight: 700;
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slideoutmessage {
  -webkit-animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.reviewpurchasesminibutton {
  border: 0px solid #070707;
  height: 15px;
  background-color: transparent;
  border-radius: 10px;
  color: #dfdfdf;
  font-size: 0.7rem;
  outline: none;
  font-family: "Quicksand", sans-serif;
  line-height: 0.7rem;
  font-weight: 500;
  float: right;
  margin-top: 3px;
  margin-right: 5px;
  text-decoration: underline;
}

.groups-sync-purchases-label {
  font-weight: 700;
  font-size: 0.8rem;
  font-style: normal;
  color: rgb(10, 62, 110);
  text-decoration: underline;
}

.groups-sync-purchases {
  border: 0px solid #070707;
  height: 65px;
  background-color: transparent;
  border-radius: 10px;
  color: rgb(10, 62, 110);
  font-size: 0.7rem;
  outline: none;
  font-family: "Quicksand", sans-serif;
  line-height: 0.7rem;
  font-weight: 500;
  float: right;
  margin-top: 3px;
  font-style: italic;
  margin-right: 10px;
  width: 100%;

}

.message-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 8000;
}

.deletemessage {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 35px;
  line-height: 2rem;
  padding: 0px 2px 0px 2px;
  text-align: center;
  background-color: #852626;
  font-size: 0.8rem;
  color: #F1f1f1;
  font-weight: 700;
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.groupdeletemessage {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 35px;
  line-height: 2rem;
  padding: 0px 2px 0px 2px;
  text-align: center;
  background-color: #852626;
  font-size: 0.8rem;
  color: #F1f1f1;
  font-weight: 700;
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.confirm-message-animate {
  -webkit-animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2.5s both;
  animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2.5s both;
}

.reset-container {
  width: 100%;
  height: auto;
  z-index: 8000;
  position: absolute;
}

.reviewstat {
  font-size: 0.8rem;
}

.reviewheader {
  font-size: 1.1rem;
  font-weight: 700;
}

.reviewheader1 {
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 700;
}

.donutcanvas {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  overflow: scroll;
  height: 40vh;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.donutchart {
  height: auto;

}

.reviewdonut {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: auto;
  max-height: 50vh;
  overflow: scroll;
}

.resetbutton {
  background: transparent;
  width: 75px;
  float: right;
  position: absolute;
  right: 45px;
  top: 10px;
  color: #111111;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  outline: none;
  border: none;
  font-size: 0.8rem;
}

.budgetheader {
  text-align: right;
}

.purchases-container {
  display: grid;
  width: 100;
  height: 100vh;
  z-index: 2;

  overflow: scroll;
}

.newunreviewedpurchasedot {
  border-radius: 50%;
  width: 7px;
  height: 7px;
  background-color: #ffffff;
  position: absolute;
  right: 115px;
  top: 7px;
}

.purchasescontainerheader {
  line-height: 1.3rem;
  position: relative;
  padding: 0px 0px 0px 10px;
  box-sizing: border-box;
  margin: 30px 0px 0px 0px;
  width: 100%;
  height: 20px;
  font-weight: 600;
  text-align: left;
  font-size: 0.8rem;
  background-color: rgb(29, 56, 53);
  border-radius: 10px 10px 0px 0px;
}

.archivedpurchases-container {
  /* display: grid; */
  width: 100;
  height: calc(100vh - 40px);
  overflow-y: scroll;
  margin-top: -3px;
  z-index: 2;
  box-sizing: border-box;
  padding: 5px;
}

.loginoverlay2 {
  background-color: #1976d2;
  width: 100%;
  height: 100%;
}

.loader {
  width: 200px;
  justify-self: center;
  height: 35px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5%;
  border: none;
  outline: none;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  background-color: #23547c;
  color: rgb(219, 219, 219);
  animation: fade-in 0.5s linear forwards;
}

.loader2 {
  width: 200px;
  justify-self: center;
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  background-color: rgb(29, 56, 53);
  color: rgb(219, 219, 219);
  animation: fade-in 0.5s linear forwards;
}

.boxoballs {
  display: flex;
  flex-flow: row;
  padding-left: 20%;
  padding-right: 20%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.boxoballs2 {
  display: flex;
  /* padding-bottom: 50px; */
  flex-flow: row;
  padding-left: 20%;
  padding-right: 20%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.boxoballs3 {
  display: flex;
  flex-flow: row;
  padding-left: 20%;
  padding-right: 20%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.ball1 {
  background-color: #1bccbd;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-animation: ping 0.8s ease-in-out infinite both;
  animation: ping 0.8s ease-in-out infinite both;
}

.ball2 {
  background-color: #2224bd;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-animation: ping 0.8s ease-in-out 0.5s infinite both;
  animation: ping 0.8s ease-in-out infinite 0.5s both;
}

.ball3 {
  background-color: #eaee1b;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-animation: ping 0.8s ease-in-out 1s infinite both;
  animation: ping 0.8s ease-in-out infinite 1s both;
}

.ball4 {
  background-color: #ca1da5;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-animation: ping 0.8s ease-in-out 1.5s infinite both;
  animation: ping 0.8s ease-in-out infinite 1.5s both;
}

.chartcanvas {
  display: block;
  box-sizing: border-box;
  padding: 2px;
  justify-content: center;
  width: 99.9%;
  max-width: 99.9%;
  border-radius: 0px;
  height: 100%;
  background-color: #f1f1f1d3;
  /* border-left: 1px solid rgb(29, 56, 53);
  border-right: 1px solid rgb(29, 56, 53);
  border-top: 0px solid rgb(29, 56, 53); */
  /* position: absolute; */
}


.group-purchase-controller-toggle-switch {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: auto;
  width: auto;
  outline: none;

}

.group-purchase-controller-slider {
  outline: none;
  position: absolute;
  width: 42px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: background-color 0.3s;
}

.group-purchase-controller-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.group-purchase-controller-toggle-switch input[type="checkbox"]:checked+.group-purchase-controller-slider {
  background-color: rgb(29, 56, 53);
}

.group-purchase-controller-toggle-switch input[type="checkbox"]:checked+.group-purchase-controller-slider:before {
  transform: translateX(26px);
  background-color: #ffbd2b;
}

.group-purchase-controller-label-text {
  position: relative;
  padding-left: 40px;
  line-height: 24px;
  color: #555;
}

.group-purchase-selected-purchase-table {

  background-color: #d6d6d6;
  border-radius: 10px;
  width: 100%;
}

.group-purchase-currency {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: white;
  /* position: absolute;
  left: 17%; */
}



.group-purchase-info-header {
  width: 100%;
  margin-top: 0px;
  padding: 20px 5px 5px 5px;
  box-sizing: border-box;
  font-weight: 700;
  color: rgb(34, 34, 34);
  text-align: center;
}

.group-purchase-info-container {
  /* justify-self: right; */
  padding: 2% 10% 0% 5%;
  width: 100%;
  /* max-width: 400px; */
  box-sizing: border-box;
  color: rgb(24, 24, 24);
  font-weight: 600;
  font-size: 0.8rem;
  /* height: 80px; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;

}

.group-purchase-info-container-2 {
  /* justify-self: right; */
  padding: 2% 10% 0% 5%;
  width: 90%;
  max-width: 400px;
  box-sizing: border-box;
  color: rgb(24, 24, 24);
  font-weight: 600;
  font-size: 0.8rem;
  /* height: 80px; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;

}

.group-purchase-controller-container1 {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  /* padding-top: 20px; */
  margin-top: 20px;
  box-sizing: border-box;
}

.group-purchase-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.group-purchase-submit {
  color: rgb(255, 255, 255);
  background: rgb(6, 59, 53);
  outline: none;
  border: none;
  border-radius: 5px;
  font-family: "Quicksand", sans-serif;
  height: auto;
  font-weight: 700;
  margin-top: 0px;
  padding: 15px 20px 15px 20px;
}

.group-purchase-done {
  align-self: center;
  width: 80%;
  color: rgb(19, 19, 19);
  background: #ffbd2b;
  outline: none;
  border: none;
  border-radius: 5px;
  font-family: "Quicksand", sans-serif;
  height: auto;
  font-weight: 700;
  margin-top: 0px;
  padding: 15px 20px 15px 20px;
}

.group-purchase-error {

  color: rgb(248, 50, 50);
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}


.group-purchase-close-container {
  position: absolute;
  top: -20px;
  right: -10px;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 2rem;
  padding: 0px;
  margin: 0px;
}

.group-purchase-amount-indicator {
  padding-right: 5px;
  font-size: 0.6rem;
}

.group-purchase-modify-inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  margin: 0px;
  padding: 15px 15px 15px 15px;
  width: 80%;
}

.group-purchase-percent-container {
  width: 30%;
}





.group-purchase-amount-container {
  width: 30%;
}

.group-purchase-change-amount-cash {
  border-radius: 10px;
  background-color: #dbdbdb;
  color: rgb(36, 36, 36);
  height: 40px;
  width: 25%;

  border: none;
  outline: none;
  font-family: "Baloo 2", cursive;
  font-size: 1.6rem;
  text-align: center;
}

.group-purchase-change-percent {
  border-radius: 10px;
  background-color: #dbdbdb;
  color: rgb(36, 36, 36);
  height: 40px;
  width: 25%;
  border: none;
  outline: none;
  font-family: "Baloo 2", cursive;
  font-size: 1.6rem;
  text-align: center;
}

.group-purchase-percent-container {}

.group-purchase-header {
  width: 100%;
  font-weight: 500;
  font-size: 1.1rem;
  color: rgb(238, 238, 238);
  text-align: center;
  padding: 0px 0px 20px 0px;
  margin: 0px;
}

.regform {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  animation: fade-in 0.5s linear forwards;
}

.regdropdown {
  width: 200px;
  justify-self: center;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #FFFFFF;
  outline: none;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  background-color: #3b3b3b00;
  color: rgb(255, 255, 255);
}

.regbutton {
  width: 200px;
  justify-self: center;
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  background-color: #23547c;
  color: rgb(219, 219, 219);
  animation: fade-in 0.5s linear forwards;
}

.regbuttonoutline {
  width: 200px;
  justify-self: center;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #23547c;
  outline: none;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  background-color: transparent;
  color: #23547c;
  animation: fade-in 0.5s linear forwards;
}

.forgotpassword {
  width: 180px;
  justify-self: center;
  border-radius: 5px;
  border: none;
  outline: none;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  color: #202020;
  background-color: transparent;
  animation: fade-in 0.5s linear forwards;
}

.cancelregbutton {
  width: 180px;
  justify-self: center;
  /* height: 35px; */
  border-radius: 5px;
  border: none;
  margin-top: 40px;
  outline: none;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  background-color: transparent;
  color: rgb(31, 31, 31);
  animation: fade-in 0.5s linear forwards;
}

.supportformcontainer {
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.support-form-container {
  margin-top: 0%;
  /* margin-bottom: 20%; */
  height: 100vh;
  width: 100%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  align-items: center;
  overflow: scroll;
}

.supportmessageemail {
  width: 200px;
  box-sizing: border-box;
  margin: 10px;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  color: rgb(31, 31, 31);
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 10px;
}

.supportmessageemailfake {
  width: 200px;

  margin: 10px;

  box-sizing: border-box;
  /* margin: 10px; */
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  color: rgb(116, 116, 116);
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 10px;
  background-color: rgb(231, 231, 231);
  height: 35px;
  font-size: 0.8rem;
}

.emaillabel {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  color: rgb(31, 31, 31);
  font-size: 0.9rem;
  line-height: 25px;
  font-weight: 600;
  width: 50px;
}

.supportmessageemailcontainer {
  display: flex;
  justify-content: left;
  padding-left: 10%;
  width: 100%;
  box-sizing: border-box;

  box-sizing: border-box;
}

.supportmessageemaildiv2 {
  width: 100%;
  display: flex;
  justify-content: left;

  box-sizing: border-box;

  box-sizing: border-box;
}

.supportmessagesubjectcontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;

  font-family: "Quicksand", sans-serif;
  color: rgb(31, 31, 31);
}

.supportmessagesubject {
  margin: 10px;
  width: 80%;
  height: 45%;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 10px;
}

.supportmessagebodybox {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.supportmessagebody {
  padding: 10px;
  margin: 10px;
  background-color: #ffffff;
  width: 80%;
  height: 160px;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  color: rgb(31, 31, 31);
  border-radius: 5px;
  border: none;
  outline: none;
}

.supportmessagesubmitbutton {
  background-color: #1d3835;
  height: 46px;
  color: white;
  box-sizing: border-box;
  width: 180px;
  margin: 10px;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  outline: none;
  font-family: "Quicksand", sans-serif;
}

.closesupportform {
  background-color: transparent;
  box-sizing: border-box;
  width: 40%;
  margin: 20px 10px 10px 10px;
  color: rgb(27, 27, 27);
  font-weight: 600;
  border: none;
  outline: none;
  font-family: "Quicksand", sans-serif;
}

.supportmessagesubmitbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.supportformheader {
  width: 100%;
  box-sizing: border-box;
  padding: 5px 25px 5px 25px;
  color: rgb(27, 27, 27);
  font-size: 0.9rem;
  font-weight: 500;
  font-family: 'Inter Tight', sans-serif;
}

.supportformheader1 {
  box-sizing: border-box;
  color: rgb(87, 87, 87);
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 20px;
}

.supportformheader2 {
  box-sizing: border-box;
  color: rgb(27, 27, 27);
  font-size: 0.9rem;
  font-weight: 600;
  padding-left: 20px;
}

.support-success-message {
  text-align: center;
  padding-left: 12%;
  padding-right: 12%;
  padding-top: 3%;
  color: #3f523a;
  font-size: 0.8rem;
  font-weight: 600;
}

.support-error-message {
  box-sizing: border-box;
  text-align: center;
  padding-left: 12%;
  padding-right: 12%;
  padding-top: 3%;
  color: rgb(114, 8, 8);
  font-size: 0.8rem;
  font-weight: 600;
}

.emojimodal {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  z-index: 7000;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1px);
  top: 0;
  left: 0;
  position: fixed;
}

.emojimodalcontent {
  background-color: #eeeeee;
  border-radius: 5px;
  height: auto;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  filter: drop-shadow(0 0 0.5rem rgb(31, 31, 31));
}

.emojiheader {
  padding-top: 20px;
  margin: 5px;
  font-size: 0.9rem;
  color: #ececec;
  font-weight: 600;
}

.emojiheader2 {
  padding-top: 0px;
  margin: 5px;
  text-align: center;
  font-size: 0.7rem;
  color: #616161;
  font-weight: 700;
}

.emojibuttons {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.newemojiform {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.newemojisave {
  height: 35px;
  width: 80px;
  background-color: rgb(10, 100, 90);
  border-radius: 5px;
  font-family: "Quicksand", sans-serif;
  font-size: 0.8rem;
  color: #f5f5f5;
  border: none;
  font-weight: 700;
}

.emojimodalclose {
  height: 35px;
  width: 80px;
  border: 1px solid rgb(10, 100, 90);
  border-radius: 5px;
  font-family: "Quicksand", sans-serif;
  font-size: 0.8rem;
  color: rgb(10, 100, 90);
  font-weight: 700;
}

.newemoji {
  width: 80%;
  height: 35px;
  border: 1px solid #c2c2c2;
  outline: none;
  border-radius: 5px;
  margin: 20px 5px 5px 5px;
  text-align: left;
  padding-left: 5px;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  resize: none;
  box-sizing: border-box;
}

.budgettable {
  table-layout: fixed;
  width: 100%;

  max-width: 99.9vw;
  background-color: #f5f5f500;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 0px;
}

.budgettable td:first-child {
  width: 0%;
}

.edit-mode {
  table-layout: fixed;
  z-index: 10;
}

.dontdisplay {
  display: none;
}

.edit-mode .dontdisplay {
  display: block;
}

.editbutton {
  background: transparent;
  /* position: absolute; */
  margin-left: 10px;
  color: rgb(10, 62, 110);
  text-decoration: underline;
  font-family: "Quicksand", sans-serif;
  outline: none;
  border: none;
  float: left;
  font-size: 0.8rem;

}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}

@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}


@-webkit-keyframes puff-out-bottom {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

.transactionanimationcontainer {
  background-color: rgba(240, 0, 0, 0);
  width: 95%;
  height: 150px;
  margin-top: 20%;
  /* border-radius: 5px; */
  position: absolute;
  bottom: 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 30%; */
  box-sizing: border-box;
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2.3s both;
  animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2.3s both;
  /* z-index: 500; */
}

.circlebackground {
  align-self: center;
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 50%;
  background-color: rgba(245, 245, 245, 0.459);
  z-index: 600;
}

.circletext {
  width: 155px;
  height: 155px;
  border-radius: 50%;
  text-align: center;
  padding-top: 32%;
  box-sizing: border-box;
  background-color: rgb(68, 124, 72);
  color: rgb(235, 235, 235);
  font-size: 4rem;
  /* z-index: 1000; */
  -webkit-animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 2s both;
  animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 2s both;
}

.circle1 {
  width: 150px;
  position: absolute;
  -webkit-animation: rotate-center 1.5s ease-in-out 0.2s both;
  animation: rotate-center 1.5s ease-in-out 0.2s both;
}

.circle2 {
  position: absolute;
  width: 110px;
  -webkit-animation: rotate-center 0.6s ease-in-out 0.6s 2 both;
  animation: rotate-center 0.6s ease-in-out 0.6s 2 both;
}

.circle3 {
  position: absolute;
  width: 70px;
  -webkit-animation: rotate-center 1.2s ease-in-out 0.4s 1 both;
  animation: rotate-center 1.2s ease-in-out 0.4s 1 both;
}

.changedatebutton {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: #f5f5f5;
  font-size: 0.7rem;
  font-family: "Quicksand", sans-serif;
  border: none;
  outline: none;
  background-color: transparent;
}

.editbutton-edit-mode {
  animation: fade-in 0.5s linear forwards;
  border-radius: 5px;
  text-decoration: none;
  width: 100px;
  font-weight: 600;
  background-color: #ffbd2b;
  color: rgb(27, 27, 27);
  position: absolute;
  right: -50px;
  top: -18px;
  white-space: nowrap;
}

.editbutton2-edit-mode {
  animation: fade-in 0.5s linear forwards;
  border-radius: 5px;
  width: 50%;
  box-sizing: border-box;
  margin: 2% 25% 0% 25%;
  background-color: #ffbd2b;
  text-decoration: none;
  font-weight: 700;
  color: rgb(27, 27, 27);
}

form {
  width: 100%;
}

.mrlogo2 {
  width: 80%;
  max-width: 180px;
  position: absolute;
  top: -50px;
}

.betalogo2 {
  width: 60%;
  position: absolute;
  top: 60px;
  left: 60%;
}

.mrlogo {
  width: 40%;
}

.betalogo {
  width: 8%;
  position: absolute;
  top: 40px;
  left: 30%;
}

.logincontainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-align: center;
  /* Vendor prefix for Safari */
  justify-content: center;
  -webkit-box-pack: center;
  /* Vendor prefix for Safari */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background-color: rgb(238, 238, 238); */
  z-index: 1000;
}

.test {
  color: rgb(50, 92, 42);
  color: rgb(60, 92, 42);
  color: rgb(70, 92, 42);
  color: rgb(80, 92, 42);
  color: rgb(90, 92, 42);
  color: rgb(100, 92, 42);
  color: rgb(110, 92, 42);
  color: rgb(120, 92, 42);
  color: rgb(130, 92, 42);
  color: rgb(140, 92, 42);
  color: rgb(150, 80, 42);
  color: rgb(160, 70, 42);
  color: rgb(170, 60, 42);
  color: rgb(180, 40, 42);
  color: rgb(190, 30, 42);
}

.logincontainer2 {
  position: relative;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* Vendor prefix for Safari */
  align-items: center;
  -webkit-box-align: center;
  /* Vendor prefix for Safari */
  width: 50%;
  height: 20%;
  margin-top: 40px;
  animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
}

.logincontainer3 {
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  /* Vendor prefix for Safari */
  -webkit-box-align: center;
  /* Vendor prefix for Safari */
  align-items: center;
  -webkit-box-pack: center;
  /* Vendor prefix for Safari */
  justify-content: space-evenly;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* Vendor prefix for Safari */
  width: 100%;
  height: 400px;
  max-height: 480px;
  position: absolute;
  top: -100px;
}

.logincontainer4 {
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  /* Vendor prefix for Safari */
  -webkit-box-align: center;
  /* Vendor prefix for Safari */
  align-items: center;
  -webkit-box-pack: center;
  /* Vendor prefix for Safari */
  justify-content: space-evenly;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* Vendor prefix for Safari */
  width: 100%;
  height: 242px;
  max-height: -webkit-fit-content;
  /* Vendor prefix for Safari */
  max-height: fit-content;
  position: absolute;
  top: -100px;
}

.logincontainer7 {
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  /* Vendor prefix for Safari */
  -webkit-box-align: center;
  /* Vendor prefix for Safari */
  align-items: center;
  -webkit-box-pack: center;
  /* Vendor prefix for Safari */
  justify-content: space-evenly;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /* Vendor prefix for Safari */
  width: 100%;
  height: 540px;
  max-height: 540px;
  position: absolute;
  top: -200px;
}


.newuserregistrationheader {
  font-weight: 700;
  font-size: 1.1rem;
  color: rgb(8, 29, 27);
  text-align: center;
}

.termsandconditions {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 1em;
  /* padding-top: 1100px; */
  box-sizing: border-box;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  overflow: scroll;
  box-sizing: border-box;
  z-index: 5000;
  padding-bottom: 140px;
  text-align: left;
}

.tncbody {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 400;
  color: rgb(27, 27, 27);
  font-family: 'Inter Tight', sans-serif;

}

.tncsubheader {
  font-size: 1rem;
  font-weight: 600;
  color: rgb(20, 20, 20);
  font-family: 'Inter Tight', sans-serif;
}

.tncheader {
  font-size: 1.3rem;
  color: rgb(7, 7, 7);
  font-family: 'Inter Tight', sans-serif;
}

.tncclose {
  padding: 0.5em 1em;
  background-color: #111111;
  color: #f3f3f3;
  border: none;
  border-radius: 0.25em;
  font-size: 1em;
  cursor: pointer;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  width: 150px;
  position: fixed;
  bottom: 60px;
  z-index: 6000;
}

.tnclink {
  text-decoration: underline;
  color: rgb(10, 62, 110);
  margin-left: 5px;
  font-size: 0.7rem;
}

.pplink {
  position: fixed;
  bottom: 15px;
  text-decoration: underline;
  color: rgb(10, 62, 110);
  margin-left: 5px;
  font-size: 0.7rem;
  animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
}

.termsstatement {
  font-size: 0.7rem;
  white-space: nowrap;
  display: flex;
  width: 100%;
  flex-flow: row;
  flex-wrap: nowrap;
  margin-left: 0px;
  justify-content: center;
  align-items: center;
  color: rgb(19, 19, 19);
  padding-bottom: 20px;
}

.logincont4 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  width: 130%;
}

.logincont {
  display: flex;
  -webkit-box-align: center;
  /* Vendor prefix for Safari */
  align-items: center;
  -webkit-box-pack: center;
  /* Vendor prefix for Safari */
  justify-content: center;
  width: 100%;
}

.logincont2 {
  display: flex;
  -webkit-box-align: center;
  /* Vendor prefix for Safari */
  align-items: center;
  -webkit-box-pack: center;
  /* Vendor prefix for Safari */
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
}

.loginemail {
  width: 215px;
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 0.7rem;
  font-weight: 700;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  /* -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both;
  animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s both; */
}

.inputpw-container {
  width: 216px;
  padding-left: 19px;
  box-sizing: border-box;
  border-radius: 5px 5px 5px 5px;
  display: flex;
  /* or use "display: grid;" for grid layout */
  align-items: center;
  background-color: white;
  /* -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.6s both;
  animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.6s both; */
}



.loginpassword {
  width: 90%;
  height: 35px;

  border: none;
  outline: none;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 700;
  background-color: transparent;
  font-family: "Quicksand", sans-serif;

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.hideorshowpassword2 {
  background-color: transparent;
  width: 10%;
  height: 35px;
  border-radius: 0px 5px 5px 0px;
  outline: none;
  padding: 1px 5px 1px 0px;
  border: none;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  color: rgb(145, 145, 145);

}

.spacer {
  width: 200px;
  height: 35px;
  background-color: transparent;
}

.datepickerproper {
  width: 100%;
  height: 40px;
  font-size: 1.1rem;
  background-color: #f3f3f3;
  color: rgb(27, 27, 27);
  text-align: center;
  border: none;
  font-weight: 400;
  font-family: "Quicksand", sans-serif;
  border-radius: 0px;
  border: none;
  outline: none;
}

/* .datepicker-slide-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.datepicker-slide-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: all 1000ms ease-in-out;
}
.datepicker-slide-exit {
  opacity: 1;
  transform: translateY(0%);
}
.datepicker-slide-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: all 1000ms ease-in-out;
} */

/* .masterinputbox {
    max-width: 480px;
} */

.diffdatebutton {
  left: 0;

  border: none;
  outline: none;
  width: 100%;
  height: 25px;
  border-radius: 10px 10px 0px 0px;
  background-color: #0c0c0c;
  padding-left: 5px;
  color: rgb(206, 206, 206);
  box-sizing: border-box;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: "Quicksand", sans-serif;
  line-height: 0.9rem;
}

.optionscomponentcontainer {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 160px;
  height: 220px;
  background-color: #07770300;
}

.optionscomponentcontainer2 {
  position: absolute;
  top: -35px;
  right: 5px;
  width: 160px;
  height: 220px;
  background-color: #07770300;
}

.optionsmenucontainer {
  position: relative;
  padding: 2px;
  box-sizing: border-box;
  width: 100%;
  height: 220px;
  background-color: #1976d200;
  z-index: 800;

}

.closeoptionsmenucontainer {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30px;
  height: 20px;
  background-color: rgba(212, 212, 212, 0);
}

.closeoptionsmenubutton {
  width: 100%;
  height: 100%;
  padding: 2%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
}

.optionsoptions {
  margin-top: 23px;
  -webkit-animation: scale-in-ver-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-ver-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.optionselections {
  background-color: rgb(241, 241, 241);
  border: 1px solid rgb(192, 192, 192);
  border-radius: 5px 0px 5px 5px;
}

.menuoption {
  text-align: right;
  padding-top: 12px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 0.8rem;
  width: 100%;
  height: 40px;
  color: rgb(27, 27, 27);
  border-radius: 0px 5px 5px 0px;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  outline: none;
  border: none;
}

.menuoptiondelete {
  text-align: right;
  margin-top: 20px;
  padding-top: 12px;
  padding-right: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  font-size: 0.8rem;
  width: 100%;
  height: 40px;
  color: #852626;
  border-radius: 0px 5px 5px 0px;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  outline: none;
  border: none;
}

.menuoptionlast {
  text-align: right;
  padding-top: 12px;
  padding-right: 10px;
  padding-bottom: 12px;
  box-sizing: border-box;
  font-size: 0.8rem;
  width: 100%;
  height: 40px;
  color: rgb(27, 27, 27);
  border-radius: 0px 5px 5px 0px;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  outline: none;
  border: none;
}

.menuoption2 {
  text-align: right;
  padding-top: 12px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 0.8rem;
  width: 100%;
  height: 40px;
  color: rgb(189, 189, 189);
  border-radius: 0px 5px 5px 0px;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  outline: none;
  border: none;
}

.loginbutton {
  width: 216px;
  justify-self: center;
  height: 35px;
  border-radius: 5px;
  border: none;
  outline: none;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  background-color: rgb(6, 59, 53);
  color: rgb(219, 219, 219);
  /* -webkit-animation: scale-in-hor-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.4s both;
  animation: scale-in-hor-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.4s both; */
}

.logoutbutton {
  background: transparent;
  font-size: 20px;
  float: right;
  position: absolute;
  right: 8px;
  top: 109px;
  color: #111111;
  font-family: "Quicksand", sans-serif;
  outline: none;
  border: none;
  font-size: 1.4rem;
}

.optionsbutton {
  background: transparent;
  font-size: 20px;
  float: right;
  position: absolute;
  right: 8px;
  top: 12px;
  color: #111111;
  font-family: "Quicksand", sans-serif;
  outline: none;
  border: none;
  font-size: 1.1rem;
}

.loginstatus {
  text-align: center;
  height: 70px;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  color: rgb(20, 20, 20);
}

.loginstatus2 {
  text-align: center;
  height: 100px;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  color: rgb(219, 219, 219);
}

.newcategoryform {
  display: flex;
  flex-wrap: nowrap;
  flex-flow: row;
  width: 100%;
  background-color: #ac646400;
}

.canceldeletecategory {
  background-color: transparent;
  border: 1px solid #852626;
  outline: none;
  font-size: 0.7rem;
  color: #852626;
  font-family: "Quicksand", sans-serif;
  line-height: 15px;
  border-radius: 5px;
  margin-top: 5px;
  font-weight: 500;
}

.categorynameinput {
  height: 25px;
  margin: 2px;
  width: 48%;
  padding: 5px;
  font-family: "Quicksand", sans-serif;
  font-size: 0.8rem;
  border: none;
  border-radius: 5px;
}

.emojimodalcontainer {
  display: absolute;
  position: fixed;
  width: auto;
  top: 0px;
  left: 0px;
  /* display: flex; */
  align-items: top;
  justify-content: center;
  z-index: 9999;
  overflow: auto;
  margin: 20% 0px 20% 0px;
  box-sizing: border-box;
  padding: 0px 0px 0px 0px;
  border-radius: 10px;
  height: 300px;
  background-color: rgb(255, 255, 255);
}

.loader-container {
  /* Position the loader container */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  display: flex;
  align-items: center;
}

.spinner-icon {
  animation: spin 1s linear infinite;
  margin-right: 0.5rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.alreadyaddedtogroup {
  color: #852626;
  font-weight: 700;
  padding: 0px;
  margin: 0px;
  height: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  /* justify-content: space-between; */
  font-size: 0.7rem;
}

.notaddedtogroup {
  color: rgb(5, 44, 40);
  font-weight: 700;
  padding: 0px;
  margin: 0px;
  height: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  /* justify-content: space-between; */
  font-size: 0.7rem;
}


.emoji-modal-content {
  line-height: 1rem;
  background-color: transparent;
  border-radius: 5px;
  text-align: center;
  width: 100%;
}

.emoji-modal-list-header {
  font-size: 0.9rem;
  color: #111111;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  background: #e2e2e2;
  /* add a background color to ensure the text doesn't overlap with the other elements when stuck at the top */
  z-index: 2;
  /* This could be any high number to ensure the header stays above all other elements */
}

.emoji-modal-list-header2 {
  font-size: 0.9rem;
  color: #111111;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  background: #ffffff;
  /* add a background color to ensure the text doesn't overlap with the other elements when stuck at the top */
  z-index: 2;
  /* This could be any high number to ensure the header stays above all other elements */
}

.emoji-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 35px);
  grid-gap: 5px;
  justify-content: center;
  margin-bottom: 20px;
  padding: 2px;
}

.emoji-list2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, 35px);
  grid-gap: 5px;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0px;
}

.emoji-item {
  font-size: 1.4rem;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  margin: 0px;
  width: 35px;
  height: 35px;
  display: flex;
  padding: 0;
  align-items: center;
  -webkit-box-pack: center;
  /* Horizontal alignment for Safari */
  justify-content: center;
  box-sizing: border-box;
}

.emojimodal-close-button {
  position: sticky;
  bottom: 10px;
  border: none;
  outline: none;
  background-color: #c9c9c9;
  color: #030303;
  box-sizing: border-box;
  line-height: 1rem;
  padding: 10px;
  width: 100px;
  border-radius: 5px;
  font-family: "Quicksand", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  z-index: 5;
}

.categoryamountinput {
  text-align: center;
  align-self: center;
  height: 25px;
  width: 50px;
  margin: 2px;
  padding: 5px;
  font-family: "Baloo 2", cursive;
  font-size: 0.9rem;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 5px;
}

.addcategorybutton {
  height: 35px;
  width: 50px;
  background-color: rgb(10, 100, 90);
  border-radius: 5px;
  font-family: "Quicksand", sans-serif;
  font-size: 0.8rem;
  color: #f5f5f5;
  border: none;
  font-weight: 700;
  margin-right: 10%;
  justify-content: right;
}

.categoryformcontainer {
  display: flex;
  width: 100%;
  padding: 0;
}

.currencytype {
  width: 10%;
  /* height: 100%; */
  margin-right: 2px;
  font-size: 2rem;
  font-family: "Baloo 2", cursive;
  background-color: transparent;
  color: rgb(185, 185, 185);
  outline: none;
  border: none;
  padding: none;
}

.formbuttoncontainer {
  width: 28%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.deletebuttoncontainer {
  box-sizing: border-box;
}

.deletecategorybutton {
  background: transparent;
  width: 80px;
  color: #852626;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  outline: none;
  border: none;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 25px;
  box-sizing: border-box;
}

.deletecategorybutton2 {
  background: transparent;
  width: 80px;
  line-height: 1.1rem;
  background-color: #852626;
  color: white;
  box-sizing: border-box;

  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  outline: none;
  border: none;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 5px;
}

.deletecategorybutton3 {
  background: transparent;
  line-height: 1.1rem;
  width: 100%;
  color: #444444;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  outline: none;
  border: none;
  font-size: 0.8rem;
  text-align: right;
  padding-right: 15px;
  border-radius: 25px;
}

.deletecategoryconfirmbutton {
  background: transparent;
  width: 100%;
  box-sizing: border-box;
  color: #852626;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  outline: none;
  border: none;
  font-size: 0.8rem;
  text-align: right;
  padding-right: 15px;
  line-height: 1.4rem;
  border-radius: 25px;
  /* -webkit-animation: color-change-2x 2s linear infinite alternate both;
  animation: color-change-2x 2s linear infinite alternate both; */
}

.chartcontainer2 {
  /* display: flex; */
  flex-wrap: wrap;
  justify-content: center;
  /* margin-top: -20px; */
  height: 240px;
  margin-bottom: 20px;
}

.chartcontainer3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  position: relative;
  justify-content: space-evenly;
  background-color: #ececec;
  height: 268px;
}

.chartntable {
  display: block;
  flex-flow: column;
  width: 100%;
  margin-top: -20px;
  justify-content: start;
  position: relative;
  padding: 0px;
  padding-top: -20px;
  box-sizing: border-box;
  border-radius: 0px 5px 5px 5px;
}

::-webkit-scrollbar {
  display: none;
}

.willitscroll {
  overflow: hidden;
  margin-top: 5px;
}

.scrollercontainer {
  width: 100%;
  z-index: 5;
  margin-top: 6px;
  justify-content: start;
}

.scrollabletable {
  display: table;
  position: relative;
  justify-content: start;
  overflow: scroll;
  border-radius: 10px;
}

.purchase-list {
  height: auto;
  padding: 0;
  margin-bottom: 20px;
}

.budgets-container,
.purchases-container {
  height: 100vh;
  overflow: scroll;
}

table {
  position: inherit;
  width: 100%;
  border-collapse: separate;
  border: 0px solid transparent;
  border-spacing: 0;
  /* position: sticky; */
  top: 0;
  z-index: 10;
}

.form-control+.form-control {
  margin-top: 1em;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}

input[type="checkbox"]:not(.exception) {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1em;
  height: 1em;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]:not(.exception):checked {
  background-color: #ffffff00;
  /* Hide the checkbox itself when it is checked */
}

input[type="checkbox"]:not(.exception)::before {
  content: "";
  width: 0.85em;
  height: 0.85em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:not(.exception)::before {
  content: "";
  width: 0.85em;
  height: 0.85em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:not(.exception):checked::before {
  transform: scale(1);
  background-color: #267732;
}

.purchasedates {
  background-color: #fcfcfc8f;
  height: 10px;
  text-align: center;
  padding: 3px 0px 3px 0px;
  font-size: 0.7rem;
}

.purchasedatedate {
  color: rgb(104, 104, 104);
}

.purchasedateamount {
  color: rgb(145, 145, 145);
}

.remembermelabel {
  font-size: 0.7rem;
  color: rgb(19, 19, 19);
  font-weight: 600;
  margin-left: 5px;
}

.remembermelabel2 {
  font-size: 0.8rem;
  padding: 5px;
}

.iconbutton {
  border: none;
  padding: 0px;
  margin: 0px;
  background-color: rgba(228, 228, 228, 0.637);
  outline: none;
  height: 40px;
  width: 40px;
  font-size: 1.3rem;
  border-radius: 50%;
  position: relative;
}

.worthiticon {
  width: 5px;
  position: absolute;
  bottom: 2px;
  right: 5px;
  font-size: 0.7rem;
}

.onboardinstruction {
  width: 100%;
  display: inline-block;
  flex-direction: row;
  align-items: center;
  color: black;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-size: 0.9rem;
  border: none;
  border-radius: 5px;
}

.onboardinstructionheader {
  text-decoration: underline;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  font-weight: 700;
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  margin: 0px;
  text-align: right;
  font-size: 1rem;
}

.onboardinstructionheader2 {

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  font-weight: 700;
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  margin: 0px;
  text-align: right;
  font-size: 2rem;
}



.onboardinstructionbody {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  font-weight: 400;
  box-sizing: border-box;
  padding: 0px 0px 20px 0px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 0.8rem;
}

.tinderaddtogroup {
  background-color: transparent;
  box-sizing: border-box;
  line-height: 0.9rem;
  font-size: 0.8rem;
  font-family: "Quicksand", sans-serif;
  text-align: center;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 5px;
  padding-left: 5px;
}

.tinder-close-profile-nav {
  color: rgb(235, 235, 235);
  text-decoration: none;
  background-color: rgb(5, 44, 40);
  padding: 5px;
  border-radius: 5px;
  ;
}

.addtogroupcontainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.tindercontainer {
  position: absolute;
  z-index: 500;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 200px;
  box-sizing: border-box;
  text-align: center;
}


.closetinderbutton {
  width: 100%;
  margin-top: 50px;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  height: 25px;
  font-size: 0.8rem;
}

.closetinderbutton2 {
  width: 100%;
  margin-top: 50px;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  height: 25px;
  font-size: 0.8rem;
}

.tinderheader {
  color: black;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10% 20px 0px 20px;
  margin: 0px;
}

.tinderfooter {
  position: fixed;
  bottom: 55px;
}

.tinderswipeexperimental {
  width: 30%;
  font-size: 0.7rem;
  padding: 0;
  font-weight: 600;
  margin: 0;
  color: #030202;
}

.tinderswipeleft {
  height: 75px;
  width: 30%;
  font-size: 0.7rem;
  padding: 0;
  margin: 0;
  color: #852626;
}

.tinderswiperight {
  height: 75px;
  width: 30%;
  font-size: 0.7rem;
  padding: 0;
  margin: 0;
  color: rgb(10, 100, 90);
}

.tinderheader2 {
  position: fixed;
  bottom: 10%;
  color: rgb(141, 35, 35);
  font-weight: 600;
  /* font-style: italic; */
  font-size: 0.6rem;
}


.swipe-text-container {
  position: fixed;
  z-index: 2000;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* .swipe-text {
  padding: 10px 20px;
  border-radius: 50px;
  color: black;
  padding: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
} */

.tindernounreviewed {
  margin-top: 60px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: rgb(5, 44, 40);
  font-size: 1rem;
  font-weight: 600;
}

.hidereviewed {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 0.9em;
  font-family: "Quicksand", sans-serif;
  color: rgb(10, 62, 110);
  text-decoration: underline;
  font-weight: 500;

}

.tinderreviewemptystate {
  color: black;
  font-weight: 600;
  padding-top: 50%;
}


.tinder-like-review {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.tinder-card {
  position: absolute;
  box-sizing: border-box;
  /* margin: 10%;
  padding: 10%; */
  top: -50px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  height: 380px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  margin: auto;
}

.tinder-card-content {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px;
  text-align: center;

}

.tinder-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: rgb(26, 26, 26);
}

.tinder-card-price {
  font-size: 2rem;
  color: #2b2b2b;
  margin: 0;
  font-family: "Baloo 2", cursive;
}

.tinder-card-icon {
  font-size: 3rem;
  color: #555;
  position: relative;
}

.tinder-card-note {
  font-size: 0.9rem;
  color: #555;
}

.tinder-card-worthit {
  width: 100%;
  font-size: 16px;
  color: #555;
  position: absolute;
  box-sizing: border-box;
  padding-left: 35px;
  margin-bottom: 0px;
  bottom: 0;
  text-align: center;
}

.worthittrue {
  color: rgb(10, 100, 90);
}

.worthitfalse {
  color: #852626;
}

.worthitfalse2 {
  width: 100%;
  color: #0c0c0c;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 700;
  background-color: white;
  border: 2px solid rgb(22, 22, 22);
  border-radius: 25px;
  padding: 5px;
}

.closetinderbutton {
  margin-top: 20px;
}

.empty-state-text {
  color: black;
  margin-top: 40%;
}

.reviewed-purchases-header {
  color: rgb(73, 73, 73);
}

.tindergroupselect {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 45px;
  color: rgb(17, 17, 17);
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-size: 0.9rem;
  border-radius: 5px 5px 0px 0px;

}

.notificationdot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-animation: color-change-2x 2s linear infinite alternate both;
  animation: color-change-2x 2s linear infinite alternate both;
  position: absolute;
  top: 13px;
  right: 30px;
  opacity: 100%;
}

.empty-state-review {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10%;
  box-sizing: border-box;
}

.empty-state-review-header {
  font-size: 1.4rem;
  font-weight: 600;
}

.empty-state-review-message {
  color: rgb(17, 17, 17);
  font-size: 0.9rem;
  text-align: center;
}

th,
td {
  /* text-align: left; */
  padding: 10px;
  border-bottom: 1px solid rgba(231, 231, 231, 0.534);
  /* font-size: .8rem; */
}

th {
  color: #0c0c0c;
  /* position: sticky; */
  top: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 10;
  font-size: 0.8rem;
  background-color: #ffffff;
}

td:last-child {
  text-align: right;
}

tbody tr:nth-child(even) {
  border-bottom: 1px solid #d8d8d8e7;
  background-color: #ffffffe7;
  height: 15px;
  /* border-top: 1px solid rgb(228, 228, 228); */
}

tbody tr:nth-child(odd) {
  background-color: #ffffffe7;
  height: 15px;
  /* border-top: 1px solid rgb(241, 241, 241); */
}

tfoot {
  background-color: #f7f7f7fd;
}

.howtocategorycontainer {
  color: rgb(19, 19, 19);
  justify-self: left;
  width: 400%;
  height: 65px;
  box-sizing: border-box;
  padding: 5% 80% 5% 10%;
  font-size: 0.7rem;
  border-bottom: 1px solid rgb(184, 184, 184);
  border-left: 3px solid #ffbd2b;
  background-color: #f7f7f7;
}

.categoryandnotecontainer {
  padding-left: 5px;
}

.deletecategoryalerticon {
  text-align: right;
  color: #852626;
  font-size: 1.2rem;
  position: absolute;
  bottom: 90px;
  right: 28px;
}

.deletecategorymessagecontainer {
  width: 400%;
  color: #852626;
  box-sizing: border-box;
  padding-right: 60px;
  text-align: right;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.4rem;
}

.deletecategoryalertmessage {
  font-size: 0.7rem;
  color: #852626;
  font-weight: 600;
}

.purchases-container table {
  margin-top: -20px;
  /* position: sticky; */
  top: 0;
  /* background-color: #f1f1f1e7; */
  overflow: scroll;
}

.remainingbudgettext {
  font-weight: 700;
  color: rgb(43, 92, 41);
  font-size: 1em;
  font-family: "Baloo 2", cursive;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tablenumber {
  font-size: 0.9em;
  font-family: "Baloo 2", cursive;
  color: #4e4e4e;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tablenumber9 {
  font-size: 0.9em;
  font-family: "Quicksand", sans-serif;
  color: #4e4e4e;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tablenumber7 {
  padding: 0px 5px 0px 0px;
  font-size: 0.9em;
  font-family: "Quicksand", sans-serif;
  color: #5f5f5f;
  max-width: 50px;
}

.tablenumber2 {
  padding: 0px 5px 0px 0px;
  font-size: 0.9em;
  font-family: "Baloo 2", cursive;
  color: #5f5f5f;
  max-width: 50px;
}

.tablenumber5 {
  font-size: 0.9em;
  font-family: "Baloo 2", cursive;
  color: #2b2b2b;
}

.tablenumber6 {
  font-size: 0.9em;
  font-family: "Baloo 2", cursive;
  color: #2b2b2b;
}

.tablenumber8 {
  font-size: 0.9em;
  font-family: "Quicksand", sans-serif;
  color: #5f5f5f;
  /* overflow: hidden; */
  white-space: wrap;
  text-overflow: ellipsis;
}

.dateandamountcontainer {
  font-size: 0.8rem;
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.amountcontainer {
  color: rgb(54, 54, 54);
  font-weight: 700;
}

.negativeamountcontainer {
  color: rgb(17, 80, 20);
  font-weight: 700;
}

.tableheader {
  font-size: 0.85em;
  font-family: "Quicksand", sans-serif;
  color: #161616;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tableheaderbb {
  font-size: 0.85rem;
  font-family: "Quicksand", sans-serif;
  color: #161616;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tableheadercc {
  width: 100%;
  /* max-width: 200px; */
  font-size: 0.8rem;
  font-family: "Quicksand", sans-serif;
  color: #161616;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sidecar {
  background-color: #2b2b2b;
  opacity: 100%;
  width: 4px;
  min-width: 4px;
  height: 30px;
  border-radius: 2px;
  margin-right: 5px;
}

.sidecarcontainer {
  display: flex;
  flex-direction: row;
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.budgetlogo {
  width: 100%;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade-in 0.5s linear forwards;

}



.budgetlogoimg {
  /* position: absolute; */
  width: 30px;
  border-radius: 5px;
  margin-bottom: 100px;
  opacity: 50%;
  transform: rotate(270deg);
  z-index: -200;
}

.expandspan {
  float: right;
}

@media screen and (max-width: 9000px) {
  /* h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  } */

  button.add-purchase-btn {
    margin-left: 5px;
    max-width: 160px;
  }

  .reset-btn {
    font-size: 0.8rem;
  }

  .budgets-container,
  .purchases-container {
    overflow: scroll;
  }

  .lowbudget {
    color: rgb(165, 154, 56);
  }

  .negativebudget {
    color: rgb(165, 56, 56);
  }

  .deletepurchase {
    color: rgb(216, 216, 216);
    text-align: center;
    background: transparent;
    outline: none;
    border: none;
    font-size: 0.7rem;
    font-family: "Quicksand", sans-serif;
    height: 10px;
    font-weight: 700;
    width: 10px;
    margin-right: 0px;
    padding-right: 30px;
  }

  .lilemoji {
    background-color: transparent;
    /* border-radius: 5px; */
    padding: 0px;
    width: 100%;
    border: none;
    outline: none;
    font-family: "Quicksand", sans-serif;
    font-size: 0.7rem;
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    color: rgb(48, 48, 48);
  }

  .confirmation-message {
    text-align: center;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(1px);
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    color: rgb(26, 26, 26);
    font-family: "Quicksand", sans-serif;
    font-size: 1rem;
    font-weight: 700;
    position: fixed;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    left: 0;
    bottom: 0;
    margin: 0;
    z-index: 9999;
    animation: fade-in 0.1s linear forwards;
  }

  .confirmation-okay {
    color: rgb(255, 255, 255);
    background: rgb(6, 59, 53);
    outline: none;
    border: none;
    border-radius: 5px;
    font-family: "Quicksand", sans-serif;
    height: auto;
    font-weight: 700;
    margin-top: 20px;
    padding: 15px 20px 15px 20px;
  }

  .confirmation-okay-dark {
    width: 80%;
    color: rgb(17, 17, 17);
    background: #ffbd2b;
    outline: none;
    border: none;
    border-radius: 5px;
    font-family: "Quicksand", sans-serif;
    height: auto;
    font-weight: 700;
    margin-top: 20px;
    padding: 15px 20px 15px 20px;
    margin-bottom: 60px;
  }


  .confirmation-no-dark {
    color: rgb(212, 212, 212);
    background: transparent;
    outline: none;
    border: none;
    font-family: "Quicksand", sans-serif;
    height: auto;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 15px;
    position: absolute;
    right: 0;
    top: 0;

  }

  .confirmation-no {
    color: rgb(19, 19, 19);
    background: transparent;
    outline: none;
    border: none;
    font-family: "Quicksand", sans-serif;
    height: auto;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .yesno {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
  }

  .archivedeleteallbutton {
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    outline: none;
    border: none;
    font-size: 0.7rem;
    background-color: #852626;
    border-radius: 5px;
    color: white;
  }

  @-webkit-keyframes color-change-2x {
    0% {
      background: #852626;
    }

    100% {
      background: #cc1c1c;
    }
  }

  @keyframes color-change-2x {
    0% {
      background: #852626;
    }

    100% {
      background: #cc1c1c;
    }
  }

  .archivedpurchasesheader {
    position: absolute;
    top: 75px;
    left: 10px;
    color: rgb(27, 27, 27);
    font-weight: 700;
    font-size: 0.9rem;
  }

  .archivedeletebutton {
    background: transparent;
    text-align: center;
    width: 60px;
    color: #852626;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    outline: none;
    border: none;
    font-size: 0.7rem;
    text-align: right;
    padding-right: 15px;
    border-radius: 5px;
  }

  .archivedeletebuttonconfirm {
    text-align: center;

    width: 60px;
    color: #ececec;
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    outline: none;
    border: none;
    font-size: 0.7rem;
    text-align: center;

    border-radius: 5px;
    -webkit-animation: color-change-2x 2s linear infinite alternate both;
    animation: color-change-2x 2s linear infinite alternate both;
  }

  .editarchivecategory {
    width: 80%;
    border: none;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 25px;
    text-align: left;
    border-radius: 5px;
    padding-left: 2px;
    box-sizing: border-box;
  }

  .savecategoryeditbutton {
    width: 20%;
    background-color: transparent;
    border: none;
    outline: none;
  }

  .modal p {
    margin: 0 0 1rem;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-flow: column;
    margin-top: 20px;
  }

  .emptychartstate {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 0.7rem;
    color: rgb(19, 19, 19);
    font-weight: 500;
    animation: fade-in 0.6s linear forwards;
  }
}

.password-validation {
  display: flex;
  flex-direction: column;
  height: 70px;
  width: 200px;
  font-size: 0.7rem;
}

.password-validation .valid {
  color: rgb(10, 100, 90);
}

.password-validation .invalid {
  color: #852626;
}


.cookieconsentcontainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  backdrop-filter: grayscale(1);
  color: black;
  z-index: 6000;
  box-sizing: border-box;
  padding: 10%;
  margin: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.cookie-consent-modal {
  width: 80%;
  height: 240px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  text-align: center;
  padding: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  -webkit-animation: scale-in-ver-center 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
  animation: scale-in-ver-center 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
}

.cookiebutton {
  width: 70%;
  height: 40px;
  border-radius: 10px;
  background-color: #ffbd2b;
  font-family: "Quicksand", sans-serif;
  text-align: center;
  padding: 10px;
  outline: none;
  border: none;
  font-weight: 600;
}

.cookiebutton2 {
  width: 70%;
  height: 40px;
  border-radius: 10px;
  background-color: white;
  font-family: "Quicksand", sans-serif;
  text-align: center;
  padding: 10px;
  outline: none;
  border: none;
}



@media screen and (max-width: 700px) {
  .component-indicator4 {
    display: none;
  }
}

@media screen and (max-width: 360px) and (max-height: 680px) {
  .show-tutorial-container {
    display: none;
  }
}



@media screen and (max-width: 400px) {
  .dollarinput {
    padding: 1px;
  }

  .add-purchase {
    font-size: 0.8rem;
  }

  .dropdown {
    font-size: 0.8rem;
    background-color: transparent;
  }
}

@media screen and (max-width: 321px) {
  .dollarinput::placeholder {
    padding-top: 2px;
  }

  /* .dropdown {
  font-size: 0.8rem;
  padding: 1px;
  max-width: min-content;
  margin-right: 5px;
} */

  .add-purchase {
    font-size: 0.6rem;
    padding: 1px;
    max-width: min-content;
  }
}

@media screen and (min-width: 420px) {

  .editbutton-edit-mode {
    top: -20px;
  }
}

@media screen and (min-width: 821px) {

  .tindercontainer {
    width: 100%;
    padding: 0% 20% 0% 20%;
    box-sizing: border-box;
  }

  .tinder-card {
    width: 50%;
  }




  .budgetpagecontainer {
    margin-top: 10px;
    padding: 1% 25% 1% 25%;
    box-sizing: border-box;
    margin-bottom: 600px;
    width: 100%;
  }

  .purchasespagecontainer {
    margin-top: 10px;
    padding: 1% 25% 1% 25%;
    box-sizing: border-box;
    margin-bottom: 160px;
    width: 100%;
  }

  .achievementspagecontainer {
    margin-top: 10px;
    padding: 1% 25% 1% 25%;
    box-sizing: border-box;
    margin-bottom: 160px;
    width: 100%;
  }

  .archivedpurchases-container {
    padding: 5% 25% 10% 25%;
  }

  .editbutton-edit-mode {
    top: -20px;
  }
}

@media screen and (min-width: 1200px) {
  .editbutton-edit-mode {
    top: -20px;
    right: -100px;
  }

  .howtocategorycontainer {

    height: 75px;

  }

  .tinder-card {
    width: 30%;
  }
}

@media screen and (min-width: 1400px) {
  .editbutton-edit-mode {
    top: -20px;
    right: -140px;
  }
}

@media screen and (max-width: 440px) {
  .tableheadercc {
    max-width: 200px;
  }

  .lilemoji {
    max-width: 280px;
  }
}

@media screen and (max-width: 420px) {}

@media screen and (max-width: 400px) {}



.lilemoji {
  max-width: 200px;
}



@media screen and (max-width: 380px) {
  .tableheadercc {
    max-width: 160px;
  }

  .component-indicator-container {
    max-width: 200px;
  }

  .component-indicator-container-tutorial {
    max-width: 200px;
  }

  .component-indicator3 {
    right: 40px;
  }

  .component-indicator5 {
    right: 90px;
  }


  .lilemoji {
    max-width: 180px;
  }
}

@media screen and (max-width: 360px) {
  .component-indicator3 {
    right: 35px;
  }

  .component-indicator5 {
    right: 80px;
  }

}

@media screen and (max-width: 350px) {
  .tableheadercc {
    max-width: 180px;
  }

  .lilemoji {
    max-width: 160px;
  }

  .tinder-card-title {
    font-size: 1.2rem;
  }

  .loginpassword {
    width: 95%
  }

  .purchasescontainerheader {
    font-size: 0.7rem;
  }

  .reviewpurchasesminibutton {
    font-size: 0.6rem;
  }

  /* 
  .editbutton {
    right: 115px;
    top: 18px;

  }

  .resetbutton {
    right: 50px;
    top: 18px;

  }

  .optionsoptions {
    padding-top: 21px;
    top: 18%;
  }

  .optionsbutton {
    top: 18px;
  } */
}

@media screen and (max-width: 340px) {
  .component-indicator-container {
    max-width: 178px;
  }

  .component-indicator-container-tutorial {
    max-width: 178px;
  }

  .component-indicator3 {
    right: 35px;
  }

  .component-indicator5 {
    right: 80px;
  }



}

@media screen and (max-width: 330px) {
  .tableheadercc {
    max-width: 180px;
  }

  .lilemoji {
    max-width: 140px;
  }

  .component-indicator-container {
    max-width: 160px;
  }

  .component-indicator-container-tutorial {
    max-width: 160px;
  }
}

@media screen and (max-width: 305px) {
  /* .optionsoptions {
    padding-top: 25px;
  }

  .optionscomponentcontainer {
    top: 20px;
  }


  .editbutton {
    right: 95px;

  }

  .resetbutton {
    right: 30px;

  } */

  .component-indicator-container {
    max-width: 150px;
  }

  .component-indicator-container-tutorial {
    max-width: 150px;
  }

  .tableheadercc {
    max-width: 140px;
  }

  .lilemoji {
    max-width: 140px;
  }

  .purchasescontainerheader {
    font-size: 0.6rem;
  }

  .reviewpurchasesminibutton {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 270px) {

  .component-indicator-container {
    max-width: 125px;
  }

  .component-indicator-container-tutorial {
    max-width: 125px;
  }

  /* .resetbutton {
    right: 20px;

  }

  .editbutton {
    right: 80px;

  } */
}








@media screen and (max-height: 700px) {


  .tinder-card {
    width: 80%;
    height: 340px;

  }

  .tinder-card-content {
    padding: 10px;
  }

  .tinder-card-title {
    font-size: 1.4rem;
    margin-bottom: 8px;
    color: rgb(26, 26, 26);
  }

  .tinder-card-icon {
    margin: 10px;
  }

  .tinderheader {
    font-size: 0.9rem;
    padding: 7% 20px 0px 20px;
  }

  .tinderfooter {
    top: 10px;
  }



}

@media screen and (max-height: 810px) {
  .logincontainer7 {
    height: 460px;
    margin-top: 60px;
  }

}

@media screen and (max-height: 650px) {


  .tinder-card {
    width: 80%;
    height: 280px;
  }

  .tinder-card-content {
    padding: 5px;
  }

  .tinder-card-title {
    font-size: 1.4rem;
    margin-bottom: 8px;
    color: rgb(26, 26, 26);
  }

  .tinder-card-icon {
    margin: 10px;
  }

  .tinderheader {
    font-size: 0.9rem;
    padding: 10%;
  }

}





@media screen and (max-height: 600px) {


  .tinder-card {
    width: 80%;
    height: 290px;
  }

  .tinder-card-content {
    padding: 5px;
  }

  .tinder-card-title {
    font-size: 1.4rem;
    margin-bottom: 8px;
    color: rgb(26, 26, 26);
  }

  .tinder-card-icon {
    margin: 10px;
  }

  .tinderheader {
    font-size: 0.9rem;
    padding: 2% 20px 0px 20px;
  }

  .tinderfooter {
    bottom: 20px;
  }
}



@media screen and (max-height: 580px) {
  .logincontainer7 {
    height: 420px;
    margin-top: 80px;
  }



}



@media screen and (max-height: 560px) {



  .tinder-card {
    width: 80%;
    height: 260px;
  }

  .tinder-card-content {
    padding: 5px;
  }

  .tinder-card-title {
    font-size: 1.3rem;
    margin-bottom: 8px;
    color: rgb(26, 26, 26);
  }

  .tinder-card-icon {
    margin: 10px;
    font-size: 2rem;
  }

  .tinder-card-note {
    font-size: 0.9rem;
  }

  .tinder-card-worthit {
    font-size: 0.8rem;
  }

  .tinderheader {
    font-size: 0.9rem;
    padding: 2% 20px 0px 20px;
  }


  .resetconfirm2 {
    top: -50px;
  }

  .logincontainer3 {
    height: 330px;
  }

  .logincontainer7 {
    height: 330px;
    top: -180px;
  }

  .loginemail {
    margin-bottom: 2px;
    height: 30px;
    margin-top: 20px;
  }

  .inputpw-container {
    padding-left: 5px;
    margin-bottom: 2px;
    height: 30px;
  }


  .containerforhidepassword {
    width: 200px;
    height: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #077703;
  }



  .showvaluepropbutton {
    margin-top: 50px;
  }

  .whatslilbudget {
    top: -25px;
  }

  .tinderheader {
    font-size: 0.8rem;
  }



  @media screen and (max-height: 500px) {
    /* .resetconfirm {
    top: -20px;
    padding: 5px;
    margin: 5px;
  } */

    .tinder-card {
      width: 80%;
      height: 240px;
    }

    .whatslilbudget {
      top: -25px;
    }

    .tinderheader {
      font-size: 0.8rem;
    }

    .tinder-card-title {
      font-size: 1.2rem;
      margin-bottom: 8px;
      color: rgb(26, 26, 26);
    }

    .tinder-card-icon {
      margin: 5px;
      font-size: 1.5rem;
    }

    .tinder-card-price {
      font-size: 0.9rem;
    }


    .tinder-card-note {
      font-size: 0.7rem;
    }

    .tinder-card-worthit {
      font-size: 0.8rem;
    }

    .closetinderbutton {
      margin-top: 5px;
    }

    .boxoballs2 {
      display: none;
    }

    .goaltargetlabel {
      font-size: 1rem;
    }
  }

  .group-purchase-controller-container1 {
    height: 280px;

  }

  .group-purchase-submit {
    margin-bottom: 60px;
  }
}

@media screen and (max-height: 450px) {
  .whatslilbudget {
    top: -35px;
  }

  /* .resetconfirm {
    top: -10px;
  } */
}

@media screen and (max-height: 405px) {
  .whatslilbudget {
    top: -45px;
  }

  .boxoballs {
    display: none;
  }

  /* .resetconfirm {
    top: -0px;
  } */
}