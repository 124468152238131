.allocatedchartcontainer {
    width: 80%;
    height: 10px;
    padding: 0px 0px 5px 0px;
    margin: 0px;
}

.allocatedcanvas {
    width: 100%;
    padding: 0px 1px 0px 1px;
    margin: 0;
    background-color: #d8d8d8;
    border-radius: 5px;
    border: 1px solid #d8d8d8;
   
}