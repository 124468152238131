@-webkit-keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-out-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }
}

@keyframes slide-out-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }
}

@-webkit-keyframes scale-in-ver-center {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1;
    }
}

@keyframes scale-in-ver-center {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1;
    }
}

@keyframes tumbleweedAnimation {
    0% {
        left: -100%;
        bottom: 60px;
        transform: rotate(0deg) translateY(0);
    }

    20% {
        transform: rotate(144deg) translateY(60px);
        bottom: 80px;
    }

    40% {
        transform: rotate(288deg) translateY(0);
        bottom: 60px;
    }

    60% {
        transform: rotate(432deg) translateY(40px);
        bottom: 90px;
    }

    80% {
        transform: rotate(576deg) translateY(0);
        bottom: 60px;
    }

    100% {
        left: 100%;
        transform: rotate(720deg) translateY(40px);
        bottom: 100px;
    }
}

@keyframes shadowAnimation {
    0% {
        left: -110%;
        transform: scale(1);
    }

    20% {
        transform: scale(0);

    }

    40% {
        transform: scale(1);

    }

    60% {
        transform: scale(0);
        opacity: 0;

    }

    80% {
        left: 60%;
        opacity: 0.5;
        transform: scale(1);
    }


    100% {
        left: 110%;
        transform: scale(0.2);
        opacity: 0;
    }
}

.shadow {
    position: absolute;
    bottom: 58px;
    /* left: 0; */
    width: 10px;
    height: 3px;
    background-color: rgb(43, 19, 5);
    border-radius: 50%;
    opacity: 0.5;
    animation: shadowAnimation 15s linear infinite;
}

.tumbleweed {
    width: 10px;
    position: absolute;
    left: 0;

    animation: tumbleweedAnimation 15s linear infinite;
}

.paydayreviewcontainer3 {
    width: 100%;
    height: 100vh;
    z-index: 6000;
    box-sizing: border-box;
    margin-bottom: 0px;
    overflow: hidden;
    display: flex;
    position: absolute;
    left: 0;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    justify-content: baseline;
    align-items: center;
    text-align: center;
    backdrop-filter: grayscale(1);
    -webkit-animation: fade-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.0s both;
    animation: fade-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.0s both;
}

.paydayreviewcontainer4 {
    width: 100%;
    height: 100vh;
    z-index: 6000;
    box-sizing: border-box;
    margin-bottom: 0px;
    overflow: hidden;
    display: flex;
    position: absolute;
    left: 0;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    filter: drop-shadow(0 0 0.75rem rgb(43, 43, 43));
}



.paydateinfoheader {
    color: rgb(29, 56, 53);
    margin: 0px;
    padding: 5%;
}

.datereviewdonuticon {
    font-size: 8rem;

}

.paydateinfotext {
    color: rgb(22, 22, 22);
    font-weight: 500;
    margin: 0px;
    padding: 5%;

}

.paydaycontainer4 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 10px;
    width: 80%;
    overflow: scroll;
    height: 80%;
    background-color: rgb(247, 246, 245);
    border-radius: 10px;
    -webkit-animation: slide-in-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.paydayreviewcontainer {
    position: relative;
    width: 98vw;
    max-width: 680px;
    height: 99vh;
    overflow: hidden;
    display: flex;
    position: fixed;
    top: 4px;
    box-sizing: border-box;
    padding: 0px;
    border-radius: 10px;
    justify-content: baseline;
    align-items: center;
    filter: drop-shadow(0 4px 0.2rem rgb(43, 43, 43));
}

.closepaydayreviewcontainer {
    position: absolute;
    top: 5px;
    right: 3px;
    color: #3a3a3a;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 1.5rem;
    z-index: 4;
}

.paydaycontainer2 {
    text-align: left;
    padding: 0px;
    width: 100%;
    overflow: scroll;
    height: 100%;
    background-color: rgb(247, 246, 245);
    border-radius: 10px;
    -webkit-animation: slide-in-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.paydaycontainer3 {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    color: rgb(22, 22, 22);
    -webkit-animation: fade-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.7s both;
    animation: fade-in 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.7s both;
}

.animateaway {
    -webkit-animation: slide-out-bottom 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-bottom 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;

}

.paddingdiv {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.toggleexpensesbutton {
    color: rgb(10, 62, 110);
    font-size: 0.7rem;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    background-color: transparent;
    text-decoration: underline;
    border: none;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    margin: 0px
}

.reviewstat {
    display: flex;
    color: rgb(22, 22, 22);
    width: 100%;
    font-size: 0.8rem;
    margin-bottom: 10px;
    text-align: center;
    flex-direction: row;
}


.reviewstat1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.8rem;
    margin-bottom: 10px;
    text-align: center;
}

.bolder {
    color: rgb(43, 43, 43);
    font-weight: 600;
    font-family: "Baloo 2", sans-serif;
    padding-left: 3px;
    font-size: 0.9rem;
}

.bolder2 {
    color: rgb(43, 43, 43);
    font-weight: 600;
    font-family: "Baloo 2", sans-serif;
    border-bottom: 1px solid grey;
    padding-left: 3px;
    font-size: 0.9rem;
}

.bolder2 {
    color: rgb(43, 43, 43);
    font-weight: 600;
    font-family: "Baloo 2", sans-serif;
    border-bottom: 1px solid grey;
    padding-left: 3px;
    font-size: 0.9rem;

}

.bolder3 {
    font-weight: 700;
    font-family: "Baloo 2", sans-serif;
    font-size: 0.9rem;
    padding-left: 3px;
    color: rgb(29, 56, 53);
}


.reviewstat2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

.reviewstat3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
}

.reviewstat4 {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.8rem;
    margin-bottom: 10px;
    text-align: right;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(107, 107, 107);
}

.reviewstat5 {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.8rem;
    text-align: right;
    padding: 0px;
    margin: 0px;
}


.worksheettext {
    font-size: 0.8rem;
    text-align: right;
    display: flex;
    width: 100%;
    text-align: right;
    justify-content: right;
    flex-direction: row;
}



.spendstatscontainer {
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: inset 0 -5px 5px -5px rgba(0, 0, 0, 0.5), inset 0 5px 5px -5px rgba(0, 0, 0, 0.5);
    padding: 10px;
    box-sizing: border-box;
    -webkit-animation: scale-in-ver-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.purchase-info {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgb(212, 212, 212);
    padding: 5px;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
}

.purchase-info2 {
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgb(212, 212, 212);
    padding: 5px;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
}

.reviewheader {
    font-size: 0.9rem;
    text-align: left;
    padding-top: 0px;
}

.reviewheader2 {
    font-size: 0.9rem;
    text-align: center;
    /* padding-top: 20px; */
}


.topcategorytile {
    max-width: 120px;
    width: 110px;
    min-width: 100px;
    height: 60px;
    border-radius: 10px;
    background-color: rgb(29, 56, 53);
    box-sizing: border-box;
    padding: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.topcategoryheader {
    color: white;
    font-size: 0.7rem;
    width: 100%;
    text-align: center;
}

.topcategoryvalue {
    color: #ffbd2b;
    font-size: 0.9rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}





.topcategoriescontainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 10px;
}

.purchase-category {
    font-weight: 700;
}





.purchase-amount {
    font-weight: 600;
    ;
}

.purchase-icon {
    font-size: 1.8rem;
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: rgb(214, 214, 214);
    border-radius: 50%;
}



.purchase-worthit {
    position: absolute;
    bottom: 2px;
    right: 2px;
    font-size: 0.6rem;
}


.purchase-date {
    display: table-cell;

    font-size: 0.6rem;
    text-align: left;
    vertical-align: top;

}

.top3categorynote {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 0.7rem;
    width: 45%;
    padding-left: 5px;
}

.top3iconworthit {
    display: flex;
    flex-direction: column;

    position: relative;
    width: 15%;
    justify-content: center;
    align-items: center;
}

.top3groupamount {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 0.7rem;
    width: 40%;
    text-align: right;
}

.reviewbuttoncontainer {
    width: 100%;
    height: 150px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.budgetlogoimg2 {
    position: absolute;
    width: 30px;
    border-radius: 5px;
    margin-bottom: 200px;
    opacity: 50%;
    transform: rotate(270deg);
    z-index: -200;
}

.emoji {
    font-size: 1.2rem;
    margin-top: 20px;
}

.totalsavingscontainer {
    padding: 20px;
    box-sizing: border-box;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: white;

}

.unspentincomecontainer {
    width: 70%;
    text-align: center;
    border-radius: 10px;
    background-color: rgb(43, 43, 43);
    padding: 5px 15px 15px 15px;
    box-sizing: border-box;
}

.unspentincome {
    font-family: "Baloo 2", sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: rgb(5, 175, 76);
    padding: 0px;
}

.paydayworksheetheader {
    font-size: 0.7rem;
    line-height: 1.4rem;
    color: grey;
}

.paydayworksheetheader2 {
    line-height: 1.4rem;
    font-size: 0.7rem;
    border-bottom: 1px solid grey;
    color: grey;
}